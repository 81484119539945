<template>
    <div class="main-box" @click="historyShow = false">
        <a-breadcrumb class="aBreadcrumb">
            <a-breadcrumb-item><router-link to="/">首页</router-link></a-breadcrumb-item>
            <a-breadcrumb-item style="color: #15b7dd">搜索结果</a-breadcrumb-item>
        </a-breadcrumb>

        <!-- 关键词搜索 -->
        <!-- <div class="searchBtn">
            <div class="search-box" @click.stop="historyShow = true">
                <a-input placeholder="孤独症康复教育" v-model="paramsQuery.productName" @change.stop="clearInput" class="input"
                    allowClear />
                <a-button type="primary" icon="search" :loading="loading" @click.stop="onButtonSearch()">搜索</a-button>
            </div>
            历史查询面板
            <div class="history-box" :class="{ 'history-box-open': historyShow }" @click.stop="historyShow = true">
                <div style="padding:14px">
                    <p class="title">热门搜索</p>
                    <div class="label">
                        <span class="label-item" @click.stop="onLabelClick(name)"
                            :class="{ 'label-active': name == paramsQuery.productName }"
                            v-for="(name, index) in hotKeyList" :key="index">{{ name }}</span>
                    </div>

                    <div class="history-title">
                        <span class="title">搜索历史</span>
                        <img @click.stop="clearHistory" src="@/assets/image/personalCenter/20220728-110000.png"
                            alt="删除">
                    </div>
                    <div class="history-list">
                        <span class="history-item" @click.stop="onLabelClick(item)"
                            v-for="(item, index) in searchHistory" :key="index">{{ item }}</span>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- 分类列表 -->
        <div class="searchType">
            <div class="o_type">
                <p class="title">商品类别：</p>
                <p class="type" @click="typestatus(item)" :class="{ active: item.type == currType }"
                    v-for="(item, index) in shop_types" :key="index">{{ item.name }}</p>
            </div>
        </div>

        <!-- 商品列表 -->
        <div class="shops">
            <no-message style="margin-top: 100px;" :msg="'暂无数据'"
                :imgSrc="require('@/assets/image/RevisionHome/shopsUn.png')" v-if="!total" type=1></no-message>
            <div class="search" v-show="total > 0">
                <div class="l_box">
                    <!-- <div @click="sort(2)" class="sort" :class="{ activetop: paramsQuery.sorting == 2 }">
                        <p>销量</p>
                    </div>
                    <div @click="sort(3)" class="sort"
                        :class="{ activefoot: paramsQuery.sorting == 3, activetop: paramsQuery.sorting == 4 }">
                        <p>价格</p>
                        <div class="btn">
                            <a-icon class="aIcon" type="caret-up" />
                            <a-icon class="aIcon" type="caret-down" />
                        </div>
                    </div> -->
                </div>
                <p class="r_Total">共找到<span> {{ total }} </span>个结果</p>
            </div>

            <!-- 课程列表 -->
            <div class="shopList" v-if="currType == 1 && courseList.length > 0">
                <div class="shop" v-for="item in courseList" :key="item.id" @click="toproDetail(item)">
                    <img :src="item.pcPhoto" alt="" class="listPhoto">
                    <div class="shopMess">
                        <p class="name mallHidden">{{ item.name }}</p>
                        <div class="tags-box"><span class="tagsItem" v-for="(cell, index) in item.productTagsList"
                                :key="index">{{ cell.tagName }}</span></div>
                        <p class="abstractsStyle onlyOneHidden">{{ item.abstracts }}</p>
                    </div>
                    <div class="priceBox">
                        <div class="price" v-if="item.couponPrice"><span>{{ item.couponPrice.toFixed(2).split('.')[0]
                                }}</span><span>.{{ item.couponPrice.toFixed(2).split('.')[1] }}</span></div>
                        <div class="price" v-else>免费</div>
                        <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                    </div>
                </div>
            </div>
            <!-- 图书教具、模拟考试 -->
            <div class="shopList" v-if="(currType == 2 || currType == 3 || currType == 4) && productList.length > 0">
                <div class="shop" v-for="item in productList" :key="item.id" @click="toproDetail(item)">
                    <img :src="item.pcPhoto" alt="" class="listPhoto">
                    <div class="shopMess">
                        <p class="name mallHidden">{{ item.name }}</p>
                        <div class="tags-box" v-if="item.type == 2"><span class="tagsItem"
                                v-for="(cell, index) in item.productTagsList" :key="index">{{ cell.tagName }}</span>
                        </div>
                        <p class="shopStyle" v-if="item.type == 4">{{ item.answerNumber }}</p>
                    </div>
                    <div class="priceBox">
                        <div class="price" v-if="item.couponPrice"><span>{{ item.couponPrice.toFixed(2).split('.')[0]
                                }}</span><span>.{{ item.couponPrice.toFixed(2).split('.')[1] }}</span></div>
                        <div class="price" v-else>免费</div>
                        <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                    </div>
                </div>
            </div>
            <!-- 资讯 -->
            <!-- <div class="shopList newsShopList" v-if="false">
                <div class="news" v-for="item in productList" :key="item.id" @click="toproDetail(item)">
                    <p class="name mallHidden">{{ item.name }}</p>
                    <div class="main-content">
                        <div class="left-box">
                            <p class="overview">{{ item.courseCategory }} | {{ item.courseHour }}</p>
                            <p class="publishtime">{{ item.courseCategory }}</p>
                        </div>
                        <div class="right-box">
                            <div></div>
                            <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- 问答 -->
            <div class="shopList qaShopList" v-if="currType == 6 && articleList.length > 0">
                <div class="news" v-for="item in articleList" :key="item.id" @click="toArticleDetail(item)">
                    <p class="name mallHidden">{{ item.articleTitle }}</p>
                    <div class="main-content">
                        <div class="qaleft-box">
                            <p class="overview">{{ item.overview }}</p>
                        </div>
                        <div class="right-box">
                            <div style="height: 60%;"> </div>
                            <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                        </div>
                    </div>
                    <div class="publishTime">发布：{{ item.publishTime }}</div>
                </div>
            </div>
            <!-- 电子书 -->
            <!-- <div class="shopList ebookShopList" v-if="false">
                <div class="ebooks" v-for="item in productList" :key="item.id" @click="toproDetail(item)">
                    <img :src="item.listPhoto" alt="" class="listPhoto">
                    <div class="right-box">
                        <p class="name mallHidden">{{ item.name }}</p>
                        <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                    </div>
                </div>
            </div> -->
            <!-- 科普视频 -->
            <div class="shopList videoShopList" v-if="currType == 5 && articleList.length > 0">
                <div class="videos" v-for="item in articleList" :key="item.id" @click="toArticleDetail(item)">
                    <img :src="item.banner" alt="" class="listPhoto">
                    <div class="right-box">
                        <div class="top-box">
                            <p class="name mallHidden">{{ item.articleTitle }}</p>
                            <p class="overview mallHidden">{{ item.overview }}</p>
                        </div>
                        <div class="bottom-box">
                            <div class="label-box"><span v-for="(label, index) in fun_split(item.label)"
                                    :key="index">#{{ label }}</span></div>
                            <div class="toDetail">查看详情 <a-icon class="aIcon" type="right-circle" theme="filled" /></div>
                        </div>

                    </div>
                </div>
            </div>
            <a-pagination class="aPagination" v-model="paramsQuery.pageNum" show-size-changer
                :page-size.sync="paramsQuery.pageSize" :total="total" @showSizeChange="onShowSizeChange"
                hideOnSinglePage />
        </div>
    </div>
</template>

<script>
import eventBus from '@/unit/eventBus';
export default {
    // 可用组件的哈希表
    components: {},
    data() {
        return {
            shop_types: [
                { name: '课程', type: 1 },
                { name: '图书', type: 2 },
                { name: '教具', type: 3 },
                { name: '模拟考试', type: 4 },
                { name: '科普视频', type: 5 },
                { name: '问答', type: 6 },
            ], // 商品分类
            total: 0,
            courseList: [], // 课程列表
            productList: [], // 图书、教具、模拟考试列表
            articleList: [], // 资讯列表
            paramsQuery: {
                pageNum: 1,
                pageSize: 10,
                // sorting: 1, // 排序 1创建创建时间 2销量，3价格降序，4价格升序
                // categoryCode: '', // 分类id
                productName: '',
            },
            searchHistory: [],
            // hotKeyList: ['孤独症', '智力障碍', '行为分析', '岗位培训', 'VB-MAPP评估', '督导服务', '言语技能', '问题行为', '行为问题', '语言行为'], // 热门搜索关键词
            // historyShow: false, // 历史搜索面板控制器
            loading: false,
            currType: 1, // 课程、图书、教具、模拟考试、科普视频、问答
        }
    },
    watch: {
        // "paramsQuery.sorting"(newVal, oldVal) {
        //     this.paramsQuery.pageNum = 1;
        //     this.getShopList();
        // },
        'paramsQuery.pageNum'(val) {
            if (this.currType < 5) {
                this.fetchProductList(this.currType);
            } else {
                this.fetchArticleList(this.currType);
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        },
    },
    methods: {

        fetchAllList() {
            switch (this.currType) {
                case 1:
                    this.fetchProductList(1);
                    break;
                case 2:
                    this.fetchProductList(2);
                    break;
                case 3:
                    this.fetchProductList(3);
                    break;
                case 4:
                    this.fetchProductList(4);
                    break;
                case 5:
                    this.fetchArticleList(5);
                    break;
                case 6:
                    this.fetchArticleList(6);
                    break;

                default:
                    break;
            }
            // 商品列表（课程、图书、教具、模拟考试）
            // this.fetchProductList(1);
            // this.fetchProductList(2);
            // this.fetchProductList(3);
            // this.fetchProductList(4);
            // // 资讯列表（科普视频、问答）
            // this.fetchArticleList(5);
            // this.fetchArticleList(6);
            this.loading = false;
        },
        fetchProductList(type) {
            this.loading = true;
            this.$ajax({
                url: "/hxclass-pc/pc-mall/search/list",
                method: "get",
                params: {
                    name: this.paramsQuery.productName,
                    pageNum: this.paramsQuery.pageNum,
                    pageSize: this.paramsQuery.pageSize,
                    type: type,
                }
            }).then((res) => {
                this.loading = false;
                if (res.code == 200 && res.success) {
                    if (type == 1) {
                        this.courseList = res.data.records;
                    } else {
                        this.productList = res.data.records;
                    }
                    if (this.currType == type) {
                        this.total = res.data.total;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        fetchArticleList(type) {
            this.loading = true;
            this.$ajax({
                url: "/hxclass-pc/Article/client/list",
                method: "get",
                params: {
                    columnCode: type == 5 ? 'SPVideo' : 'QA',
                    pageNum: this.paramsQuery.pageNum,
                    pageSize: this.paramsQuery.pageSize,
                    title: this.paramsQuery.productName,
                }
            }).then((res) => {
                this.loading = false;
                if (res.code == 200 && res.success) {
                    this.articleList = res.data.records;
                    if (this.currType == type) {
                        this.total = res.data.total;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 格式化标签
        fun_split(str) {
            let arr = []
            if (!str) { return arr }
            arr = str.split("，")
            return arr
        },
        onShowSizeChange(current, pageSize) {
            this.paramsQuery.pageNum = current;
            this.paramsQuery.pageSize = pageSize;
            if (this.currType < 5) {
                this.fetchProductList(this.currType);
            } else {
                this.fetchArticleList(this.currType);
            }
        },

        // 选择商品一级分类
        typestatus(e) {
            if (this.currType == e.type) {
                return
            }
            this.currType = e.type;
            this.paramsQuery.pageNum = 1;
            this.courseList = [];
            this.productList = [];
            this.articleList = [];
            eventBus.$emit('searchTextEvent');
            // if (this.currType < 5) {
            //     this.fetchProductList(this.currType);
            // } else {
            //     this.fetchArticleList(this.currType);
            // }
        },

        // sort排序
        sort(e) {
            if (e == 3) {
                if (this.paramsQuery.sorting == 4) {
                    this.$set(this.paramsQuery, 'sorting', 3);
                } else if (this.paramsQuery.sorting == 3) {
                    this.$set(this.paramsQuery, 'sorting', 1);
                } else {
                    this.$set(this.paramsQuery, 'sorting', 4);
                }
                return
            }
            if (e == this.paramsQuery.sorting) {
                this.$set(this.paramsQuery, 'sorting', 1);
                return
            }
            this.$set(this.paramsQuery, 'sorting', e);
        },

        // 跳转商品详情
        toproDetail(item) {
            if (item.productType == 1 || item.type == 1) {
                this.$router.push({
                    path: "/knowledgeMall/courseDetail",
                    query: {
                        productId: this.$AES.encode_data(item.productId + ""),
                        code: item.code ? item.code : item.productCode,
                    },
                });
            } else if (
                item.productType == 2 ||
                item.productType == 3 ||
                item.type == 2 ||
                item.type == 3
            ) {
                this.$router.push(
                    "/knowledgeMall/curriculumDetail?productId=" +
                    this.$AES.encode_data(item.productId + "")
                );
            } else if (item.productType == 4 || item.type == 4) {
                this.$router.push(
                    "/knowledgeMall/examDetail?productId=" +
                    this.$AES.encode_data(item.productId + "")
                );
            }
        },

        // 跳转咨询详情
        toArticleDetail(item) {
            if (this.currType == 5) {
                this.$router.push({
                    path: "/videoDetail",
                    query: {
                        id: this.$AES.encode_data(item.id + ""),
                        type: '科普视频',
                        from: '首页'
                    },
                });
            } else if (this.currType == 6) {
                this.$router.push(
                    "/ArticleDetail?id=" +
                    this.$AES.encode_data(String(item.id))
                );
            }
        },

        // clearHistory() {
        //     this.searchHistory = []
        //     localStorage.setItem('searchHistory', null)
        // },

        // onButtonSearch() {
        //     if (!this.paramsQuery.productName) {
        //         this.paramsQuery.productName = '孤独症'
        //     }

        //     if (this.paramsQuery.productName.trim() !== '') {
        //         // 如果新关键词已经在历史中，则先移除它
        //         const index = this.searchHistory.indexOf(this.paramsQuery.productName);
        //         if (index > -1) {
        //             this.searchHistory.splice(index, 1);
        //         }

        //         // 确保历史记录不超过10条
        //         if (this.searchHistory.length >= 8) {
        //             this.searchHistory.pop(); // 移除最后一项
        //         }

        //         // 在数组最前面添加新关键词
        //         this.searchHistory.unshift(this.paramsQuery.productName);
        //     }

        //     localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))

        //     this.getShopList()

        //     setTimeout(() => {
        //         this.historyShow = false
        //     }, 50)
        // },

        clearInput(e) {
            const newValue = e.target.value
            if (!newValue) {
                this.getShopList()
            }
        },
    },
    created() {
        this.paramsQuery.productName = this.$route.query.searchWord;
        // console.log('传递的值------', this.paramsQuery.productName);
        if (this.$route.query.productName) {
            this.paramsQuery.productName = this.$route.query.productName
            this.paramsQuery.pageNum = 1
        }
        // this.getTypeList();
        // this.getShopList();
        this.fetchAllList();

        if (JSON.parse(localStorage.getItem('searchHistory'))) {
            this.searchHistory = JSON.parse(localStorage.getItem('searchHistory'))
        }
    },
    // 生命周期-实例挂载后调用
    mounted() {
        eventBus.$on('searchEvent', target => {
            this.paramsQuery.productName = target;
            this.paramsQuery.pageNum = 1
            // console.log('=======', this.paramsQuery.productName);
            this.fetchAllList();
        })
    },
    beforeDestroy() {
        eventBus.$off("searchEvent")
    }
}
</script>


<style lang="less" scoped>
.main-box {
    padding-top: 80px;
    width: 100%;

    .searchBtn,
    .aBreadcrumb,
    .searchType,
    .search,
    .shopList {
        width: 1200px;
        margin: 0 auto;
    }

    .aPagination {
        text-align: center;
    }

    .searchBtn {
        position: relative;
        margin-top: 20px;

        .search-box {
            width: 400px;
            display: flex;
            padding: 3px 7px 3px 7px;
            border: 1px solid #15B7DD;
            border-radius: 8px;

            /deep/ .ant-input {
                height: 32px;
                border: none;
                background-color: #F6F6FC;
            }

            /deep/ .ant-input:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .history-box {
            position: absolute;
            left: 0;
            top: 50px;
            transition: .3s;
            height: 0;
            width: 400px;
            background: #FFFFFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 8px 8px 8px 8px;
            overflow: hidden;

            /* 超出部分隐藏 */
            .title {
                font-weight: 500;
                font-size: 14px;
                color: #15B7DD;
                line-height: 21px;
            }

            .label {
                .label-item {
                    display: inline-block;
                    background: #F6F6FC;
                    margin-right: 20px;
                    margin-top: 10px;
                    padding: 0 10px;
                    border-radius: 11px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    line-height: 21px;
                    cursor: pointer;
                }

                .label-active {
                    color: #15B7DD;
                }
            }

            .history-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;

                span {
                    color: #333333;
                }

                img {
                    width: 18px;
                    cursor: pointer;
                }
            }

            .history-list {
                .history-item {
                    display: inline-block;
                    margin-right: 20px;
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    line-height: 21px;
                    cursor: pointer;
                }
            }
        }

        .history-box-open {
            height: 260px;
        }
    }

    .shopList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;

        .shop {
            width: calc(50% - 15px);
            padding: 12px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 24px;
            display: flex;
            cursor: pointer;

            .priceBox {
                padding-right: 12px;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 132px;

                .toDetail {
                    margin-top: 12px;
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    line-height: 24px;
                    cursor: pointer;

                    .aIcon {
                        color: #8ADBEE;
                    }
                }

                .price {
                    display: inline-block;
                    font-size: 24px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #EC6C01;
                    line-height: 36px;

                    span:first-child {
                        position: relative;
                    }

                    span:first-child::before {
                        content: '￥';
                        position: absolute;
                        left: -12px;
                        bottom: 3px;
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #EC6C01;
                        line-height: 18px;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #EC6C01;
                        line-height: 21px;
                    }
                }
            }

            .shopMess {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // flex: 1;
                width: calc(100% - 250px);

                .name {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 27px;
                    cursor: pointer;
                }

                .tags-box {
                    .tagsItem {
                        background: rgba(21, 183, 221, 0.1);
                        border-radius: 2px 2px 2px 2px;
                        padding: 2px 4px;
                        font-weight: 500;
                        font-size: 12px;
                        color: #15B7DD;
                        line-height: 18px;
                        margin-right: 10px;
                    }
                }

                .abstractsStyle {
                    font-size: 12px;
                    color: #999999;
                    line-height: 18px;
                }

                .shopStyle {
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #15B7DD;
                    line-height: 18px;
                }

                .abstracts {
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                }

                .teacherList {
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;

                    span {
                        margin-right: 10px;
                    }

                    span:first-child {
                        margin-right: 6px;
                    }
                }

                .slogan {
                    margin-top: 24px;
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }

                .bookAbstracts {
                    margin-top: 20px;

                    .tag {
                        padding-left: 4px;
                        padding-right: 17px;
                        background: linear-gradient(225deg, #FFBB64 0%, #FE9B3F 100%);
                        font-size: 13px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 20px;
                        position: relative;
                    }

                    .tag::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-bottom: 20px solid white;
                        border-left: 20px solid transparent;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    span {
                        font-size: 13px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }
                }
            }

            .listPhoto {
                width: 110px;
                height: 110px;
                border-radius: 8px;
            }
        }

        .shop:hover {
            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
            border-color: #FFFFFF;
        }

        .news {
            width: calc(50% - 15px);
            padding: 12px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
                cursor: pointer;
            }

            .main-content {
                display: flex;
                flex: 1;

                .right-box {
                    width: 90px;
                    // height: 100%;
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    position: relative;

                    .toDetail {
                        margin-top: 12px;
                        font-size: 16px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #666666;
                        line-height: 24px;
                        cursor: pointer;
                        position: absolute;
                        bottom: -10px;

                        .aIcon {
                            color: #8ADBEE;
                        }
                    }
                }

                .left-box {
                    flex: 1;
                    min-height: 70px;
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .qaleft-box {
                    flex: 1;
                    min-height: 30px;
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-items: center;
                }
            }

            .publishTime {
                font-size: 12px;
                color: #999999;
                line-height: 18px;
            }

            .overview {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                color: #999;
                line-height: 21px;
                margin-right: 16px;
                margin-bottom: 12px;
                cursor: pointer;
                -webkit-line-clamp: 3; //（最多显示 3 行）
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ebooks {
            width: calc((100% - 60px) / 3);
            padding: 12px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            /* 垂直居中 */
            cursor: pointer;

            .listPhoto {
                width: 80px;
                height: 120px;
                border-radius: 8px;
                object-fit: cover;
                background-repeat: no-repeat;
            }

            .right-box {
                height: 100px;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .toDetail {
                    margin-top: 12px;
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    line-height: 24px;
                    cursor: pointer;

                    .aIcon {
                        color: #8ADBEE;
                    }
                }
            }
        }

        .videos {
            width: calc(50% - 15px);
            padding: 12px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            /* 垂直居中 */
            cursor: pointer;

            .listPhoto {
                width: 178px;
                height: 110px;
                border-radius: 8px;
                object-fit: cover;
                background-repeat: no-repeat;
            }

            .right-box {
                flex: 1;
                height: 100px;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .top-box {
                    .overview {
                        margin-top: 8px;
                        font-size: 14px;
                        color: #999999;
                        line-height: 21px;
                    }
                }

                .bottom-box {
                    display: flex;
                    justify-content: space-between;

                    .label-box {
                        font-size: 14px;
                        color: #15B7DD;
                        line-height: 15px;
                    }

                    .toDetail {
                        width: 90px;
                        margin-top: 12px;
                        font-size: 16px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #666666;
                        line-height: 24px;
                        cursor: pointer;

                        .label-box {
                            flex: 1;
                        }

                        .aIcon {
                            color: #8ADBEE;
                        }
                    }
                }
            }
        }
    }

    .searchType {
        margin-top: 18px;
    }

    .o_type {
        display: flex;
        align-items: center;
        margin-top: 21px;

        .title {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 21px;
        }

        .type {
            margin-left: 12px;
            width: 80px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            user-select: none;
        }

        .type:hover,
        .active {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            background: #EAEAEF;
            border-radius: 6px 6px 6px 6px;
        }
    }

    .o_type:first-child {
        margin-top: 0;
    }

    .shops {
        margin-top: 16px;
        background: #FFFFFF;
        padding-top: 20px;
        padding-bottom: 50px;
        min-height: calc(100vh - 482px);

        /deep/.noMessage {
            img {
                width: 185px;
                height: 102px;
                margin-left: -3px;
            }

            p {
                margin-top: 10px;
            }
        }

        .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .r_Total {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;

            span {
                font-weight: 500;
                color: #333333;
            }
        }

        .l_box {
            display: flex;

            .sort {
                margin-right: 19px;
                display: flex;
                align-items: center;
                cursor: pointer;

                p {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                    user-select: none;
                }

                .btn {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .aIcon {
                        font-size: 10px;
                        color: #D9D9D9;
                    }

                    .aIcon:first-child {
                        margin-bottom: -3px;
                    }
                }
            }

            .activetop {
                p {
                    color: @theme;
                }

                .btn {
                    .aIcon:first-child {
                        color: @theme;
                    }
                }
            }

            .activefoot {
                p {
                    color: @theme;
                }

                .btn {
                    .aIcon:nth-child(2) {
                        color: @theme;
                    }
                }
            }
        }
    }
}
</style>