<template>
  <!-- 对公转账 -->
  <div class="offlinePay">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">" v-if="!$route.query.source">
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item
        ><router-link to
          ><a @click="$router.go(-1)">收银台</a></router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">对公转账</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="box_con">
      <div class="box1">
        <div class="hint_title">
          您需转账<span class="light"
            >{{ ((orderDetail.payPrice * 100) / 100).toFixed(2) }}元</span
          >至以下账户：
        </div>
        <div class="hint_con">
          <span class="con_left">收款户名：</span>
          <span class="con_right">华夏出版社有限公司</span>
        </div>
        <div class="hint_con">
          <span class="con_left">收款银行：</span>
          <span class="con_right">中国工商银行北京东四支行</span>
        </div>
        <div class="hint_con">
          <span class="con_left">银行账号：</span>
          <span class="con_right">0200 0041 0900 4621 597</span>
        </div>
      </div>
      <div class="box2">
        <div class="box_title">支付完成后请尽快填写以下信息：</div>
        <div class="box_input">
          <div class="input_item require">
            <span class="item_name">付款人户名</span>
            <a-input v-model="accountName" placeholder="请输入付款人户名" />
          </div>
          <div class="input_item require">
            <span class="item_name">开户行</span>
            <a-input v-model="bankName" placeholder="请输入开户行" />
          </div>
          <div class="input_item require">
            <span class="item_name">付款人账号</span>
            <a-input v-model="accountNumber" placeholder="请输入付款人账号" />
          </div>
          <!-- <div class="input_item require">
            <span class="item_name">应付金额</span>
            <a-input-number v-model="price" placeholder="请输入应付金额" :min="0.01"/>
          </div> -->
          <div class="input_item upload">
            <span class="item_name">上传支付凭证</span>
            <a-upload
              v-show="picture.length < 3"
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :customRequest="handleChange"
            >
              <div>
                <a-icon
                  :type="loading ? 'loading' : 'plus'"
                  style="fontSize: 30px; color: #c4c4c4"
                />
              </div>
            </a-upload>
            <div class="imgs">
              <div class="img" v-for="(item,index) in picture" :key="index">
                <img
                  class="pictureImg"
                  :src="item"
                  alt="avatar"
                  :onerror="GLOBAL.errorimg()"
                />
                <img class="close" @click="deletePictrue(index)" src="@/assets/image/order/closeIcon.png" alt="">
              </div>
            </div>
            <!-- <div class="item_img" @click="openImg()">
              <img src="@/assets/image/defaultLevel.png" alt="示例">
              <div class="img_info">示例</div>
            </div> -->
          </div>
          <div class="input_item">
            <p class="warn"><a-icon type="exclamation-circle" /><span>最多上传3张凭证照片</span></p>
          </div>
        </div>
        <div class="box_button">
          <div class="all-btn-blue" @click="goBack()">取消</div>
          <div class="all-btn-small" @click="submitTransfer">
            提交 <a-icon v-if="isLoading" type="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadObject } from "@/unit/cosUpload.js";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      orderId: null, //订单id
      orderDetail: {}, //订单详情

      accountName: "", //户名
      bankName: "", //开户行
      accountNumber: "", //付款人账号
      price: "", //应付金额
      picture: [], //支付凭证

      loading: false, //上传图片
      isLoading: false, //提交中
    };
  },
  // 事件处理器
  methods: {
    deletePictrue(i) {
      this.picture.splice(i,1);
    },
    // 订单详情
    getOrderDetail() {
      this.$ajax({
        url: "/hxclass-pc/order/" + this.orderId,
        method: "get",
      }).then((res) => {
        console.log(res);
        if (res.code == 200 && res.success) {
          this.orderDetail = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleChange(info) {
      if(this.picture.length>=3){
        return this.$message.error('支付凭证最多可上传三张!');
      }

      this.loading = true;
      // 腾讯云对象存储上传文件
      const bucket = 'user/transaction/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(info.file, bucket, this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.picture.push(url);
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('请上传正确格式图片文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('图片内存大小需在2MB之内');
      }
      return isJpgOrPng && isLt2M;
    },
    // 示例
    openImg() {
      window.open(
        "https://cos.hxclass.cn/prod/mobile/20220721/4ee6ef8c59424f9da3cd5920c6c0e8c1.png"
      );
    },
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 提交对公转账信息
    submitTransfer() {
      if (this.isLoading) {
        return;
      }
      if (!this.accountName) {
        return this.$message.info("请输入户名");
      }
      if (!this.bankName) {
        return this.$message.info("请输入开户行");
      }
      if (!this.accountNumber) {
        return this.$message.info("请输入付款人账号");
      }
      // if(!this.price){
      //   return this.$message.info('请输入应付金额')
      // }
      this.isLoading = true;
      this.$ajax({
        url: "/hxclass-pc/order/transfer",
        method: "post",
        params: {
          accountName: this.accountName, // 户名
          bankName: this.bankName, // 开户行
          accountNumber: this.accountNumber, // 付款人账号
          // price: this.price, // 应付金额
          price: this.orderDetail.payPrice, //应付金额
          img: this.picture.join(','),
          orderId: this.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 转账成功
          this.$router.replace({
            path: "/pay/payOk?type=2",
            query: {
              orderId: this.$AES.encode_data(this.orderId),
              source: this.$route.query.source
            },
          });
          // this.$router.push('/order/corporatetransfer')
          // this.$Toast('您已成功提交支付信息，我们会尽快为您处理，请耐心等候。');
        } else {
          this.$message.error(res.message);
        }
        this.isLoading = false;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    // 订单详情
    this.getOrderDetail();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.offlinePay {
  width: 1400px;
  margin: 0 auto;
  padding-top: 92px;
  .box_con {
    margin-top: 22px;
    margin-bottom: 40px;
    padding: 40px 40px 60px 40px;
    background: #fff;
    border-radius: 5px;
    .box1 {
      position: relative;
      width: 567px;
      border-radius: 5px;
      .hint_title {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        .light {
          color: #ec6c01;
          font-size: 24px;
          margin: 0 5px;
        }
      }
      .hint_con {
        margin-top: 18px;
        font-size: 16px;
        .con_left {
          color: #666;
          font-family: PingFang SC-Regular, PingFang SC;
        }
        .con_right {
          font-family: PingFang SC-Regular, PingFang SC;
        }
      }
    }
    .box2 {
      margin-top: 50px;
      .box_title {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .box_input {
        padding: 0 12px;
        .input_item {
          margin-top: 16px;
          .warn{
            padding-left: 120px;
            color: @theme;
            font-size: 10px;
            display: flex;
            align-items: center;
            span{
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              line-height: 12px;
              margin-left: 2px;
            }
          }
          &.require {
            .item_name::before {
              content: "*";
              display: inline-block;
              color: #f90000;
              font-size: 16px;
            }
          }
          // .pictureImg {
          //   height: 85px;
          // }
          /deep/.ant-upload-picture-card-wrapper {
            width: auto;
          }
          /deep/.ant-upload.ant-upload-select-picture-card {
            width: 102px;
            height: 102px;
            border: 1px solid #ebebeb;
            background-color: transparent;
          }
          /deep/.ant-input-number,
          /deep/.ant-input-number-input {
            width: 300px;
            height: 41px;
          }
          .item_name {
            display: inline-block;
            width: 120px;
            color: #333333;
            font-size: 16px;
          }
          .ant-input {
            width: 300px;
            height: 41px;
          }
          .item_img {
            position: relative;
            width: 102px;
            height: 102px;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            cursor: pointer;
            img {
              width: 100%;
            }
            .img_info {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 47px;
              height: 20px;
              line-height: 21px;
              text-align: center;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              background: rgba(0, 0, 0, 0.2);
            }
          }
          &.upload {
            display: flex;
            .imgs{
              height: 100px;
              display: flex;
              align-items: center;
              .img{
                min-width: 50px;
                min-height: 50px;
                position: relative;
                border: 1px solid #ccc;
                margin-right: 10px;
                border-radius: 5px;
                overflow: hidden;
                .close{
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
              }
              img{
                max-width: 100px;
                max-height: 100px;
              }
            }
            .item_name {
              padding-top: 8px;
            }
          }
        }
      }
      .box_button {
        display: flex;
        margin-top: 50px;
        margin-left: 120px;
        .all-btn-blue,
        .all-btn-small {
          width: 138px;
          height: 46px;
          line-height: 46px;
          font-size: 16px;
          margin-right: 32px;
        }
      }
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .offlinePay {
    width: 1200px;
  }
}
</style>
