<template>
  <div class="main-box confirmOrder">
    <!-- 面包屑 -->
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/knowledgeMall/shopHome">书课专区</router-link></a-breadcrumb-item>
      <a-breadcrumb-item style="color: #15b7dd">确认订单</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <!-- 收货人信息 -->
      <div class="moduletitle" v-if="addressFlag">
        <p class="title"><span></span>收货人信息</p>
        <p class="all-btn-small" @click="showModal(0)">+新增收货地址</p>
      </div>
      <div class="addressList" v-if="addressFlag">
        <div class="addressItem" @click="changeAddressId(item)" :class="{ addressActive: addressId == item.id }"
          v-for="(item, index) in addressList" :key="'#a' + index" v-show="addressStatus || index < 3">
          <p class="name">{{ item.consignee }}</p>
          <p class="phone">{{ item.phone }}</p>
          <p class="address">
            {{ item.proviceName }}{{ item.cityName }}{{ item.areaName ? item.areaName : '' }}{{ item.address }}
            <span v-if="item.isDefault">默认地址</span>
          </p>
          <div class="btns">
            <p class="btn" @click.stop="showModal(1, item)">修改</p>
            <p class="btn" v-if="!item.isDefault" @click.stop="showPop(2, item.id)">设为默认</p>
            <p class="btn" @click.stop="showPop(1, item.id)">删除</p>
          </div>
        </div>
        <no-message :msg="'暂无收货地址'" :imgSrc="require('@/assets/image/personalCenter/20220729-100305.png')"
          v-if="(!addressList || !addressList.length) && flag" />
        <div class="addressMall" v-if="addressList.length > 3"><span
            @click="addressStatus = !addressStatus">{{ addressStatus ? '收起' : '显示更多' }}<a-icon v-if="!addressStatus"
              class="icon" type="double-left" /><a-icon v-else class="icon" type="double-right" /></span></div>
      </div>

      <!-- 商品清单 -->
      <div class="moduletitle">
        <p class="title"><span></span>商品清单</p>
      </div>
      <div class="productList">
        <div class="topTitle">
          <p class="productMess">商品信息</p>
          <p class="price">单价</p>
          <p class="num">数量</p>
          <p class="coupon">优惠</p>
          <p class="allPrice">小计</p>
        </div>
        <div class="productItem" v-for="(item, index) in productList" :key="'#b' + index">
          <div class="productMess">
            <div class="img"><img :src="item.listPhoto" alt=""></div>
            <div class="product">
              <p class="name">{{ item.name }}</p>
              <!-- <p class="type">{{item.courseCategory}} | {{item.courseHour}}</p> -->
              <!-- <p class="type" v-if="item.type == 1">{{item.courseCategory}} | {{item.courseHour}}</p> -->
              <p class="type" v-if="item.type == 1">
                {{ item.openTimeId ? item.className + '：' + item.startTime.split('-').join('/') + '-' + item.endTime.split('-').join('/') : (item.validDayType
                  == 1 ? '有效期：' + item.validDays + '天' : item.validDayType == 2 ? '长期有效' :'')}}</p>
              <p class="type" v-else-if="item.type == 4">{{ item.answerNumber }}</p>
            </div>
          </div>
          <div class="price">￥{{ item.couponPrice.toFixed(2) }}</div>
          <div class="num">x{{ item.count }}</div>
          <div class="coupon" v-if="item.totalPrice != item.actualPrice && item.actualPrice">
            {{ '-￥' + ((item.totalPrice * 100 - item.actualPrice * 100) / 100).toFixed(2) }}</div>
          <div class="coupon" v-else>-</div>
          <div class="allPrice">￥{{ item.actualPrice ? item.actualPrice.toFixed(2) : item.totalPrice.toFixed(2) }}</div>
        </div>
        <!-- this.giftList,this.packedList -->
        <p class="otherProduct" v-if="giftList.length || packedList.length">
          {{ giftList.length ? '赠品' : packedList.length ? '包含商品' : '' }}
        </p>
        <div class="productItem" v-show="giftList.length || packedList.length"
          v-for="(item, index) in giftList.length ? giftList : packedList.length ? packedList : ''" :key="'#c' + index">
          <div class="productMess">
            <div class="img"><img :src="item.listPhoto" alt=""></div>
            <div class="product">
              <p class="name">{{ item.name }}</p>
              <!-- <p class="type">{{item.courseCategory}} | {{item.courseHour}}</p> -->
              <p class="type" v-if="item.type == 1">{{ item.courseCategory }} | {{ item.courseHour }}</p>
              <p class="type" v-else-if="item.type == 4">{{ item.answerNumber }}</p>
            </div>
          </div>
          <div class="price">￥{{ item.couponPrice.toFixed(2) }}</div>
          <div class="num">x{{ item.count }}</div>
          <div class="coupon">-</div>
          <div class="allPrice">-</div>
        </div>
      </div>

      <!-- 提示信息 -->
      <div class="warning">
        <p class="warnText">课程、服务类商品为虚拟内容服务，购买后不支持出售、退订、转让、退换，请斟酌确认</p>
        <p class="warnText">图书、教具等实物商品满足相应条件（未发货、未拆封且不影响二次销售）时可以申请退款，但产生的退货运费由您承担。</p>
        <p class="warnText">购买后可在「我的-我的订单」中查看。</p>
      </div>
      <!-- 边框盒子-商品清单模块 -->
      <div class="borderbox"></div>

      <!-- 发票信息 -->
      <div class="moduletitle">
        <p class="title"><span></span>发票信息</p>
      </div>
      <div class="invoiceType">
        <div class="type" @click="changeIsInvoice" :class="{ pick: !isInvoice }">默认</div>
        <div class="type" @click="isInvoice = 1" :class="{ pick: isInvoice }">其他</div>
      </div>
      <div class="invoiceList" v-show="isInvoice">
        <div class="invoiceItem marginStyle" @click="changeInvoiceId(item.userInvoiceId)"
          :class="{ invoiceActive: invoiceId == item.userInvoiceId }" v-for="(item, index) in invoiceList"
          :key="'#d' + index">
          <div class="message">
            <img v-if="item.headerType == 3" src="@/assets/image/order/personal.png" alt="" />
            <img v-if="item.headerType == 1" src="@/assets/image/order/business.png" alt="" />
            <p class="headerType">{{ item.headerType == 3 ? '个人' : '企业' }}</p>
            <p class="name">{{ item.invoiceHeader }}</p>
          </div>
          <p class="code">{{ item.dutyParagraph }}</p>
          <p class="email">{{ item.email }}</p>
          <div class="box_left_top">
            <div class="writeBox"></div>
          </div>
          <div class="box_left_bottom">
            <div class="writeBox"></div>
          </div>
          <div class="box_right_top">
            <div class="writeBox"></div>
          </div>
          <div class="box_right_bottom">
            <div class="writeBox"></div>
          </div>
        </div>
        <div class="invoiceAdd marginStyle" @click="isAddInvoiceModal = true">
          <img src="@/assets/image/order/invoiceAdd.png" alt="" />
          <p>添加发票抬头</p>
        </div>
      </div>
      <!-- 提示信息 -->
      <p class="invoicewarnText" v-show="!isInvoice">提示：默认使用“个人”抬头，如需使用其他抬头请点击“其他”进行添加。</p>
      <img class="invoiceNote" v-show="!isInvoice" src="@/assets/image/order/invoiceNote.png" alt="">

      <!-- 使用优惠券/兑换券 -->
      <div v-if="couponList.length" class="moduletitle" style="margin-top:30px;">
        <p class="title" style="cursor:pointer;" @click="couponFlag = !couponFlag"><span></span>使用优惠券/兑换券 <a-icon
            v-if="!couponFlag" type="down" /><a-icon v-else type="up" /></p>
      </div>
      <div class="couponList" v-show="couponFlag && couponList.length">
        <div class="couponItem" @click="changeCouponId(item)" v-for="(item, index) in couponList" :key="'#e' + index">
          <!-- <p class="price">￥<span>{{item.couponPrice}}</span></p>
          <p class="condition">{{item.condition}}</p>
          <p class="type">【{{item.type}}】</p>
          <p class="time">有效期至{{item.time}}</p> -->
          <template v-if="item.type == 1">
            <p class="price">¥<span :style="[
              {
                fontSize: (item.couponPrice + '').length < 4 ? '64px' : (item.couponPrice + '').length == 4 ? '55px' : '40px'
              },
              {
                lineHeight: (item.couponPrice + '').length <= 4 ? '77px' : '77px'
              }
            ]">{{ item.couponPrice }}</span></p>
            <p class="condition">满{{ item.fullPrice }}元可用</p>
          </template>
          <template v-if="item.type == 3">
            <p class="price"><span>{{ item.discount }}</span>折</p>
            <p class="condition">满{{ item.discountPrice }}{{ item.discountType == 1 ? '件' : '元' }}可用</p>
          </template>
          <!-- <p class="type">【{{item.type}}】</p> -->
          <p class="type" :title="item.receiveDesc">「{{ item.receiveDesc }}」</p>
          <!-- <p class="time">有效期至{{item.time}}</p> -->
          <p class="time" v-if="item.times == 2 || item.times == 3">有效期至{{ item.lapseTime }}</p>
          <p class="time" v-if="item.times == 1">不限使用时间</p>
          <img v-if="drawIds && drawIds.indexOf(item.drawId) != -1" src="@/assets/image/order/couponChecked.png" alt="">
        </div>
      </div>

      <!-- 价格 -->
      <div class="priceMessage" v-if="orderMessage">
        <p class="price">商品金额：<span>¥{{ price.toFixed(2) }}</span></p>
        <p class="price" v-if="addressFlag">
          运费：<span>{{ orderMessage.freightPrice ? '¥' + orderMessage.freightPrice.toFixed(2) : '包邮' }}</span></p>
        <p class="price" v-if="ordercouponPrice()"><span v-if="priceDifference"
            class="warn">剩余{{ priceDifference }}元未抵扣,下单后不退</span>优惠金额：<span>{{ '- ¥' + ordercouponPrice().toFixed(2) }}</span>
        </p>
      </div>

      <!-- 支付信息 -->
      <div class="payMess" v-if="orderMessage">
        <p class="payPrice"><span v-if="drawIds && drawIds.length && payPrice() == 0.01"
            class="warn">（订单最少支付0.01元）</span>实付款：<span>¥{{ payPrice() }}</span></p>
        <p class="addressMess" v-if="addressFlag && address">
          <span>寄送至：{{ address.proviceName }}{{ address.cityName }}{{ address.areaName ? address.areaName : '' }}{{ address.address }}</span>
          <span class="namePnone">收货人：{{ address.consignee }} {{ address.phone }}</span>
        </p>
      </div>

      <!-- 同意加入会员协议 -->
      <div class="agreement" v-if="kfxhVisible">
        <a-checkbox v-model="kfxhAgree">
          同意成为康复协会会员，查看<a @click="isEquityShow = true">《会员权益》</a>
        </a-checkbox>
      </div>

      <!-- 提交按钮 -->
      <div class="submit">
        <p class="all-btn-small" @click="submitOrder">提交订单</p>
      </div>
    </div>
    <!-- 添加发票 -->
    <invoiceModal :invoiceType="1" :isAddInvoiceModal="isAddInvoiceModal" @onCancelAdd="onCancelAdd"
      @onConfirmAdd="onConfirmAdd"></invoiceModal>

    <TipsModal :isPublicTipsShow="isPublicTipsShow" :tipsText="popupTxt" @setEvent="getEvent" />
    <!-- 地址管理 -->
    <a-modal title="新建收货地址" :visible="addressVisible" :closable="false" :confirm-loading="confirmLoading" :width="616"
      @cancel="handleCancel">
      <template slot="footer">
        <div class="bottombtn">
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleSubmit">
            确认
          </a-button>
        </div>
      </template>

      <div class="modalcenter">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-model-item label="收货人" prop="consignee">
            <a-input class="forminput" placeholder="请输入姓名" v-model="form.consignee" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="phone">
            <a-input class="forminput" placeholder="请输入手机号码" v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item class="require" label="收货地址">
            <a-select @change="getRegions($event, false)" class="selects" v-model="form.provice" placeholder="选择省份">
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option v-for="(item, index) in proviceList" :key="'#f' + index" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-select @change="getRegion" v-model="form.city" class="selects" placeholder="选择市">
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option v-for="(item, index) in cityList" :key="'#g' + index" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-select v-model="form.area" class="selects" placeholder="选择区县">
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option v-for="(item, index) in areaList" :key="'#h' + index" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <a-input class="forminput" placeholder="请输入详细地址" v-model="form.address" />
          </a-form-model-item>
        </a-form-model>
        <a-radio-group v-model="form.isDefault" @change="onChange">
          <a-radio class="radio" :value="1">设为默认地址</a-radio>
        </a-radio-group>
      </div>
    </a-modal>
    <Sidebar ref="sidebar" />
    <EquityModal :isEquityShow="isEquityShow" @closeEvent="isEquityShow = false" />

  </div>
</template>

<script>
import invoiceModal from "@/components/model/invoiceModal.vue"; // 添加发票抬头
import Sidebar from "@/components/sidebar.vue";
import EquityModal from "../knowledgeMall/EquityModal.vue";


export default {
  // 可用组件的哈希表
  components: { invoiceModal, Sidebar, EquityModal },
  data() {
    return {
      flag: false,
      isEquityShow: false,
      addressVisible: false, // 地址管理弹框状态
      confirmLoading: false, // 地址管理确认弹框loading
      isPublicTipsShow: false,
      popupTxt: '',
      proviceList: [], //省
      cityList: [], //市
      areaList: [], //区
      ifvisible: 0, //判断是否是新建

      kfxhVisible: false,  // 康复协会协议是否展示
      kfxhAgree: true, // 是否同意加入康复协会会员   /* 默认同意 */

      couponId: null, // 已选优惠券id
      isUsecouponList: null, // 已选优惠券
      drawIds: null, // 已选优惠券ids
      priceDifference: null, // 差价
      submitLoading: false,
      address: null, // 选中收货地址
      price: 0, // 总价
      isInvoice: 0, // 是否开具发票 1.是 0.否
      couponFlag: true, // 优惠券显隐
      addressMall: false,
      addressId: null, // 收货地址id
      addressStatus: false,
      parameters: null,
      type: null,
      invoiceId: null, // 发票id
      addressFlag: false,
      isAddInvoiceModal: false, //添加抬头弹框
      orderMessage: null, // 订单信息
      // 优惠券列表
      couponList: [],
      // 发票列表
      invoiceList: [],
      // 地址列表
      addressList: [],
      // 商品列表
      productList: [],
      // 赠品列表
      giftList: [],
      // 套餐包含商品
      packedList: [],
      // 地址参数
      form: {
        address: null, //详细地址
        provice: undefined, //省
        city: undefined, //市
        area: undefined, //区
        consignee: "", //收货人
        isDefault: 0, //是否默认-0否1是
        phone: "", //	手机号
        id: undefined,//收货地址id
      },
      // 校验方法
      rules: {
        consignee: [
          { required: true, message: "请输入姓名" },
          {
            pattern: /^[^0-9]{2,20}$/,
            message: "姓名格式有误",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号有误",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址" },
        ],
      },

      drawPrice: 0, // 订单优惠价格
    }
  },
  methods: {
    onChange(e) {
      console.log("radio checked", e.target.value);
    },
    // 添加发票弹框 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 添加发票弹框 - 确定
    onConfirmAdd() {
      this.getInvoiceList();
      this.isAddInvoiceModal = false;
    },
    // 修改发票状态
    changeIsInvoice() {
      this.isInvoice = 0
      this.invoiceId = null;
      this.$store.commit('updateInvoiceId', null);
    },
    // 修改发票信息
    changeInvoiceId(e) {
      this.invoiceId = e;
      this.$store.commit('updateInvoiceId', e);
    },
    // 修改收货地址
    changeAddressId(e) {
      this.address = e;
      this.addressId = e.id;
      this.$store.commit('updateAddress', e.id);
      this.addressList.map(item => {
        if (item.id == this.addressId) {
          let list = this.parameters.map(ite => {
            ite['province'] = item.provice; // 省
            ite['city'] = item.city; // 市
            return ite;
          })
          this.$set(this, 'parameters', list);
        }
      })
      this.getOrderMessage();
    },
    // 地址管理
    // 校验
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.form.provice) {
            return this.$message.error('请选择省')
          }
          if (!this.form.city) {
            return this.$message.error('请选择市')
          }
          if (!this.form.area) {
            return this.$message.error('请选择区')
          }
          let form = JSON.parse(JSON.stringify(this.form));
          if (!form.isDefault) {
            form['isDefault'] = 0;
          }
          this.$ajax({
            url: this.ifvisible
              ? "/hxclass-pc/ship/address/update"
              : "/hxclass-pc/ship/address/add",
            method: this.ifvisible ? "put" : "post",
            params: form,
          }).then((res) => {
            if (res.code == 200 && res.success) {
              if (this.ifvisible) {
                // this.addressList.map(item=>{
                //   if(item.id == this.form.id){
                //     for (const key in this.form) {
                //       item[key] = this.form[key];
                //     }
                //   }
                // })
                this.getAddressList();
              } else {
                this.getAddressList();
              }
              this.$message.success(res.message);
              this.addressVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 打开提示框
    showPop(type, index) {
      this.state = type;
      this.changeItem = index;
      this.isPublicTipsShow = true;
      if (type == 1) {
        // 删除地址
        this.popupTxt = "确认删除该地址吗？";
      } else if (type == 2) {
        // 设为默认
        this.popupTxt = "确认是否将该地址设置为默认地址？";
      } else if (type == 3) {
        // 取消默认
        this.popupTxt = "确认是否取消该默认地址？";
      }
    },
    // 弹窗回调事件
    getEvent() {
      /** 需要执行的任务 **/
      // 删除
      if (this.state == 1) {
        this.$ajax({
          url: "/hxclass-pc/ship/address/delete?id=" + this.changeItem,
          method: "delete",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            this.addressList.map((item, index) => {
              if (item.id == this.changeItem) {
                this.addressList.splice(index, 1);
              }
            });
            this.isPublicTipsShow = false;
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (this.state == 2 || this.state == 3) {
        // 设为默认
        let type = this.state == 2 ? 1 : 0; //1设置 0取消
        // 2设为默认 3取消默认
        this.$ajax({
          url:
            "/hxclass-pc/ship/address/isdefault?id=" +
            this.changeItem +
            "&type=" +
            1,
          method: "PUT",
          params: {},
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            // 设为默认
            this.addressList.map(item => {
              if (item.id == this.changeItem) {
                item.isDefault = 1;
              } else {
                item.isDefault = 0;
              }
            })
            // this.changeItem = 1;
          } else {
            this.$message.error(res.message);
          }
        });
        this.isPublicTipsShow = false;
      }
    },
    // 查市
    getRegion(e, flag) {
      this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + e,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            // 存市，并清除区
            this.areaList = res.data;
            if (flag) {
              return
            }
            this.form.area = undefined;
          } else {
            this.areaList = [];
            this.form.area = undefined;
          }
        }
      });
    },
    // 查省区
    getRegions(e, flag) {
      this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + e,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            if (e == 0) {
              // 存省并清除市
              this.proviceList = res.data;
              this.cityList = [];
              this.areaList = [];
              this.form.city = undefined;
              this.form.area = undefined;
            } else {
              // 有省存市
              this.cityList = res.data;
              this.areaList = [];
              if (flag) {
                return
              }
              this.form.area = undefined;
              this.form.city = undefined;
            }
          } else {
            // 上层函数onRegionItem里已赋三级地区值，如果三级没城市的时候重置一下
            this.areaName = "";
            this.addressIndex = 1;
            this.regionForm.area = [];
          }
        }
      });
    },
    //  取消按钮
    handleCancel(e) {
      this.addressVisible = false;
    },
    // 打开弹窗操作
    showModal(e, r) {
      if (e) {
        for (const key in this.form) {
          this.form[key] = r[key];
        }
        this.getRegions(this.form.provice, true);
        this.getRegion(this.form.city, true);
      } else {
        for (const key in this.form) {
          this.form[key] = undefined;
        }
      }
      this.addressVisible = true;
      this.ifvisible = e;
    },

    // 切换优惠券
    changeCouponId(e) {
      // 无选中优惠券时
      if (!this.isUsecouponList || !this.isUsecouponList.length) {
        this.isUsecouponList = [e];
        this.drawIds = [e.drawId];
        this.discountAmount();
        this.$store.commit('updateCouponList', this.isUsecouponList);
        return
      }

      let stopCheck = false; // 已选择列表中含有同一批次优惠券
      let flag = false; // 是否是取消选中
      // 可叠加
      this.isUsecouponList.some((item, index) => {
        // 是否取消选中
        if (item.drawId == e.drawId) {
          flag = true;
          this.isUsecouponList.splice(index, 1);
          this.drawIds.splice(index, 1);
          return true;
        }

        // 判断已选中优惠卷列表中是否含有同一批次优惠券
        if (item.couponId == e.couponId) {
          stopCheck = true;
          return true;
        }

        // 判断是否可叠加使用
        if (!item.overly) {
          this.isUsecouponList.splice(index, 1);
          this.drawIds.splice(index, 1);
          return true;
        }
      })

      // 取消选中
      if (flag) {
        if (this.isUsecouponList.length) {
          this.discountAmount();
        } else {
          this.drawPrice = 0;
        }
        this.$store.commit('updateCouponList', this.isUsecouponList);
        return
      }

      if (stopCheck) {
        return this.$message.warning('当前优惠券不可叠加使用！')
      }

      // 不可叠加使用
      if (!e.overly) {
        this.isUsecouponList = [e];
        this.drawIds = [e.drawId];
        this.discountAmount();
        this.$store.commit('updateCouponList', this.isUsecouponList);
        return
      } else {
        this.isUsecouponList.push(e);
        this.drawIds.push(e.drawId);
        this.discountAmount();
        this.$store.commit('updateCouponList', this.isUsecouponList);
      }
      // 原选择逻辑
      // if(e.couponId == this.couponId){
      //   this.isUsecouponList = [];
      //   this.couponId = '';
      //   this.$store.commit('updateCouponList',[]);
      //   this.discountAmount(); // 价格计算
      //   return
      // }
      // this.isUsecouponList = [e];
      // this.couponId = e.couponId;
      // this.$store.commit('updateCouponList',[e]);
      // this.discountAmount(); // 价格计算
    },
    // 获取可用/不可用优惠券列表
    getCouponList() {
      this.$ajax({
        url: '/hxclass-pc/pc-mall/app/mall-shop/new',
        method: 'post',
        params: {
          products: this.funCouponData(), // 确认订单信息
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
          if (res.data && res.data.length) {
            this.changeCouponId(res.data[0])
          }
        }
      })

      // 旧版获取优惠券逻辑
      // this.$ajax({
      //   url:'/hxclass-pc/pc-mall/app/confirm/coupon',
      //   method:'post',
      //   params:{
      //     page:1, // 页数
      //     size:100000, // 每页条数
      //     type:1, // 1可用 2，不可用
      //     productList:this.parameters, // 确认订单信息
      //   }
      // }).then(res=>{
      //   if(res.code == 200 && res.success){
      //     this.couponList = res.data.records;
      //     this.$ajax({
      //       url:'/hxclass-pc/pc-mall/app/confirm/coupon',
      //       method:'post',
      //       params:{
      //         page:1, // 页数
      //         size:100000, // 每页条数
      //         type:2, // 1可用 2，不可用
      //         productList:this.parameters, // 确认订单信息
      //       }
      //     }).then(res=>{
      //       if(res.code == 200 && res.success){
      //         this.uncouponList = res.data.records;
      //       }
      //     })
      //   }
      // })
    },
    // 获取用户默认地址
    getDefaultAddress() {
      this.$ajax({
        url: '/hxclass-pc/ship/address/getdefault',
        method: 'get',
        params: {
          userId: this.$store.state.userInfo.userId
        }
      }).then(res => {
        // this.visible = true;
        this.getRegions(0) // 获取省列表
        if (res.code == 200 && res.success && res.data) {
          this.$store.commit('updateAddress', res.data.id);
          this.addressId = res.data.id;
          this.address = res.data;
          let list = this.parameters.map(item => {
            item['province'] = res.data.provice; // 省
            item['city'] = res.data.city; // 市
            return item;
          })
          this.$set(this, 'parameters', list);
          this.getOrderMessage();
        } else {
          this.getOrderMessage();
        }
      })
    },
    // 获取收货地址列表
    async getAddressList() {
      this.flag = false;
      let res = await this.$ajax({
        url: "/hxclass-pc/ship/address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 999
        }
      })
      this.flag = true;
      if (res.code == 200 && res.success) {
        this.addressList = res.data.records;
      }
    },
    // 获取发票抬头列表
    getInvoiceList() {
      this.$ajax({
        url: "/hxclass-pc/invoice/header/list",
        method: "get",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.invoiceList = res.data;
        }
      })
    },
    // 获取订单信息
    getOrderMessage() {
      let filteredArrWithDefaults = this.parameters.map(item => ({
        productId: item.productId, // 商品id
        relationId: item.relationId,
        count: item.count, // 数量")
        openTimeId: item.openTimeId, // 班级id
        type: item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        province: item.province, // 省
        city: item.city,// 市
        noGift: item.noGift, // 是否为赠品 0否1是
      }));
      this.$ajax({
        url: '/hxclass-pc/pc-mall/app/confirm/order',
        method: 'POST',
        params: filteredArrWithDefaults
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.orderMessage = res.data;
          // // 储存最优优惠状态
          // this.bestOffer = res.data.couponPrice;
          // this.bestCouponList = res.data.couponList;
          // // 优惠券列表
          // // if(res.data.couponList){
          // //   this.couponList = res.data.couponList;
          // // }
          this.productList = res.data.productList.filter(item => {
            return item.noGift === 0;
          })
          let arr = res.data.productList.filter(item => {
            return item.type == 9;
          })

          // 检索是否有康复会员商品
          let kfxhMumberList = res.data.productList.filter(item => {
            return item.kfxhMumber == 1;
          })
          if (kfxhMumberList.length) {
            this.kfxhVisible = true
          } else {
            this.kfxhVisible = false
          }

          if (arr.length) {
            this.giftList = [];
            this.packedList = res.data.productList.filter(item => {
              return item.noGift === 1;
            })
          } else {
            this.packedList = [];
            this.giftList = res.data.productList.filter(item => {
              return item.noGift === 1;
            })
          }
          this.price = 0;
          this.productList.map(item => {
            this.price += item.totalPrice;
          })

          if (this.type == 1 && arr.length == 0) {
            this.getCouponList();
          }

          // // 新优惠券逻辑
          // if (this.$store.state.confirmOrder.couponList && this.$store.state.confirmOrder.couponList.length) {
          //   this.isUsecouponList = this.$store.state.confirmOrder.couponList;
          //   this.drawIds = this.isUsecouponList.map(item => { return item.drawId });
          //   this.discountAmount();
          // }

          // 原优惠券逻辑
          // vuex 中有没有存储优惠券列表  有：不存储调取获取优惠价格接口  没有：存储
          // if(this.$store.state.confirmOrder.couponList && this.$store.state.confirmOrder.couponList.length){
          //   this.isUsecouponList = this.$store.state.confirmOrder.couponList;
          //   this.couponId = this.$store.state.confirmOrder.couponList[0]?.couponId;
          //   this.discountAmount();
          // }else{
          //   this.isUsecouponList = res.data.couponList;
          //   this.couponId = res.data.couponList[0]?.couponId;
          //   this.$store.commit('updateCouponList',res.data.couponList)
          // }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 处理优惠券数据请求格式
    funCouponData() {
      let filteredArrWithDefaults = this.orderMessage.productList.map(item => ({
        productId: item.productId, // 商品id
        count: item.count, // 数量")
        openTimeId: item.openTimeId, // 班级id
        couponPrice: item.relationId ? item.couponPrice:null,
        type: item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        province: item.province, // 省
        city: item.city,// 市
        noGift: item.noGift, // 是否为赠品 0否1是
      }));
      return filteredArrWithDefaults
    },
    // 根据优惠券列表和 商品列表 计算优惠价格
    discountAmount() {
      let price = 0
      let arr = this.isUsecouponList.map(item => {
        price += item.couponPrice;
        return item.drawId;
      })

      this.$ajax({
        url: '/hxclass-pc/pc-mall/app/verifyCouponUsage',
        method: 'post',
        params: {
          userDrawId: arr,
          products: this.funCouponData()
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          if (price != res.data) {
            this.priceDifference = (price * 100 - res.data * 100) / 100;
          } else {
            this.priceDifference = null;
          }
          this.drawPrice = res.data;
        } else {
          this.$message.error(res.message);
        }
      })


      // this.$ajax({
      //   url:'/hxclass-pc/pc-mall/calculate/discount/amount',
      //   method:'post',
      //   params:{
      //     couponList:this.isUsecouponList,
      //     productList:this.productList
      //   }
      // }).then(res=>{
      //   if(res.code == 200 && res.success){
      //     let list = res.data.data.filter(item=>{
      //       return item.noGift === 0;
      //     })
      //     if(!this.isUsecouponList || !this.isUsecouponList.length){
      //       list.map((item)=>{
      //         item.actualPrice = item.count*item.couponPrice;
      //       })
      //     }
      //     this.$set(this,'productList',list)
      //     this.orderMessage.couponPrice = res.data.price?res.data.price:0;
      //   }else{
      //     this.$Toast(res.message);
      //   }
      // })
    },

    // 订单优惠价格计算
    ordercouponPrice() {
      return ((this.orderMessage.couponPrice * 100) + (this.drawPrice * 100)) / 100;
    },

    // 实际付款计算
    payPrice() {
      let price = ((this.price * 100) + (this.orderMessage.freightPrice * 100) - (this.ordercouponPrice() * 100)) / 100;
      if (price <= 0) {
        let arr = this.productList.filter(item => {
          return item.type != 9;
        })
        if (arr && arr.length) {
          price = 0.01 * arr.length;
        } else {
          price = 0.01;
        }
      }
      return price.toFixed(2);
    },
    // 提交订单
    submitOrder() {
      if (this.addressFlag && !this.addressId) {
        return this.$message.error('请选择地址')
      }
      if (this.addressFlag && this.orderMessage.noFreight == 2) {
        return this.$message.error('不配送')
      }
      if (this.isInvoice == 1 && !this.invoiceId) {
        return this.$message.error('请选择发票抬头')
      }
      let couponIds = [];
      let couponName = [];
      if (this.isUsecouponList) {
        couponIds = this.isUsecouponList.map(item => {
          couponName.push(item.name);
          return item.drawId;
        })
      }
      let list = null;
      let arr = this.productList.filter(item => {
        return item.type != 9;
      })
      let packArr = this.productList.filter(item => {
        return item.type == 9;
      })
      list = [...arr, ...this.giftList, ...this.packedList].map(item => {
        return {
          noPay: item.noPay ? 1 : 0,
          courseDeferralDaysNotes: item.type == 7 ? item.delaysNum ? `课程延期天数：${item.delaysNum}天` : '' : item.type == 6 ? item.days ? `课程延长天数：${item.days}天` : '' : '', // 课程延期天数说明
          discountPrice: '', // 优惠价
          examNumberNotes: item.type == 7 ? item.makeNum ? `补考次数：${item.makeNum}次；` : '' : item.type == 4 ? `${item.answerNumber}` : item.type == 6 ? item.examNumber ? `赠送考试机会：${item.examNumber}次` : '' : '', // 考试次数说明
          relationId: item.relationId, // 关联关系id
          openTimeId: item.openTimeId, // 开班时间id
          otherNotes: item.type == 1 ? `${item.openTimeId ? item.openTimeId : item.courseCategory + ' | ' + item.courseHour}` : item.type == 5 ? '代为冲印费' : '', // 其他说明
          payPrice: item.actualPrice ? item.actualPrice : item.totalPrice, // 实付价
          productCount: item.count, // 产品数量
          productId: item.productId, // 产品id
          productPrice: item.couponPrice, // 产品价格
          productType: item.type, // 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他
          type: this.packedList.length ? 1 : item.noGift + 1, // 类型：1.正常，2.赠品
          kfxhMumber: (item.kfxhMumber == 1 ? (this.kfxhAgree ? 1 : 0) : 0),  // 显示加入康复会员商品时判断是否勾选加入，加入传1，非0
        }
      })

      // 实付价判断
      let payPrice = 0;
      if ((this.price * 100 - this.ordercouponPrice() * 100 + this.orderMessage.freightPrice * 100) / 100 <= 0) {
        if (arr && arr.length) {
          payPrice = 0.01 * arr.length;
        } else {
          payPrice = 0.01;
        }
      } else {
        payPrice = (this.price * 100 - this.ordercouponPrice() * 100 + this.orderMessage.freightPrice * 100) / 100;
      }

      let params = {
        couponIds: couponIds, // 优惠卷id
        couponName: couponName.join(','), // 优惠卷名称
        couponPrice: this.ordercouponPrice(), // 优惠价
        freightPrice: this.orderMessage.freightPrice, // 运费
        invoiceId: this.invoiceId, // 发票id
        isInvoice: 1, // 是否开具发票1.是 0.否
        orderPrice: this.price, // 订单价
        orderType: this.type, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
        payPrice, // 实付价
        productList: list, // 产品信息
        receiveId: this.addressId, // 收货id
        source: this.packedList.length ? 1 : '',//活动来源:1.套餐
        sourceId: packArr.length ? packArr[0].productId : '' // 活动来源id
      }
      if (this.submitLoading) {
        return
      }
      this.submitLoading = true;
      this.$ajax({
        url: '/hxclass-pc/order/',
        method: 'post',
        params: params
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.$router.push('/pay/payMethod?orderId=' + this.$AES.encode_data(res.data.orderId + ''))
        } else {
          this.submitLoading = false;
          if (res.code == 1001) {
            const h = this.$createElement;
            this.$info({
              title: '提示',
              content: h('div', {}, [
                h('p', res.message)
              ]),
              onOk() { },
            });
          } else {
            this.$message.error(res.message);
          }
        }
      })
    },
  },
  async created() {
    let list = null;
    let giftList = [];

    let isPack = false; // 套餐不查询优惠券列表
    list = this.$store.state.confirmOrder.list.map(item => {
      if (item.type == 9) {
        isPack = true;
      }
      if (item.productList) {
        item.productList.map(ite => {
          giftList.push({
            productId: ite.productId, // 商品id
            count: ite.count ? ite.count : 1, // 数量")
            openTimeId: ite.openTimeId, // 班级id
            type: ite.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            province: '', // 省
            city: '',// 市
            noGift: 1, // 是否为赠品 0否1是
          })
        })
      }
      return {
        productId: item.productId, // 商品id
        count: item.count, // 数量")
        openTimeId: item.openTimeId, // 班级id
        relationId: item.relationId,
        couponPrice: item.price,
        type: item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
        province: '', // 省
        city: '',// 市
        noGift: 0, // 是否为赠品 0否1是
      }
    })
    this.parameters = [...list, ...giftList];
    this.type = this.$store.state.confirmOrder.type; // 订单类型
    this.invoiceId = this.$store.state.confirmOrder.InvoiceId; // 发票id
    if (this.$store.state.confirmOrder.InvoiceId) {
      this.isInvoice = 1;
    }
    // 获取发票抬头列表
    this.getInvoiceList();
    // 获取收货地址列表
    // await this.getAddressList()
    // if(this.type == 1 && !isPack){
    //   this.getCouponList();
    // }
    if (this.$store.state.confirmOrder.address) {
      this.addressId = this.$store.state.confirmOrder.address;
    }
    this.parameters.map(async item => {
      if (item.type == 2 || item.type == 3) {
        // 获取收货地址列表
        await this.getAddressList()
        // 有实物
        this.addressFlag = true;
      }
    })
    setTimeout(() => {
      // 如果没有实物直接获取订单信息
      if (!this.addressFlag) {
        this.getOrderMessage();
      }
    }, 1000)
  },
  watch: {
    addressFlag(newVal, oldVal) {
      if (newVal) {
        if (!this.addressId) {
          this.getDefaultAddress();
        } else {
          this.addressList.map(item => {
            if (item.id == this.addressId) {
              this.address = item;
              let list = this.parameters.map(ite => {
                ite['province'] = item.provice; // 省
                ite['city'] = item.city; // 市
                return ite;
              })
              this.$set(this, 'parameters', list);
            }
          })
          this.getRegions(0)
          this.getOrderMessage();
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.couponList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  .couponItem {
    cursor: pointer;
    width: 180px;
    height: 232px;
    margin-right: 32px;
    background-image: url("~@/assets/image/order/coupon.png");
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 22px;
    position: relative;

    .price {
      height: 82px;
      font-size: 30px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: #38312E;
      line-height: 81px;

      // text-shadow: 0px 0px -1px #000000;
      span {
        font-size: 68px;
      }
    }

    .condition {
      margin-top: 11px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1C1714;
      line-height: 18px;
    }

    .type {
      padding: 0 13px;
      margin-top: 5px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1C1714;
      line-height: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .time {
      margin-top: 8px;
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #A97D52;
      line-height: 10px;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 38px;
      height: 26px;
    }
  }
}

.agreement {
  margin-top: 16px;
  text-align: right;

  .ant-checkbox-wrapper {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;

    a {
      color: #999999;
    }
  }
}

.submit {
  margin-top: 24px;
  display: flex;
  justify-content: right;
}

.confirmOrder {
  padding-top: 92px;
  padding-bottom: 60px;

  .content {
    margin-top: 24px;
    padding: 30px 40px;
    border-radius: 5px;
    background: #ffffff;

    .payMess {
      height: 132px;
      background: #F1FBFD;

      .payPrice {
        padding-top: 24px;
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        text-align: right;
        padding-right: 30px;

        .warn {
          font-size: 13px;
          margin-right: 10px;
        }

        span {
          min-width: 125px;
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: bold;
          color: #EC6C01;
          line-height: 36px;
        }
      }

      .addressMess {
        text-align: right;
        padding-right: 30px;
        margin-top: 24px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;

        .namePnone {
          margin-left: 70px;
        }
      }
    }

    .priceMessage {
      margin-top: 40px;
      padding-right: 30px;

      .price {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        text-align: right;
        margin-bottom: 24px;

        .warn {
          width: 300px;
          margin-right: 10px;
          white-space: nowrap;
          font-size: 13px;
          color: #EC6C01;
        }

        span {
          display: inline-block;
          width: 125px;
          color: #333333;
        }
      }
    }

    .warning {
      margin-top: 14px;
      padding-left: 15px;

      .warnText {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        margin-bottom: 8px;
      }

      .warnText::before {
        content: "·";
        position: relative;
        left: -3px;
      }

      .warnText:last-child {
        margin: 0;
      }
    }

    .invoiceList {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;

      .marginStyle:nth-child(3n+1) {
        margin-left: 0;
      }

      .marginStyle:nth-child(n+4) {
        margin-top: 20px;
      }

      .marginStyle {
        margin-left: 2%;
      }

      .invoiceAdd {
        cursor: pointer;
        width: 32%;
        margin-left: 2%;
        padding-top: 48px;
        padding-bottom: 41px;
        text-align: center;
        border: 2px dashed #C4C4C4;

        img {
          width: 39px;
          height: 39px;
        }

        p {
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
          margin-top: 7px;
        }
      }

      .invoiceItem {
        cursor: pointer;
        width: 32%;
        padding: 12px 20px;
        border: 2px dotted #C4C4C4;
        border-left: 0;
        border-right: 0;
        position: relative;

        .box_left_top,
        .box_left_bottom,
        .box_right_top,
        .box_right_bottom {
          width: 25px;
          height: 25px;
          background: #C4C4C4;

          .writeBox {
            width: 21px;
            height: 21px;
            background: #FFFFFF;
          }
        }

        .box_left_top {
          position: absolute;
          left: -1px;
          top: -2px;

          .writeBox {
            position: relative;
            left: 4px;
            top: 4px;
          }
        }

        .box_left_bottom {
          position: absolute;
          left: -1px;
          bottom: -2px;

          .writeBox {
            position: relative;
            left: 4px;
          }
        }

        .box_right_top {
          position: absolute;
          right: -1px;
          top: -2px;

          .writeBox {
            position: relative;
            top: 4px;
          }
        }

        .box_right_bottom {
          position: absolute;
          right: -1px;
          bottom: -2px;
        }

        .email {
          margin-top: 16px;
          font-size: 16px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }

        .code {
          margin-top: 35px;
          font-size: 16px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }

        .message {
          display: flex;
          align-items: baseline;

          img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }

          .headerType {
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            min-width: 38px;
            line-height: 27px;
            margin-right: 18px;
          }

          .name {
            font-size: 18px;
            font-family: PingFang HK-Medium, PingFang HK;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
          }
        }
      }

      .invoiceActive {
        border: 2px dotted #15B7DD;
        border-left: 0;
        border-right: 0;

        .box_left_top,
        .box_left_bottom,
        .box_right_top,
        .box_right_bottom {
          background: #15B7DD;
        }
      }
    }

    .invoiceType {
      display: flex;
      margin-top: 24px;

      .type {
        cursor: pointer;
        width: 165px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        margin-right: 36px;
        position: relative;
      }

      .pick {
        border: 1px solid #15B7DD;
        background-color: rgba(21, 183, 221, 0.0400);
        background-image: url('~@/assets/image/pay/right.png');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: right bottom;
      }
    }

    .borderbox {
      // border-top: 1px solid #F2F2F2;
      // margin-top: 40px;
      margin-bottom: 30px;
    }

    .productList {
      margin-top: 24px;
      margin-left: 12px;
      border: 1px solid #EBEBEB;
      border-top: 0;

      .otherProduct {
        margin: 20px 0;
        padding-left: 49px;
        font-size: 16px;
        font-family: PingFang HK-Medium, PingFang HK;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }

      .productItem {
        margin: 0 37px;
        border-bottom: 1px solid #EBEBEB;

        .productMess {
          padding: 28px 0;
          display: flex;

          img {
            width: 151px;
            height: 87px;
            background-repeat: no-repeat;
            object-fit: cover;
          }

          .img {
            text-align: center;
          }

          .product {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4px 18px;
            font-size: 14px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            text-align: left;

            .type {
              color: #1E97B4;
            }
          }
        }
      }

      .productItem:last-child {
        border-bottom: 0;
      }

      .topTitle {
        background: #F3F3F3;
        height: 54px;
        padding: 0 37px;
      }

      .topTitle,
      .productItem {
        display: flex;
        text-align: center;
        align-items: center;

        .productMess {
          flex: 1;
        }

        .price {
          width: 160px;
        }

        .num {
          width: 160px;
        }

        .coupon {
          width: 160px;
        }

        .allPrice {
          width: 160px;
        }
      }
    }

    .addressList {
      box-sizing: border-box;
      padding-left: 12px;
      align-items: center;
      margin-top: 24px;
      // padding-bottom: 40px;
      margin-bottom: 30px;

      // border-bottom: 1px solid #F2F2F2;
      .addressMall {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 21px;

        span {
          cursor: pointer;
        }

        .icon {
          transform: rotate(-90deg);
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .addressItem {
        cursor: pointer;
        display: flex;
        height: 52px;
        align-items: center;
        padding-left: 18px;
        padding-right: 40px;
        font-size: 16px;
        border: 1px solid #ffffff;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 19px;

        .name {
          width: 217px;
        }

        .phone {
          width: 213px;
        }

        .btns {
          width: 168px;
          display: flex;
          justify-content: right;

          .btn {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #15B7DD;
            line-height: 19px;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .address {
          flex: 1;

          span {
            display: inline-block;
            margin-left: 16px;
            width: 80px;
            height: 28px;
            background: rgba(21, 183, 221, 0.1200);
            border-radius: 2px 2px 2px 2px;
            font-size: 16px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #15B7DD;
            line-height: 28px;
            text-align: center;
          }
        }
      }

      .addressActive {
        border-radius: 2px;
        border: 1px solid #15B7DD;
      }
    }

    // 标题
    .moduletitle {
      height: 36px;
      font-size: 24px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #15b7dd;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        span {
          display: inline-block;
          background-color: #15b7dd;
          height: 19px;
          width: 4px;
          border-radius: 7px;
          margin-right: 12px;
        }
      }

      .all-btn-small {
        width: 125px;
        height: 32px;
        line-height: 32px;
        background: #15B7DD;
        border-radius: 2px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    @media screen and (max-width:16000px) {
      .moduletitle {
        font-size: 22px;
      }
    }
  }
}

.require {
  /deep/label::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.invoiceNote {
  width: 367px;
  margin-top: 20px;
}

.invoicewarnText {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 21px;
  margin-top: 12px;
}
</style>