<template>
  <!-- 支付成功/提交成功 -->
  <div class="payOk">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">">
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item
        ><router-link to="/pay/payMethod"
          >收银台</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="payType==1" style="color: #15b7dd">支付成功</a-breadcrumb-item>
      <a-breadcrumb-item v-else style="color: #15b7dd">对公转账</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="box_con">
      <div class="con_icon">
        <!-- 补考 -->
        <img v-if="productType == 3" src="@/assets/image/pay/success2.png" alt="成功">
        <!-- 冲印费 -->
        <img v-else-if="productType == 4" src="@/assets/image/pay/success3.png" alt="成功">
        <!-- 其他 -->
        <img v-else src="@/assets/image/pay/success.png" alt="成功">
      </div>
      <!-- 补考和冲印费支付成功提示 -->
      <div class="con_main" v-if="productType == 3 || productType == 4">
        <div v-if="payType==1" class="main_title">{{ productType==3 ? '报名成功！' : '支付成功！' }}</div>
        <div v-else class="main_title">提交成功！</div>
        <!-- 支付宝或微信支付 -->
        <div class="main_info main_info_nother" v-if="payType==1">
          <span class="info_txt auto_txt" v-if="productType == 3">考试申请将于{{auditTime}}统一审核,如在该时间前未学完课程,本次考试申请将被驳回</span>
          <span class="info_txt auto_txt" v-else>冲印费用已支付成功，请返回云课堂网页，确认考试证书信息。</span>
          <div class="flex" v-if="productType == 3">
            <div class="order" @click="goDetail()">查看订单</div>
            <div class="all-btn-small" @click="goSignup()">查看报名信息</div>
          </div>
        </div>
        <!-- 对公转账 -->
        <div class="main_info" v-else>
          <span class="info_txt">工作人员确认收款后，会在1个工作日内联系您。</span>
          <div class="all-btn-small" @click="goDetail()">查看订单</div>
        </div>
      </div>
      
      <div class="con_main" v-else>
        <div v-if="payType==1" class="main_title">恭喜您，支付成功啦！</div>
        <div v-else class="main_title">提交成功！</div>
        <div class="main_info">
          <span v-if="payType==1" class="info_txt">您已成功支付<span class="light">{{(orderDetail.payPrice*100/100).toFixed(2)}}</span>元。</span>
          <span v-else class="info_txt">工作人员确认收款后，会在1个工作日内联系您。</span>
          <div class="all-btn-small" @click="goDetail()">查看订单</div>
        </div>
        <div class="main_info">
          <span class="info_txt">扫描二维码添加专属客服，了解更多。</span>
          <img class="info_code" src="@/assets/image/nav/banner_person.png" alt="添加客服">
        </div>
      </div>
    </div>
    <img style="width:100%;margin-bottom:30px" src="@/assets/image/20230620-01.png" alt="">
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      payType: 0, //1支付成功 2对公转账成功
      orderId: null, //订单ID
      orderDetail: {}, //订单详情
      productType: '', // 3.补考  4.冲印费
      auditTime:'' //报名审核时间
    }
  },
  // 事件处理器
  methods: {
    // 订单详情
    getOrderDetail() {
      this.$ajax({
        url: "/hxclass-pc/order/" + this.orderId,
        method: 'get',
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderDetail = res.data
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 查看订单
    goDetail() {
      this.$router.push({
        path: '/knowledgeMall/orderDetail',
        query: {
          orderId: this.$AES.encode_data(this.orderId)
        }
      });
    },

    // 查看报名信息
    goSignup() {
      this.$router.push({
        path: '/study/study'
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // 1支付成功 2对公转账成功
    this.payType = this.$route.query.type;
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    this.productType = (this.$route.query.productType && this.$route.query.productType != 'undefined') ? this.$AES.decode_data(this.$route.query.productType) : '';
    this.auditTime = (this.$route.query.auditTime && this.$route.query.auditTime != 'undefined') ? this.$AES.decode_data(this.$route.query.auditTime) : '';
    // 订单详情
    this.getOrderDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  .payOk{
    width: 1400px; 
    margin: 0 auto;
    padding-top: 92px;
    .box_con {
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      margin-bottom: 40px;
      padding: 145px 240px 180px 240px;
      background: #fff;
      border-radius: 5px;
      .con_icon {
        img {
          width: 392px;
        }
      }
      .con_main {
        min-width: 369px;
        .main_title {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
        .main_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 32px;
          margin-bottom: 6px;
          .info_txt {
            width: 210px;
            font-size: 16px;
            line-height: 29px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .light {
              color: #EC6C01;
              font-size: 24px;
            }
          }
          .auto_txt{
            width: 369px;
          }
          .all-btn-small {
            width: 138px;
            height: 46px;
            line-height: 46px;
            font-size: 16px;
          }
          .info_code {
            width: 120px;
            height: 120px;
            border: 1px solid #EBEBEB;
            // padding: 8px;
            padding-top: 3px;
            margin-right: 9px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .main_info_nother{
          display: inline-block;
          max-width: 369px;
          .flex{
            display: flex;
            margin-top: 24px;
          }
          .order{
            display: inline-block;
            text-align: center;
            background: #fff;
            color: #333333;
            width: 138px;
            height: 46px;
            line-height: 46px;
            border-radius: 23px;
            border: 1px solid #666666;
            margin-right: 60px;
            cursor: pointer;
          }
        }
      }
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .payOk {
      width: 1200px;
      .box_con {
        padding: 145px 160px 180px 160px;
      }
    }
  }
</style>
