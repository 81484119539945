<template>
  <!-- 目录菜单组件 -->
  <div class="catalogue-list">
    <div
      class="tree-item"
      v-for="onceItem in arr"
      :key="onceItem.sectionId + 'oncePid'"
    >
      <!-- 第一行 一级标题 -->
      <div class="first-line" @click="onActive(onceItem, 'oncePid')">
        <div
          class="firstName"
          :class="{ 'firstName-active': sectionId == onceItem.sectionId }"
        >
        <!-- :src="onceItem.isMenuOpen ? openImg : downImg" -->
          <img
            class="img"
            :src="onceItem.isMenuOpen ? downImg : openImg"
            v-show="onceItem.childList"
            @click.stop="onOpen(onceItem)"
          />
          {{ onceItem.sectionName }}
        </div>
        <Option
          :isBuy="isHave"
          @nodeClick="nodeClick(onceItem, 'oncePid')"
          :courseId="courseId"
          :courseName="courseName"
          :obj="onceItem"
          :isOrder="isOrder"
        />
      </div>
      <!-- 二级 -->
      <div
        class="second-child"
        v-show="onceItem.childList && onceItem.isMenuOpen"
      >
        <div
          class="tree-item"
          v-for="secondItem in onceItem.childList"
          :key="secondItem.sectionId + 'secondPid'"
        >
          <div class="first-line" @click="onActive(secondItem, 'secondPid')">
            <div
              class="firstName"
              :class="{ 'firstName-active': sectionId == secondItem.sectionId }"
            >
            <!-- :src="secondItem.isMenuOpen ? openImg : downImg" -->
              <img
                class="img"
                :src="secondItem.isMenuOpen ? downImg : openImg"
                v-show="secondItem.childList"
                @click.stop="onOpen(secondItem)"
              />
              {{ secondItem.sectionName }}
            </div>
            <Option
              :isBuy="isHave"
              @nodeClick="nodeClick(secondItem, 'secondPid')"
              :courseId="courseId"
              :courseName="courseName"
              :obj="secondItem"
              :isOrder="isOrder"
            />
          </div>
          <!-- 三级 -->
          <div
            class="third-child"
            v-show="secondItem.childList && secondItem.isMenuOpen"
          >
            <div
              class="tree-item"
              v-for="thirdItem in secondItem.childList"
              :key="thirdItem.sectionId + 'treePid'"
            >
              <div class="first-line" @click="onActive(thirdItem, 'treePid')">
                <div
                  class="firstName"
                  :class="{
                    'firstName-active': sectionId == thirdItem.sectionId,
                  }"
                >
                <!-- :src="thirdItem.isMenuOpen ? openImg : downImg" -->
                  <img
                    class="img"
                    :src="thirdItem.isMenuOpen ? downImg : openImg"
                    v-show="thirdItem.childList"
                    @click.stop="onOpen(thirdItem)"
                  />
                  {{ thirdItem.sectionName }}
                </div>
                <Option
                  :isBuy="isHave"
                  @nodeClick="nodeClick(thirdItem, 'treePid')"
                  :courseId="courseId"
                  :courseName="courseName"
                  :obj="thirdItem"
                  :isOrder="isOrder"
                />
              </div>
              <!-- 四级 -->
              <div
                class="third-child"
                v-show="thirdItem.childList && thirdItem.isMenuOpen"
              >
                <div
                  class="tree-item"
                  v-for="fourthItem in thirdItem.childList"
                  :key="fourthItem.sectionId + 'fourthPid'"
                >
                  <div
                    class="first-line"
                    @click="onActive(fourthItem, 'fourthPid')"
                  >
                    <div
                      class="firstName"
                      :class="{
                        'firstName-active': sectionId == fourthItem.sectionId,
                      }"
                    >
                    <!-- :src="fourthItem.isMenuOpen ? openImg : downImg" -->
                      <img
                        class="img"
                        :src="fourthItem.isMenuOpen ? downImg : openImg"
                        v-show="fourthItem.childList"
                        @click.stop="onOpen(fourthItem)"
                      />
                      {{ fourthItem.sectionName }}
                    </div>
                    <Option
                      :isBuy="isHave"
                      @nodeClick="nodeClick(fourthItem, 'fourthPid')"
                      :courseId="courseId"
                      :courseName="courseName"
                      :obj="fourthItem"
                      :isOrder="isOrder"
                    />
                  </div>
                  <!-- 五级 -->
                  <div
                    class="third-child"
                    v-show="fourthItem.childList && fourthItem.isMenuOpen"
                  >
                    <div
                      class="tree-item"
                      v-for="fiveItem in fourthItem.childList"
                      :key="fiveItem.sectionId + 'fivePid'"
                    >
                      <div
                        class="first-line"
                        @click="onActive(fiveItem, 'fivePid')"
                      >
                        <div
                          class="firstName"
                          :class="{
                            'firstName-active': sectionId == fiveItem.sectionId,
                          }"
                        >
                        <!-- :src="fiveItem.isMenuOpen ? openImg : downImg" -->
                          <img
                            class="img"
                            :src="fiveItem.isMenuOpen ? downImg : openImg"
                            alt=""
                            v-show="fiveItem.childList"
                            @click.stop="onOpen(fiveItem)"
                          />

                          {{ fiveItem.sectionName }}
                        </div>
                        <Option
                          :isBuy="isHave"
                          @nodeClick="nodeClick(fiveItem, 'fivePid')"
                          :courseId="courseId"
                          :courseName="courseName"
                          :isOrder="isOrder"
                          :obj="fiveItem"
                        />
                      </div>
                      <!-- 六级 -->
                      <div
                        class="third-child"
                        v-show="fiveItem.childList && fiveItem.isMenuOpen"
                      >
                        <div
                          class="tree-item"
                          v-for="sixItem in fiveItem.childList"
                          :key="sixItem.sectionId + 'sixPid'"
                        >
                          <div
                            class="first-line"
                            @click="onActive(sixItem, 'sixPid')"
                          >
                            <div
                              class="firstName"
                              :class="{
                                'firstName-active':
                                  sectionId == sixItem.sectionId,
                              }"
                            >
                              {{ sixItem.sectionName }}
                            </div>
                            <Option
                              :isBuy="isHave"
                              @nodeClick="nodeClick(sixItem, 'sixPid')"
                              :courseId="courseId"
                              :courseName="courseName"
                              :isOrder="isOrder"
                              :obj="sixItem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <Shopwarning :visible="visible" :fontText="fontText" />
  </div>
</template>

<script>
import Option from "./option.vue";
export default {
  // 可用组件的哈希表
  components: { Option },
  // 接收传值
  props: {
    arr: {
      type: Array,
      default: () => [],
    },
    // 是否购买
    isHave: {
      type: null,
      default: null,
    },
    // 选中章节id
    sectionId: {
      type: Number,
      default: null,
    },
    // 课程id
    courseId: {
      type: Number,
      default: null,
    },
    // 课程课程名称
    courseName: {
      type: String,
      default: null,
    },
    // 课程顺序播放
    isOrder: {
      type: Boolean,
      default: true,
    },
  },
  // 数据对象
  data() {
    return {
      openImg: require("@/assets/image/selected/open.png"),
      downImg: require("@/assets/image/selected/down.png"),
      activeIndex: "",
      visible: false,
      fontText: "系统升级，请下载华夏云课堂app进行试看",
      sectionIdArry: [], // 需要展开的id集合
    };
  },
  // 事件处理器
  methods: {
    onOpen(e) {
      e.isMenuOpen = !e.isMenuOpen;
      let i = this.sectionIdArry.indexOf(e.sectionId)
      if(e.isMenuOpen && i < 0){
        this.sectionIdArry.push(e.sectionId)
      } else if(!e.isMenuOpen && (i != -1)) {
        this.sectionIdArry.splice(i, 1)
      }
      // this.getOpenSectionId()
    },
    // 获取已打开的章节id
    getOpenSectionId(){
      let closeSectionIdArry = [];
      // 记录已关闭的目录
      function fors(arr){
        if (arr.length !== 0) {
          arr.forEach((item) => {
            if(item.isMenuOpen){
              closeSectionIdArry.push(item.sectionId)
            }
            if (item.childList) {
              item.childList = fors(item.childList);
            }
          });
        }
        return arr;
      }
      fors(this.arr)
      // this.$store.commit("updatecloseSectionIdArry", closeSectionIdArry);
      localStorage.setItem('SectionIdArry', JSON.stringify(closeSectionIdArry))
    },
    nodeClick(item, pid) {
      
      this.onActive(item, pid);
    },
    // 点击章节名称高亮
    onActive(item, pid) {
      // 非末级，不做业务处理
      if(item.childList && item.childList.length){
        return
      }
      // 有视频时并且已购买
      if (item.fileId && this.isHave == 1) {
        // 当前章节加锁
        if (item.lock == 1 && item.isHaveSelfTest == 1) {
          this.$message.error("请先学习上一节课程并通过小节测试");
        } else if (item.lock == 1 && item.isHaveSelfTest == 0) {
          this.$message.error("请先学习完上一节课程");
        } else {
          if(item.passStatus == 1){
            item.passStatus = 2
          }
          // this.activeIndex = item.sectionId + pid
          this.$emit("nodeClick", item.sectionId);
        }
      } else if (item.fileId && this.isHave == 0) {
        // 当前章节加锁
        if(item.isFreeTrial==1){
          this.activeIndex = item.sectionId + pid
          this.$emit('nodeClick', item.sectionId)
        } else if(item.lock==1) {
          this.$message.error('您当前没有购买此课程，不可观看');
        }
        // this.visible = true;
      }
    },
    // changeisMenuOpen(arr){
    //   arr[0].isMenuOpen = true;
    //   if(arr[0].childList && arr[0].childList.length){
    //     this.changeisMenuOpen(arr[0].childList);
    //   }
    // },

    // 打开当前播放的所有父级章节
    changeOpenSelection(){
      let parentList = []  // 存放所有父级关系集合
      let _that = this
      parentList = _that.getParentAreas(_that.sectionId, _that.flatTreeAndSetLevel(_that.arr))
      parentList.forEach(element => {
        if(element.parentId && (_that.sectionIdArry.indexOf(element.parentId) < 0)){
          _that.sectionIdArry.push(element.parentId)
        }
      });

      function fors(arr){
        if (arr.length !== 0) {
          arr.forEach((item) => {
            if(_that.sectionIdArry.indexOf(item.sectionId) != -1){
              item.isMenuOpen = true
            }
            if (item.childList) {
              item.childList = fors(item.childList);
            }
          });
        }
        return arr;
      }

      this.arr = fors(this.arr);
    },

    // 1. 先将目录树形结构扁平化，处理为同级
    flatTreeAndSetLevel(tree, level = 1) {
      const list = []
      tree.forEach(item => {
        const o = JSON.parse(JSON.stringify(item))
        if(o.childList) delete o.childList
        o.level = level
        list.push(o)
        if(item.childList && item.childList.length) {
          list.push(...this.flatTreeAndSetLevel(item.childList, level + 1))
        }
      })
      return list
    },
    // 2. 找出当前播放章节id的所有父级关系
    getParentAreas(sectionId, list) {
      const target = []
      const o = list.find(item => item.sectionId == sectionId) || {}
      if(JSON.stringify(o) != '{}') target.push(o)
      if(o.parentId) target.push(...this.getParentAreas(o.parentId, list))
      return target
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // this.$nextTick(()=>{
    //   this.getOpenSectionId()
    // })
    setTimeout(()=>{
      // 找出当前播放的章节id所有父级关系
      this.changeOpenSelection()


      // this.changeisMenuOpen(this.arr);
    },700)
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    sectionId(){
      this.changeOpenSelection()
    },

    arr(){
      this.changeOpenSelection()
    }
  },
};
</script>

<style lang="less" scoped>
.catalogue-list {
  .tree-item {
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 22px;
    .first-line {
      display: flex;
      justify-content: space-between;
      padding: 5px 22px 5px 52px;
      background: rgba(0, 0, 0, 0.04);
      cursor: pointer;
      .firstName {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        font-family: PingFangMedium;
        position: relative;
        .img {
          width: 17px;
          left: -27px;
          top: 6px;
          position: absolute;
        }
      }
      @media screen and (max-width:16000px) {
        .firstName {
          font-size: 18px;
        }
      }
      .firstName-active {
        color: @theme;
      }
    }
    .second-child {
      margin: 18px 0 0 72px;
      .tree-item {
        margin-bottom: 0;

        .first-line {
          margin-bottom: 16px;
          padding: 0 22px 0 0;
          background: #ffffff;
          .firstName {
            font-size: 18px;
            font-family: PingFangMedium;
          }
          @media screen and (max-width:16000px) {
            .firstName {
              font-size: 16px;
            }
          }
        }
      }
    }
    .third-child {
      margin: 0 0 0 22px;
      .first-line {
        margin-bottom: 10px !important;
        .firstName {
          font-size: 16px !important;
          font-family: PingFangMedium;
        }
        @media screen and (max-width:16000px) {
          .firstName {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .catalogue-list{
    padding-left: 15px;
    .tree-item{
      padding-left: 5px;
      .first-line{
        padding: 5px;
        flex-wrap: wrap;
        .firstName{
          font-size: 16px;
          line-height: 28px;
        }
      }
      .second-child{
        margin: 18px 0 0 8px;
      }
      .third-child{
        margin: 0;
      }
    }
  }
  /deep/ .all-side-r{
    flex-wrap: wrap;
    .ant-progress{
      display: none;
    }
    .progress{
      width: auto;
      margin-left: 0;
      font-size: 12px;
      margin-right: 10px;
      span{
        width: auto;
      }
    }
    .nameTime{
      font-size: 12px;
      margin-left: 0;
      min-width: auto;
      .name{
        text-align: left;
        width: auto;
      }
      .time{
        margin-left: 12px;
        margin-right: 10px;
        width: auto;
      }
    }
    .option{
      margin-left: 0;
      text-align: right;
    }
  }
}
</style>
