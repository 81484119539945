<template>
  <div class="main-wrap">
    <a-spin :spinning="spinning">
      <div class="input-box">
        <h4 class="title-h4">收货地址</h4>
        <p class="all-btn-small" v-if="listdata.length" @click="showModal(0)">新建地址</p>
      </div>

      <div class="card" v-for="(item, index) in listdata" :key="index">
        <div class="cardimg">
          <img src="@/assets/image/personalCenter/20220728-105939.png" alt="" />
        </div>
        <div class="cardcenter">
          <div class="top">
            <img
              src="@/assets/image/personalCenter/20220728-110003.png"
              alt=""
            />
            <span class="span1">{{ item.consignee }}</span>
            <span class="span2"
              >{{ item.phone.substr(0, 3) }}****{{
                item.phone.substr(7, 11)
              }}</span
            >
            <div v-if="item.isDefault == 1" class="default">默认</div>
          </div>
          <div class="center">
            <span>
              {{ item.proviceName }}{{ item.cityName }}{{ item.areaName
              }}{{ item.address }}
            </span>
          </div>
          <div class="bottom">
            <span v-if="item.isDefault != 1" @click="showPop(2, item.id)">
              <img
                src="@/assets/image/personalCenter/20220728-105957.png"
                alt=""
              />设为默认地址
            </span>
            <span @click="showModal(1, item)">
              <img
                src="@/assets/image/personalCenter/20220728-105954.png"
                alt=""
              />编辑
            </span>
            <span @click="showPop(1, item.id)">
              <img
                src="@/assets/image/personalCenter/20220728-110000.png"
                alt=""
              />删除
            </span>
          </div>
        </div>
        <div class="cardimg">
          <img src="@/assets/image/personalCenter/20220728-105950.png" alt="" />
        </div>
      </div>

      <!-- <div class="btn-foot">
      <p class="cancel">取消</p>
    </div> -->
      <div class="none" v-if="!listdata.length && !spinning">
        <img src="@/assets/image/personalCenter/20220729-100305.png" alt="" />
        <span>暂无地址，<a @click="showModal(0)">新建收货地址</a></span>
      </div>
    </a-spin>

    <TipsModal
      :isPublicTipsShow="isPublicTipsShow"
      :tipsText="popupTxt"
      @setEvent="getEvent"
    />

    <a-modal
      title="Title"
      :visible="visible"
      :closable="false"
      :confirm-loading="confirmLoading"
      :width="616"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <div class="bottombtn">
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" :loading="loading" @click="handleSubmit">
            确认
          </a-button>
        </div>
      </template>

      <div class="modalcenter">
        <p>新建收货地址</p>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="收货人" prop="consignee">
            <a-input
              class="forminput"
              placeholder="请输入姓名"
              v-model="form.consignee"
            />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="phone">
            <a-input
              class="forminput"
              placeholder="请输入手机号码"
              v-model="form.phone"
            />
          </a-form-model-item>
          <a-form-model-item label="收货地址">
            <a-select
              @change="getRegions"
              class="selects"
              v-model="form.provice"
              placeholder="选择省份"
            >
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option
                v-for="(item, index) in proviceList"
                :key="index"
                :value="item.code"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>

            <a-select
              @change="getRegion"
              v-model="form.city"
              class="selects"
              placeholder="选择市"
            >
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option
                v-for="(item, index) in cityList"
                :key="index"
                :value="item.code"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>

            <a-select
              v-model="form.area"
              class="selects"
              placeholder="选择区县"
            >
              <a-icon slot="suffixIcon" type="caret-down" theme="filled" />
              <a-select-option
                v-for="(item, index) in areaList"
                :key="index"
                :value="item.code"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <a-input
              class="forminput"
              placeholder="请输入详细地址"
              v-model="form.address"
            />
          </a-form-model-item>
        </a-form-model>
        <a-radio-group v-model="form.isDefault" @change="onChange">
          <a-radio class="radio" :value="1">设为默认地址</a-radio>
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 地址参数
      form: {
        address: null, //详细地址
        provice: undefined, //省
        city: undefined, //市
        area: undefined, //区
        consignee: "", //收货人
        isDefault: 0, //是否默认-0否1是
        phone: "", //	手机号
        id: undefined, //收货地址id
      },
      isPublicTipsShow: false,

      isHavePSW: false,
      tipsIndex: null,
      confirm: "", //确认密码
      // 弹窗内容
      visible: false,
      confirmLoading: false,
      loading: false,
      formLayout: "horizontal",
      // 校验方法
      rules: {
        consignee: [
          { required: true, message: "请输入姓名" },
          {
            pattern: /^[^0-9]{2,20}$/,
            message: "姓名格式有误",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "手机号有误",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址" },
          // {
          //   pattern: /^[^0-9]{2,20}$/,
          //   message: "详细地址有误",
          // },
        ],
      },
      listdata: [], //地址列表
      changeItem: null, //暂存id
      popupTxt: null, //暂存文案

      proviceList: [], //省
      cityList: [], //市
      areaList: [], //区
      spinning: false,
      ifvisible: 0, //判断是否是新建
    };
  },
  // 事件处理器
  methods: {
    onChange(e) {
      // console.log("radio checked", e.target.value);
    },
    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.isLogin = true;
          this.$store.commit("updateUserInfo", res.data);
        }
      });
    },

    // 获取地址信息列表
    async getList() {
      this.spinning = true;
      let res = await this.$ajax({
        url: "/hxclass-pc/ship/address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 10,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res);
          this.listdata = res.data.records;
        }
      });
      this.spinning = false;

      return res;
    },
    // 查市
    async getRegion(e) {
      let res = await this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + e,
        method: "get",
      });
      if (res.code == 200 && res.success) {
        if (res.data.length) {
          // 存市，并清除区
          this.areaList = res.data;
          this.form.area = undefined;
        }
      }
      return res;
    },
    // 查省区
    async getRegions(e) {
      // console.log(e);
      let res = await this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + e,
        method: "get",
      });
      if (res.code == 200 && res.success) {
        if (res.data.length) {
          if (e == 0) {
            // 存省并清除市
            this.proviceList = res.data;
            this.cityList = [];
            this.areaList = [];
            this.form.city = undefined;
            this.form.area = undefined;
          } else {
            // 有省存市
            this.cityList = res.data;
            this.areaList = [];
            this.form.area = undefined;
            this.form.city = undefined;
          }
        } else {
          // 上层函数onRegionItem里已赋三级地区值，如果三级没城市的时候重置一下
          this.areaName = "";
          this.addressIndex = 1;
          this.regionForm.area = [];
        }
      }
      return res;
    },

    inputChange() {
      this.tipsIndex = null;
    },
    // 打开提示框
    showPop(type, index) {
      this.state = type;
      this.changeItem = index;
      this.isPublicTipsShow = true;
      if (type == 1) {
        // 删除地址
        this.popupTxt = "确认删除该地址吗？";
      } else if (type == 2) {
        // 设为默认
        this.popupTxt = "确认是否将该地址设置为默认地址？";
      } else if (type == 3) {
        // 取消默认
        this.popupTxt = "确认是否取消该默认地址？";
      }
    },
    // 弹窗回调事件
    getEvent() {
      /** 需要执行的任务 **/
      // 删除
      if (this.state == 1) {
        this.$ajax({
          url: "/hxclass-pc/ship/address/delete?id=" + this.changeItem,
          method: "delete",
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            this.listdata.map((item, index) => {
              if (item.id == this.changeItem) {
                this.listdata.splice(index, 1);
                if (
                  this.listdata.length &&
                  this.pageSize == this.listdata.length
                ) {
                  this.pageNum--;
                }
              }
            });
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (this.state == 2 || this.state == 3) {
        // 设为默认
        let type = this.state == 2 ? 1 : 0; //1设置 0取消
        // 2设为默认 3取消默认
        this.$ajax({
          url:
            "/hxclass-pc/ship/address/isdefault?id=" +
            this.changeItem +
            "&type=" +
            1,
          method: "PUT",
          params: {},
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success(res.message);
            // 设为默认
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
      this.isPublicTipsShow = false;
    },
    // 打开弹窗操作
    async showModal(e, r) {
      if (r) {
        // 获取区县
        let resb = await this.getRegions(r.provice);
        // 获取市
        let resa = await this.getRegion(r.city);
      }
      if (e) {
        for (const key in this.form) {
          this.form[key] = r[key];
          // console.log(r[key] + "==" + this.form[key]);
        }
      } else {
        for (const key in this.form) {
          this.form[key] = undefined;
        }
      }

      this.visible = true;
      this.ifvisible = e;
    },
    //  取消按钮
    handleCancel(e) {
      this.visible = false;
    },
    // 校验
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$ajax({
            url: this.ifvisible
              ? "/hxclass-pc/ship/address/update"
              : "/hxclass-pc/ship/address/add",
            method: this.ifvisible ? "put" : "post",
            params: this.form,
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
              this.$message.success(res.message);
              this.visible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelectChange(value) {
      // console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 检查是否有密码
    this.isHavePSW = this.$store.state.userInfo.password ? true : false;
    this.getList();
    this.getRegions(0);
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.input-box {
  display: flex;
  justify-content: space-between;
}
.btn-foot {
  margin-top: 40px;
  display: flex;
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 138px;
    border-radius: 40px;
    height: 40px;
    color: @theme;
    margin-right: 54px;
    border: 1px solid @theme;
    cursor: pointer;
  }
}
.card {
  width: 1006px;
  background-color: #fff;
  margin-bottom: 32px;
  .cardimg {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .cardcenter {
    width: 100%;
    padding: 0 24px;
    .top {
      display: flex;
      align-items: flex-end;
      img {
        width: 17px;
        height: 19px;
      }
      .span1 {
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: 500;
        margin-left: 11px;
        color: #333333;
      }
      .span2 {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        margin-left: 16px;
        color: #333333;
      }
    }
    .center {
      width: 100%;
      height: 38px;
      margin-top: 15px;
      margin-bottom: 16px;
      border-bottom: 1px solid #ebebeb;
      span {
        height: 21px;
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        margin-left: 27px;
      }
    }
    .bottom {
      width: 100%;
      padding: 0 24px;
      span {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        margin-right: 24px;
        cursor: pointer;
      }
      img {
        width: 16px;
        margin-right: 2px;
      }
    }
    .default {
      width: 32px;
      height: 17px;
      background: #15b7dd;
      box-shadow: inset 0px 0px 4px 0px #c9f4ff;
      border-radius: 2px 2px 2px 2px;
      font-size: 12px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 17px;
      margin-left: 16px;
    }
  }
}
.bottombtn {
  text-align: center;
  .ant-btn {
    width: 113px;
    height: 36px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 18px;
  }
}
.forminput {
  width: 300px;
  height: 41px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.modalcenter {
  width: 100%;
  .selects {
    width: 138px;
    height: 41px;
    margin-right: 10px;
    border-radius: 4px 4px 4px 4px;
  }
  .radio {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    margin-left: 100px;
  }
  p {
    font-size: 20px;
    font-family: PingFangMedium;
    font-weight: 500;
    display: block;
    color: #333333;
    margin-left: 203px;
    margin-bottom: 24px;
  }
}

.none {
  margin-top: 124px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  img {
    width: 218px;
    height: 218px;
    display: block;
    margin-top: 124px;
    margin: 0 auto;
  }
  span {
    font-size: 20px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
    display: inline-block;
    margin-top: 24px;
    line-height: 23px;
  }
}
/deep/.ant-modal-footer {
  border-top: #15b7dd 0px solid;
}
/deep/.ant-modal-header {
  display: none;
}
/deep/.ant-col-4 {
  text-align: left;
  label {
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #333333;
  }
}
/deep/.ant-modal-body {
  padding: 24px 40px;
}
@media screen and (max-width: 16000px) {
  .main-wrap {
    .title-h4 {
      font-size: 22px !important;
    }
  }
  .Tip {
    font-size: 22px !important;
  }
  .card {
    width: 824px !important;
  }
}
</style>
