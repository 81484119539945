<template>
  <div class="main-wrap">
    <!-- 顶部banner -->
    <div class="banner-top">
      <img class="banner-bg" src="../../assets/image/publicBenefit/publicBenefitBanner.png" alt="">
      <div class="goMallList">
        <div class="see-all" @click="olist">
          <span>查看全部<a-icon type="right" /></span>
        </div>
      </div>
      <div class="title-top">
        <p class="name">科普公益</p>
        <span class="line"></span>
        <p class="name-eg">PUBLIC BENEFIT</p>
      </div>
      <div class="product-head" v-animate="'product-animate'" v-if="WelfareCourseList && WelfareCourseList.length">
        <div class="title">
          <h4 class="public-h4"><img src="../../assets/image/publicBenefit/icon_01.png" alt="">公益课程</h4>
          <!-- <span class="more">查看所有></span> -->
        </div>
        <div class="list-between">
          <div class="item-side" v-for="item in WelfareCourseList" :key="item.id">
            <div class="label"><span>{{item.title}}</span></div>
            <div class="item-box" v-for="cItem in item.productColumnRelations" :key="'child' + cItem.id" @click="toPage(cItem)">
              <!-- :src="cItem.homePage" -->
              <img class="cover" v-lazy="cItem.homePage" :key="cItem.homePage" alt="">
              <div class="view-r">
                <div class="info">
                  <p class="name">{{cItem.productName}}</p>
                  <span class="num">视频数量：{{cItem.classHour}}个</span>
                </div>
                <p class="all-btn-small">立即观看</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 百问百答 -->
    <div class="QA margin-area">
      <h4 class="public-h4"><img src="../../assets/image/publicBenefit/icon_07.png" alt="">百问百答</h4>
    </div>
    <a-tabs class="aTabs" v-model="tabIndex" @change="changeTab" v-animate="'topbpttom'" v-if="bwbdList && bwbdList.length">
      <div class="searchBox" slot="tabBarExtraContent">
        <a-input-search placeholder="搜索" style="width: 200px" @search="onSearch"/>
      </div>
      <a-tab-pane :key="item.columnCode" :tab="item.columnTitle" v-for="item in bwbdList">
        <div class="columnMess">
          <div class="banner-box swiper-container">
            <swiper ref="mySwiperBanner" class="swiper-wrapper" :options="bannerOption">
              <swiper-slide
                class="swiper-slide t_box"
                v-for="i in item.teacherList"
                :key="i.id"
              >
                <img :src="i.bannerLink" alt="">
                <div class="t_mess">
                  <div>
                    <p class="name">{{ i.bannerTitle.split('/')[0] }}</p>
                    <p class="tag" v-if="i.bannerTitle.split('/')[1].trim()">{{ i.bannerTitle.split('/')[1] }}</p>
                  </div>
                  <p class="speciality onlyOneHidden">{{ i.bannerTitle.split('/')[2] }}</p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="bwbd-voice-next" v-if="item.teacherList && item.teacherList.length>8"><a-icon class="aIcon" type="right" /></div>
          <div class="bwbd-voice-prev" v-if="item.teacherList && item.teacherList.length>8"><a-icon class="aIcon" type="left" /></div>
        </div>
        
        <div class="details" v-if="item.children">
          <a-carousel class="a_carousel" :style="{height: item.children.length > 9?'552px':'680px'}" arrows :dots="false" effect="fade">
            <div slot="prevArrow" class="custom-slick-arrow" style="top: -50px;left: 0;">
              <a-icon type="caret-up" />
            </div>
            <div slot="nextArrow" class="custom-slick-arrow" style="top: calc(100% + 35px);left: 0;">
              <a-icon type="caret-down" />
            </div>
            <div v-for="index in Math.ceil(item.children.length/9)" :key="index+'parent'">
              <template v-for="(i,j) in item.children">
                <div class="carousel_item" :class="{carousel_item_active: i.columnCode == childColumnCode}" @click="changeChildColumn(i)" :key="j+'child'" v-if="j >= ((index - 1)*9) && j < ((index - 1)*9+9)">
                  {{ i.columnTitle }}
                </div>
              </template>
            </div>
          </a-carousel>
          <a-spin class="demo-loading" v-if="loading"/>

          <div class="nomess" v-if="!getinfo(item.children,childColumnCode).length">暂无数据~~</div>

          <div class="infos" :class="{'infoHide' : !showList.length}" @scroll="handleInfiniteOnLoad($event, item.children)" v-if="getinfo(item.children,childColumnCode).length">

            <div class="info" @click="goArticleDetail(item)" v-for="(item,index) in getinfo(item.children,childColumnCode)" :key="item.id">
              <p class="title">{{ item.articleTitle }}</p>
              <!-- <p class="description">{{ item.overview }}</p> -->
              <div class="description" :class="{'hidden' : showList.length ? showList[index].isHide && !showList[index].isShow : false}" :id="'hideText' + item.id" v-html="item.pcContent"></div>
              <div class="fold-btn open" v-if="showList.length ? showList[index].isHide && !showList[index].isShow : false" @click="showList[index].isShow = true">展开 <a-icon type="down" /></div>
              <div class="fold-btn" v-if="showList.length ? showList[index].isHide && showList[index].isShow : false" @click="showList[index].isShow = false">收起 <a-icon type="up" /></div>
              <!-- <img src="@/assets/image/RevisionHome/toDetailIcon.gif" alt=""> -->
            </div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>

    <!-- 新知共享 -->
    <div class="voice-box" v-animate="'topbpttom'" v-if="voiceList && voiceList.length">
      <div class="category-box pad-box">
        <div class="leftTitle">
          <p class="logo"></p>
          <p class="title">孤独症之声</p>
        </div>
      </div>

      <div class="tab-box" v-show="false">
        <div class="tab-left" v-if="vtabList.length > 0"></div>
        <div class="swiper" ref="tabWidth">
          <template>
            <swiper
              ref="tabSwiper"
              :options="voiceOptiont"
              v-if="vtabList.length > 0"
            >
              <swiper-slide
                v-for="item in vtabList"
                :key="item.autismClassificationId + 'eight'"
              >
                <div class="industryitem">
                  <div class="tab-list">
                    <div
                      class="tab-title"
                      :class="{ 'tab-active': item.isClick }"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <div
                v-show="showTabWidth"
                class="
                  swiper-button-next swiper_btn swiper-button-white
                  tab-next
                "
                slot="button-next"
                @mouseover="changeMaskTab(1)"
                @mouseout="changeMaskTab(2)"
              >
                <img
                  v-show="swiperRightTab"
                  src="@/assets/image/index/icon_next.png"
                  alt=""
                />
                <img
                  v-show="!swiperRightTab"
                  src="@/assets/image/index/icon_next1.png"
                  alt=""
                />
              </div>
            </swiper>
          </template>
        </div>
      </div>

      <div class="voice-info pad-box">{{ introduce }}</div>
      <div class="voice-course pad-box">
        <div class="swiper">
          <template>
            <swiper ref="voiceSwiper" :options="voiceOptions">
              <swiper-slide
                v-for="(item, index) in voiceList"
                :key="index + 'nine'"
              >
                <div class="industryitem">
                  <div class="sub" @click="toUrl(4, item)">
                    <img class="sub-banner" :src="item.img" alt="" />
                    <div class="sub-info">{{ item.name }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </template>
          <!-- 前进后退按钮 -->
          <div
            class="voice-prev"
            slot="button-prev"
            v-show="voiceList.length > 4"
            @mouseover="changeMaskv(1)"
            @mouseout="changeMaskv(2)"
          >
            <img
              class="proright"
              v-show="swiperLeftv"
              src="@/assets/image/knowledgeMall/bannerLeft2.png"
              alt=""
            />
            <img
              class="proright"
              v-show="!swiperLeftv"
              src="@/assets/image/knowledgeMall/bannerLeft.png"
              alt=""
            />
          </div>
          <div
            class="voice-next"
            slot="button-next"
            v-show="voiceList.length > 4"
            @mouseover="changeMaskv(3)"
            @mouseout="changeMaskv(4)"
          >
            <img
              class="proleft"
              v-show="swiperRightv"
              src="@/assets/image/knowledgeMall/bannerRight2.png"
              alt=""
            />
            <img
              class="proleft"
              v-show="!swiperRightv"
              src="@/assets/image/knowledgeMall/bannerRight.png"
              alt=""
            />
          </div>
        </div>
        <no-message :msg="'暂无数据'" v-if="!voiceList.length" />
        <a-spin class="spin" v-show="loading" />
      </div>
    </div>

    <!-- 知识解析 -->
    <!-- <div class="analysis-item margin-area" >
      <h4 class="public-h4"><img src="../../assets/image/publicBenefit/icon_03.png" alt="">知识解析</h4>
      <div class="side-list" v-animate="'topbpttom'" v-if="KnowledgeList && KnowledgeList.length">
        <div class="arrange up-levels">
          <div class="item-levels" v-for="item in KnowledgeList" :key="item.id" @click="toVideoPage(item.id,'知识解析','科普公益')">
            <img class="cover" :src="item.banner||require('@/assets/image/RevisionHome/knowsImg.png')" alt="">
            <p class="name onlyOneHidden">{{item.articleTitle}}</p>
            <div class="lable">
              <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
            </div>
          </div>
        </div>
        <div class="arrange down-levels">
          <div class="item-levels" v-for="item in KnowledgeList2" :key="item.id" @click="toVideoPage(item.id,'知识解析','科普公益')">
            <img class="cover" :src="item.banner||require('@/assets/image/RevisionHome/knowsImg.png')" alt="">
            <p class="name onlyOneHidden">{{item.articleTitle}}</p>
            <div class="lable">
              <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="see-all" v-if="KnowledgeTotal > 12" @click="$router.push('/videoList')">
        <span>查看全部<a-icon type="right" /></span>
      </div>
    </div> -->

    <!-- 科普视频 -->
    <div class="video-item margin-area" v-animate="'topbpttom'" v-if="SPVideoList && SPVideoList.length">
      <h4 class="public-h4"><img src="../../assets/image/publicBenefit/icon_04.png" alt="">科普视频</h4>
      <div class="video-list">
        <div class="side-item" v-for="item in SPVideoList" :key="item.id" @click="toVideoPage(item.id,'科普视频','科普公益')">
          <img class="cover" v-lazy="item.banner" :key="item.banner" :src="item.banner" alt="">
          <div class="info">
            <div class="head">
              <p>{{item.articleTitle}}</p>
            </div>
            <div class="detail" v-html="item.overview"></div>
            <div class="label">
              <span v-for="(label,index) in fun_split(item.label)" :key="index">#{{label}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="see-all" v-if="SPVideoTotal > SPVideoList.length">
        <span @click="onPage">查看更多<a-icon type="down" /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      WelfareCourseList:[], // 公益课程列表
      KnowledgeList:[], // 知识解析
      KnowledgeList2:[], // 知识解析
      KnowledgeTotal: 0,
      SPVideoList:[], // 科普视频
      SPVideoTotal: 0,
      SPVideoNum: 1,

      bwbdList: [], // 百问百答数据
      showList: [],
      heightList: [],
      tabIndex: '',
      title: '', // 资讯搜索条件
      bannerOption: {
        slidesPerView:"auto",
        spaceBetween: 50,
        navigation: {
          nextEl: ".bwbd-voice-next",
          prevEl: ".bwbd-voice-prev"
        },
      },

      loading: false,
      busy: false,
      childColumnCode: '',

      // 新知共享
      vtypeID: "",
      introduce: "",
      vtabList: [],
      voiceList: [],
      swiperLeftv: false,
      swiperRightv: false,
      voiceOptions: {
        slidesPerView: '7',
        slidesPerGroup: 6,
        centeredSlides: false,
        centeredSlidesBounds: false,
        navigation: {
          nextEl: ".voice-next",
          prevEl: ".voice-prev",
          disabledClass: "my-button-disabled",
        },
      },
      
      tabShow: "",
      showTabWidth: false,
      swiperRightTab: false,
      voiceOptiont: {
        slidesPerView: "auto",
        spaceBetween: 20,
        navigation: {
          nextEl: ".tab-next",
          disabledClass: "my-button-disabled",
        },
        on: {
          click: () => {
            this.onvTab();
          },
        },
      },
    };
  },
  // 事件处理器
  methods: {
    olist(w, e, r) {
      this.$router.push(
        "/knowledgeMall/educationalCurriculum?tabCode=1004&code=10"
      );
    },

    goArticleDetail(e) {
      return
      // return window.open("/ArticleDetail?id=" + this.$AES.encode_data(String(e.id)),"_black")
      this.$router.push(
        "/ArticleDetail?id=" +
          this.$AES.encode_data(String(e.id))
      );
    },
    // 跳转
    toUrl(x, item) {
      if (x == 4) {
        // 更新浏览量
        this.$ajax({
          url: "/hxclass-pc/autism/updateAutismViewNum/" + item.autismId,
          method: "put",
        }).then((res) => { });
        let lastIndex = item.fileUrl.lastIndexOf('.');
        let suffix = item.fileUrl.substring(lastIndex+1)
        if(suffix.toLowerCase() == 'epub'){
          this.$router.push(
            "/ebookDetail?url=" +
              this.$AES.encode_data(String(item.fileUrl))
          );
        } else {
          this.$router.push(
            "/voiceDetail?autismId=" +
              this.$AES.encode_data(String(item.autismId)) +
              "&vtypeID=" +
              this.$AES.encode_data(String(this.vtypeID))
          );
        }
      }
    },
    getinfo(e,code) {
      let arr = [];

      e.some(item=>{
        if(item.columnCode == code) {
          arr = item.Infolist;
          return true;
        }
      })
      if(arr.length && !this.showList.length){
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {
          this.funHeight(arr)
        }, 200);
      }
      return arr
    },

    // 计算百问百答高度
    funHeight(arr){
      arr.forEach(element => {
        let ids = 'hideText' + element.id
        const clientHeight = document.getElementById(ids).clientHeight
        this.showList.push({
          isShow: false,
          isHide: clientHeight > 150 ? true : false,
        })
      });
    },

    // 切换tab
    changeTab(e) {
      this.bwbdList.map(item=>{
        if(item.columnCode == e) {
          this.childColumnCode = item.children[0].columnCode
        }
      })
      this.showList = []
    },
    // 切换子分类
    changeChildColumn(e){
      this.childColumnCode = e.columnCode;
      if(!e.total) {
        e.pageNum = 1;
        this.loading = true
        this.getInfoList(e);
      }
      this.showList = []
      // console.log(e)
    },

    // 获取公益课程列表
    getWelfareCourse() {
      this.$ajax({
        url: "/hxclass-pc/ProductColumnRelation/client/columnAndProduct",
        params: {
          columnCode: 'AppPublicWelfareCourses',
          pageNum: 1,
          pageSize: 3,
        },
      }).then((res) => {
        if (res.code == 200 && res.success && res.data.length) {
          this.WelfareCourseList = res.data.slice(0,2)
        }
      });
    },

    // 获取百问百答分类列表
    getBWBDColumnList(e) {
      this.$ajax({
        url: '/hxclass-pc/ArticleColumn/list/' + e
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.bwbdList = res.data;
          this.tabIndex = res.data[0].columnCode;
          res.data.map(item=>{
            // 获取子分类列表
            this.getChildColumn(item)

            // 获取对应教师列表
            let locationCode = ''
            if(item.columnCode == "QA-Autism") {
                locationCode = "AutismAuthor"
            } else if(item.columnCode == "QA-LowVision") {
                locationCode = "LowVisionAuthor"
            } else if(item.columnCode == "QA-Assistive") {
                locationCode = "AssistiveDeviceAuthor"
            }
            this.getColumnRemark(item,locationCode)
          })
        }
      })
    },

    // 获取子分类列表
    getChildColumn(e) {
      this.$ajax({
        url: '/hxclass-pc/ArticleColumn/list/' + e.columnCode
      }).then(res=>{
        if(res.code == 200 && res.success) {
          res.data.map((item,index)=>{
            item.total = 0;
            item.Infolist = [];
            item.pageNum = 1;
            if(index == 0) {
              this.getInfoList(item)
            }
          })
          e.children = res.data;
          if(this.bwbdList[0].children && this.bwbdList[0].children.length) {
            this.childColumnCode = this.bwbdList[0].children[0].columnCode;
          }
        }
      })
    },

    // 搜索
    onSearch(e) {
      this.title = e;
      this.resetInfoList();
    },

    // 重置列表资讯数据
    resetInfoList() {
      this.bwbdList.map(bwbd=>{
        bwbd.children.map((item,index)=>{
          item.total = 0;
          item.Infolist = [];
          item.pageNum = 1;
          if(index == 0 || item.columnCode == this.childColumnCode) {
            this.getInfoList(item)
          }
        })
      })
    },

    // 获取资讯列表
    getInfoList(e) {
      this.$ajax({
        url: '/hxclass-pc/Article/client/list',
        params: {
          columnCode: e.columnCode,
          showContent: 1,
          title: this.title,
          pageNum: e.pageNum,
          sortType: 4,
          pageSize: 20
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.$set(e,'total',res.data.total);
          e.Infolist = e.Infolist.concat(res.data.records)
          this.$forceUpdate()
        }else{
          this.$set(e,'total',0);
          this.$set(e,'Infolist',[]);
        }
        this.loading = false;
      })
    },
    
    // 资讯列表上拉加载
    handleInfiniteOnLoad(e, list) {
      if(e.target.scrollTop + e.target.clientHeight + 10 >= e.target.scrollHeight){
        
        //触底时进行操作 
        list.some(item=>{
          if(item.columnCode == this.childColumnCode) {
            if(item.Infolist.length < item.total) {
              this.showList = []
              item.pageNum ++;
              this.getInfoList(item)
            }
            return true;
          }
        })
      }
    },

    // 循环获取百问百答作者
    getColumnRemark(e,locationCode) {
      this.$ajax({
        url: '/hxclass-pc/Banner/client/list',
        params: {
          locationCode
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.$set(e,'teacherList',res.data);
        }
      })
    },

    // 科普视频分页
    onPage() {
      this.SPVideoNum++;
      this.getClient('SPVideo', 6);
    },
    // 获取列表
    getClient(code,pageSize) {
      this.$ajax({
        url: "/hxclass-pc/Article/client/list",
        params: {
          columnCode: code,
          queryLevel: 1,
          sortType: (code == 'SPVideo' ? 3 : 1),
          pageNum: (code == 'SPVideo' ? this.SPVideoNum : 1),
          pageSize: pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(code == 'Knowledge'){ // 知识解析
            var arr = res.data.records
            if(arr.length > 6){
              this.KnowledgeList = arr.slice(0, 5)
              this.KnowledgeList2 = arr.slice(5, 10)
            } else {
              this.KnowledgeList = arr
            }
            this.KnowledgeTotal = res.data.total
          } else if (code == 'SPVideo'){
            this.SPVideoList.push(...res.data.records)
            this.SPVideoTotal = res.data.total
          }
        }
      });
    },

    // 格式化标签
    fun_split(str){
      let arr = []
      if(!str){return arr}
      arr = str.split("，")
      return arr
    },

    toPage(item){
      this.$router.push({
        path: "/knowledgeMall/courseDetail",
        query: {
          productId: this.$AES.encode_data(item.productId + ""),
          code: item.productCode,
        },
      });
    },

    
    getIntroduce(vtypeID) {
      this.$ajax({
        url: "/hxclass-pc/autism/introduce",
        params: {
          autismClassificationId: vtypeID ? this.vtypeID : vtypeID,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.introduce = res.data.name;
        }
      });
    },
    toVideoPage(id, type, from){
      // if(!this.$store.state.userInfo.userId){
			// 	return this.$router.push('/login/loginIndex?type=2');
			// }
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: this.$AES.encode_data(id + ""),
          type,
          from
        },
      });
    },

    onvTab() {
      if (this.$refs.tabSwiper.$swiper) {
        let realIndex = this.$refs.tabSwiper.$swiper.clickedIndex;
        if (!isNaN(realIndex)) {
          this.vtabList.forEach((item, index) => {
            if (index == realIndex) {
              item.isClick = true;
              this.vtypeID = item.autismClassificationId;
            } else {
              item.isClick = false;
            }
          });
        }
      }
    },
    getVoicetab() {
      this.$ajax({
        url: "/hxclass-pc/autism/select/type",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.vtabList = res.data.map((item, index) => {
            if (index == 0) {
              this.vtypeID = item.autismClassificationId;
              return { ...item, isClick: true };
            } else {
              return { ...item, isClick: false };
            }
          });

          let widthTotal = 0;
          setTimeout(() => {
            if (this.$refs.infoSwipert) {
              this.$refs.tabSwiper.$children.forEach((element) => {
                widthTotal = widthTotal + element.$el.clientWidth + 20;
              });
              this.tabShow = widthTotal;
              if (this.tabShow > this.$refs.tabWidth.offsetWidth) {
                this.showTabWidth = true;
              }
            }
          }, 500);
        }
      });
    },
    getVoice() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/autism/list",
        params: {
          autismClassificationId: this.vtypeID,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.voiceList = res.data;
        }
      });
    },
    changeMaskv(e) {
      if (e == 1) {
        this.swiperLeftv = true;
      } else if (e == 2) {
        this.swiperLeftv = false;
      } else if (e == 3) {
        this.swiperRightv = true;
      } else if (e == 4) {
        this.swiperRightv = false;
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.loading = true;
    this.getWelfareCourse()
    this.getClient('Knowledge', 10)
    this.getClient('SPVideo', 6)
    this.getBWBDColumnList('QA')
    this.getVoicetab();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    setTimeout(()=>{
      if(this.$route.query.type == 'bwbdBox'){
        window.scrollTo({
          top: 740 ,
          behavior: "smooth"
        })
      }else if(this.$route.query.type == 'keVideo'){
        window.scrollTo({
          top: 2730,
          behavior: "smooth"
        })
      }
    },1000)
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    vtypeID(newval) {
      this.getIntroduce(newval);
      this.getVoice(newval);
    },
  },
};
</script>

<style lang="less" scoped>
.main-wrap{
  margin-top: 60px;
}
.public-h4{
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 36px;
  display: inline-block;
  img{
    width: 21px;
    height: 21px;
    margin-right: 3px;
    margin-top: -4px;
  }
}

.margin-area{
  width: 1200px;
  margin: 0 auto;
}
.banner-top{
  width: 100%;
  height: 710px;
  position: relative;

  .banner-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    z-index: 0;
  }

  .goMallList{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 217px;
    background-repeat: no-repeat;
    object-fit: cover;
    z-index: 1;
    // background: linear-gradi ent(0deg, #F4F4F4 0%, rgba(244,244,244,0.32) 52%, rgba(244,244,244,0) 100%);
    .see-all{
      margin-top: 200px;
    }
  }

  .title-top{
    position: relative;
    z-index: 2;
    text-align: center;
    padding-top: 32px;
    .name{
      font-size: 30px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 45px;
    }
    .name-eg{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 14px;
    }
    .line{
      display: inline-block;
      width: 20px;
      height: 2px;
      background: #ffffff;
      margin-bottom: 16px;
    }
  }

  .product-head{
    width: 1200px;
    margin: 47px auto 0;
    z-index: 2;
    position: relative;
    .title{
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      .public-h4{
        color: #FFFFFF;
        img{
          width: 12px;
          height: 14px;
        }
      }
      .more{
        font-size: 14px;
        cursor: pointer;
      }
    }

    .list-between{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .item-side{
        width: calc((100% - 30px) / 2);
        .label{
          padding: 8px 18px;
          background: #FFFFFF;
          display: inline-block;
          border-radius: 1px;
          position: relative;
          >span{
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0,0,0,0.8);
            line-height: 27px;
            &::before{
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #15B7DD;
              margin-right: 8px;
            }
          }
          &::after{
            content: '';
            position: absolute;
            bottom: -7px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 9px solid #fff;
          }
        }
        .item-box{
          margin-top: 8px;
          width: 100%;
          padding: 20px 24px;
          background: rgba(255,255,255,0.85);
          display: flex;
          cursor: pointer;
          .cover{
            min-width: 160px;
            width: 160px;
            height: 92px;
            background-repeat: no-repeat;
            object-fit: cover;
          }
          .view-r{
            flex: 1;
            margin-left: 16px;
            position: relative;
            .info{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              max-width: calc(100% - 125px);
              height: 100%;
              .name{
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
              }
              .num{
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
              }
            }
            .all-btn-small{
              position: absolute;
              right: 0;
              bottom: 0;
              width: 100px;
              height: 36px;
              line-height: 36px;
              font-size: 14px;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
  .product-animate{
    .item-side:nth-child(1){
      animation: leftright 2s cubic-bezier(0, 0, 0.79, 1.38) forwards;
      transition: all 3s;
    }
    .item-side:nth-child(2){
      animation: rightleft 2s cubic-bezier(0, 0, 0.79, 1.38) forwards;
      transition: all 3s;
    }
  }
  // 平移从左到右
  @keyframes leftright {
    0% {
      transform: translatex(-100%);
      opacity: 0.1;
    }

    100% {
      transform: translatex(0%);
      opacity: 1;
    }
  }

  // 平移从右到左
  @keyframes rightleft {
    0% {
      transform: translatex(100%);
      opacity: 0.1;
    }

    100% {
      transform: translatex(0%);
      opacity: 1;
    }
  }
}
.QA{
  margin-top: 32px;
}
.details{
  width: 1200px;
  height: 720px;
  margin: 8px auto 0;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 24px;
  background: #ffffff;
  .nomess{
    width: 100%;
    text-align: center;
  }
  .infos{
    margin-left: 30px;
    width: 100%;
    height: 680px;
    overflow-y: auto;
    .info{
      padding: 20px;
      background: #FAFAFA;
      transition: all 0.4s ease;
      cursor: pointer;
      margin-bottom: 20px;
      position: relative;
      img{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 30px;
        top: 10px;
        display: none;
      }
      .title{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-bottom: 12px;
        transition: all 0.4s ease;
      }
      .description{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        text-indent: 1em;
      }
      .hidden{
        height: 150px;
        overflow: hidden;
      }
      .fold-btn{
        text-align: center;
        color: #666666;
        font-size: 12px;
        line-height: 36px;
      }
      .open{
        position: absolute;
        height: 36px;
        bottom: 0;
        width: calc(100% - 40px);
        background: linear-gradient(180deg, rgba(250,250,250,0) 0%, #FAFAFA 54%);
      }
    }
    .info:hover{
      box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
      .title{
        color: @theme;
      }
      img{
        display: block;
      }
    }
    .info:last-child{
      margin-bottom: 0;
    } 
    .ant-list-item{
      padding: 20px;
      background: #FAFAFA;
    }
  }
  .infoHide{
    opacity: 0;
  }
  .a_carousel{
    width: 150px;
    height: 556px;
    .carousel_item{
      width: 138px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid rgba(0,0,0,0.2);
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      text-align: center;
      margin-bottom: 24px;
      cursor: pointer;
    }
    .carousel_item:last-child{
      margin-bottom: 0;
    }
    .carousel_item_active{
      background: #15B7DD;
      border-color: #15B7DD;
      color: #FFFFFF;
      font-weight: bold;
      font-family: PingFang SC-Medium, PingFang SC;
      position: relative;
    }
    .carousel_item_active::after{
      content: '';
      width: 138px;
      height: 40px;
      background: rgba(21,183,221,0.2);
      position: absolute;
      right: -5px;
      top: -5px;
      z-index: -1;
    }
  }
}
/deep/.ant-carousel{
  .slick-slide {
    overflow: hidden;
    padding-top: 5px;
  }
  .custom-slick-arrow {
    width: 138px;
    height: 40px;
    font-size: 25px;
    color: rgba(102, 102, 102, 0.40);
    border: 1px solid rgba(0,0,0,0.2);
    text-align: center;
    line-height: 40px;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    border: 1px solid rgba(21, 183, 221, 1);
    background-color: rgba(21, 183, 221, 1);
    color: #ffffff;
  }
}
.columnMess{
  width: 1200px;
  margin: 0 auto;
  padding: 12px 20px 12px;
  background-image: url(~@/assets/image/publicBenefit/column_back.png);
  background-size: 100% auto;
  position: relative;
  .remark{
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.6);
    line-height: 23px;
  }
  .bwbd-voice-next{
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    z-index: 99;
    .aIcon {
      border-bottom-left-radius: 22px;
      border-top-left-radius: 22px;
    }
  }
  .bwbd-voice-prev{
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 99;
    .aIcon {
      border-bottom-left-radius: 22px;
      border-top-left-radius: 22px;
    }
  }
  .t_box{
    width: auto;
    display: inline-flex;
    img{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
    }
    .t_mess{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      >div{
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .name{
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
      .tag{
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin-left: 24px;
        position: relative;
      }
      .tag::before{
        content: '';
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
        width: 13px;
        height: 13px;
        background-image: url(../../assets/image/publicBenefit/tag_icon.png);
        background-size: 100% 100%;
      }
      .speciality{
        width: 100%;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 15px;
        margin-top: 4px;
        transform: scale(0.9);
        transform-origin: left;
      }
    }
  }
}
.voice-box {
  margin: 16px auto 0;
  padding: 30px 0 30px;
  width: 1200px;
  // height: 490px;
  border-radius: 5px;
  background-color: #fff;
  .tab-box {
    position: relative;
    margin-top: 24px;
    padding: 0 0 0 20px;
    height: 38px;
    overflow: hidden;
    .tab-left {
      position: absolute;
      left: 0;
      height: 38px;
      width: 20px;
      background: rgba(21, 183, 221, 0.1);
    }

    .tab-list {
      margin: 0 20px;
      .tab-title {
        cursor: pointer;
        min-width: 104px;
        height: 38px;
        font-size: 18px;
        font-family: Source Han Sans CN-Medium, SourceHan Sans CN;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 38px;
        text-align: center;
      }
      .tab-active {
        color: #fff;
        font-weight: 500;
        background: #15b7dd;
      }
    }
    /deep/.ant-carousel {
      .slick-slide {
        text-align: center;
        height: 447px;
        line-height: 160px;
        background: #364d79;
        overflow: hidden;
      }
      .slick-slide h3 {
        color: #fff;
      }
    }
    /deep/.dotsClass {
      li {
        margin: 0 10px;
        button {
          width: 24px !important;
        }
      }
    }
    // 轮播样式
    .industryitem {
      position: relative;
      z-index: 3;
    }
    // /deep/.swiper-container {
    //   overflow: inherit;
    // }
    /deep/.swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-prev:after {
      content: "";
    }
    /deep/.swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
      content: "";
    }
    /deep/.swiper-button-next {
      position: absolute;
      right: -2px;
      top: 22px;
      z-index: 5;
      height: 38px;
      width: 24px;
      background: #ebf6fb;
      img {
        position: relative;
        width: 7px;
        height: 14px;
      }
    }
    /deep/.swiper-slide {
      width: auto;
    }
    /deep/.tab-active {
      padding: 0 32px;
    }
    .swiper {
      position: relative;
      width: auto;
      display: inline-block;
      background: rgba(21, 183, 221, 0.1);
      max-width: 100%;
    }
  }
  .pad-box {
    padding: 0 30px;
  }
  .voice-info {
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangMedium;
    font-weight: 300;
    color: #666666;
    line-height: 27px;
    text-indent: 2em;
    // letter-spacing: 1px;
  }
  .voice-course {
    position: relative;
    /deep/.voice-prev {
      position: absolute;
      left: 0px;
      cursor: pointer;
      top: 122px;
      width: 20px;
      z-index: 99;
      img {
        width: 20px;
        height: 44px;
        position: relative;
      }
    }
    // afaf
    /deep/ .voice-prev:after {
      display: none;
    }
    /deep/ .voice-next {
      position: absolute;
      cursor: pointer;
      right: 0px;
      cursor: pointer;
      top: 122px;
      z-index: 99;
      img {
        width: 20px;
        height: 44px;
        border-bottom-left-radius: 22px;
        border-top-left-radius: 22px;
      }
    }
  }
  /deep/.ant-carousel {
    .slick-slide {
      text-align: center;
      height: 447px;
      line-height: 160px;
      background: #364d79;
      overflow: hidden;
    }
    .slick-slide h3 {
      color: #fff;
    }
  }
  /deep/.dotsClass {
    li {
      margin: 0 10px;
      button {
        width: 24px !important;
      }
    }
  }
  // 轮播样式
  .industryitem {
    cursor: pointer;
    position: relative;
    z-index: 3;
    .sub {
      margin-top: 30px;
      .sub-banner {
        width: 175px;
        height: 227px;
        border-radius: 5px;
        transition: all 0.3s;
        box-shadow: 6px 6px 4px 1px rgba(0, 0, 0, 0.05);
        &:hover {
          margin-top: -10px;
        }
      }
      .sub-info {
        margin-top: 12px;
        width: 175px;
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }
      &:hover {
        .sub-info {
          color: @theme;
        }
      }
    }
  }
  
  .swiper {
    position: relative;
  }
  .title{
    font-weight: bold;
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .voice-box {
    .industryitem {
      .sub {
        //  margin-top: 16px;
        .sub-banner {
          width: 121px;
          height: 174px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        .sub-info {
          width: 136px;
          font-size: 16px;
          // font-weight: bold;
        }
      }
    }
  }
}

.aTabs{
  position: relative;
  .searchBox{
    position: absolute;
    z-index: 100;
    width: 1200px;
    height: 0px;
    padding-top: 8px;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
  }
  /deep/ .ant-tabs-nav-scroll{
    text-align: center;
  }
  /deep/ .ant-tabs-nav{
    width: 1200px;
    text-align: left;
  }
  /deep/ .ant-tabs-tab{
    font-size: 18px;
  }
  /deep/ .ant-tabs-tab-active{
    font-size: 22px;
  }
}
.analysis-item{
  margin-top: 30px;
  .side-list{
    margin-top: 16px;
    height: 442px;
    background-image: url(../../assets/image/publicBenefit/bg_02.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .arrange{
      display: flex;
      .item-levels{
        padding: 14px;
        width: 196px;
        height: 206px;
        background: #FFFFFF;
        margin-right: 30px;
        transition: all 0.4s ease;
        cursor: pointer;
        .cover{
          width: 100%;
          height: 103px;
          margin-bottom: 4px;
          background-repeat: no-repeat;
          object-fit: cover;
          transition: transform 0.5s;
        }
        .name{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
        }
        .lable{
          margin-top: 6px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 15px;
          -webkit-line-clamp: 2; //（最多显示 2 行）
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          >span{
            display: inline-block;
            padding: 1px 6px;
            transform: scale(0.9);
            margin-bottom: 8px;
            background: rgba(21,183,221,0.1);
            white-space: nowrap;
          }
        }
      }
      .item-levels:hover{
        box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
        .cover{
          transform: scale(1.05);
        }
      }
    }
    .up-levels{
      position: relative;
      margin-bottom: 33px;
    }
    .down-levels{
      justify-content: right;
      position: relative;
      bottom: 3px;
      .item-levels{
        margin-left: 30px;
        margin-right: 0;
      }
    }
  }
}
.video-item{
  margin-top: 30px;
  margin-bottom: 30px;
  .video-list{
    overflow: hidden;
    .side-item{
      width: calc((100% - 30px) / 2);
      margin-right: 30px;
      margin-top: 30px;
      padding: 16px;
      background: #fff;
      float: left;
      display: flex;
      transition: all 0.4s ease;
      cursor: pointer;
      .cover{
        min-width: 168px;
        width: 168px;
        height: 104px;
        background-repeat: no-repeat;
        object-fit: cover;
        transition: transform 0.5s;
      }
      .info{
        margin-left: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .head{
          display: flex;
          justify-content: space-between;
          >p{
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 21px;
            margin-right: 30px;
          }
        }
        .label{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 18px;
          >span{
            margin-right: 10px;
          }
        }
        .detail{
          height: 42px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .side-item:hover{
      box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
      transform: translateY(-2px);
      .cover{
        transform: scale(1.05);
      }
    }
    .side-item:nth-child(2n){
      margin-right: 0;
    }
  }
}
.see-all{
  width: 180px;
  height: 28px;
  border: 1px solid #999999;
  margin: 27px auto 0;
  text-align: center;
  cursor: pointer;
  >span{
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
  }
}
.see-all:hover{
  background-color: #15B7DD;
  border-color: #15B7DD;
  span{
    color: #ffffff;
  }
}
/deep/ .demo-loading {
  position: relative;
  top: -100px;
  width: 100%;
  text-align: center;
}

/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 6px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #F0F0F0;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #F9F9F9;
    border-radius: 32px;
}
</style>
