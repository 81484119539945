<template>
  <div class="main-wrap">
    <!-- 顶部标题和搜索框 -->
    <div class="nav-inner">
      <h4 class="title-h4">我的订单</h4>
      <div class="search-box">
        <a-input class="input" v-model="keyword" placeholder="请输入商品名称/订单编号" />
        <span class="btn-span" @click="pageNum=1,getOrderList()">搜索</span>
      </div>
    </div>
    <!-- tab切换 -->
    <div class="tabs">
      <p
        v-show="tabMenu.length > 1"
        class="tabItem"
        @click="tabChange(item.code)"
        :class="{ active: item.code == tabCode }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>
    <!-- 表头 -->
    <div class="header-inner" v-if="orderList.length">
      <p>商品信息</p>
      <span>单价</span>
      <span>数量</span>
      <span>实付款</span>
      <span>订单状态</span>
      <span>操作</span>
    </div>
    <!-- 订单表格 -->
    <div class="order-form">
      <!-- 暂无课程 -->
      <a-empty
        v-if="orderList.length == 0 && !loading"
        description="暂无订单"
        style="padding-top: 200px"
        :image="require('@/assets/image/stateHint/icon_no.png')"
        :image-style="{
          height: '180px',
        }"
      >
        <span class="nodata"></span>
      </a-empty>
      <div class="order-form-item" v-for="item in orderList" :key="item.orderId + 'orderId'">
        <!-- 订单编号和创建时间 -->
        <div class="order-num">
          <span>创建时间：{{item.createTime}}</span>
          <span>订单编号：{{item.code}}</span>
        </div>
        <!-- 表格内容 -->
        <div class="form-content">
          <div class="form-left">
            <div class="product-item" v-for="subItem in item.productList" :key="subItem.id + 'productId'">
              <img class="product-cover" v-show="subItem.productPicture" :src="subItem.productPicture" alt="">
              <div class="product-r">
                <div class="info">
                  <p class="name">{{subItem.productName}}</p>
                  
                  <span class="navTime" v-if="subItem.productType == 1"
                    ><span>{{ subItem.openTime ? subItem.openTime : (subItem.validDayType == 1 ? '有效期：' + subItem.validDays + '天' : subItem.validDayType == 2 ? '长期有效' :'') }}</span>
                    <p class="offlineAddress" v-if="subItem.offlineAddress">
                      {{ subItem.offlineAddress }}
                    </p>
                  </span>
                  <span class="navTime" v-if="subItem.productType == 4">{{
                    subItem.examNumberNotes
                  }}</span>
                  <span class="navTime" v-if="subItem.productType == 5">{{
                    subItem.otherNotes
                  }}</span>
                  <span
                    class="navTime"
                    v-if="subItem.productType == 6"
                    >{{ subItem.courseDeferralDaysNotes }}
                    <br
                      v-if="subItem.examNumberNotes && subItem.courseDeferralDaysNotes"
                    />
                    {{ subItem.examNumberNotes }}</span
                  >
                  <span
                    class="navTime"
                    v-if="subItem.productType == 7"
                    >{{ subItem.examNumberNotes }}
                    <br
                      v-if="subItem.examNumberNotes && subItem.courseDeferralDaysNotes"
                    />
                    {{ subItem.courseDeferralDaysNotes }}</span
                  >
                </div>
                <!-- 价格 或 订单状态 -->
                <div class="card-width status">
                  <!-- <p class="bg">已购买</p> -->
                  <span class="price">{{
                    (subItem.type == 2 || subItem.payPrice) && subItem.productPrice
                      ? "¥" + subItem.productPrice.toFixed(2)
                      : "￥0.00"
                  }}</span>
                </div>
                <!-- 商品数量 -->
                <span class="card-width num">x{{ subItem.productCount }}</span>
              </div>
            </div>
          </div>
          <div class="form-right">
            <div class="th price">¥{{ item.payPrice ? item.payPrice.toFixed(2) : "0.00" }}</div>
            <div class="th payType">
              <div>
                <p>{{ item.refundStatus?
                      item.refundStatus == 1?'退款中':
                      item.refundStatus == 2?'部分退款':
                      item.refundStatus == 3?'全部退款':'-'
                    :item.statusTxt}}</p>
                <!-- <p v-if="
                  item.orderCreateType == 1 &&
                  item.showBtnComment &&
                  item.status == 5 &&
                  getpjStatus(item.productList)
                ">(待评价)</p> -->
              </div>
            </div>
            <!-- 操作 -->
            <div class="th operation">
              <div>
                <p class="on-btn" v-if="item.status == 1 && item.payMethod != 3" @click="goPay(item.orderId)">去支付</p>
                <p class="on-btn" v-if="item.payMethod == 3 && item.status == 1 && item.transferStatus == 0" @click="isPublicPriceShow=true">支付审核中</p>
                <!-- <p class="on-btn" v-if="item.showBtnComment && item.status == 5 && getpjStatus(item.productList)"  @click="onEvalua(item.orderId)">评价</p> -->
                <p class="on-btn" v-if="item.status == 3 || item.status == 4" @click="orderId=item.orderId, showReceiving(item)">确认收货</p>
                <p><router-link :to="'/knowledgeMall/orderDetail?orderId=' + $AES.encode_data(item.orderId + '')">订单详情</router-link></p>
                <p v-if="item.status == 3 || item.status == 4 || (item.status == 5 && getStatus(item.productList))">
                  <router-link :to="'/knowledgeMall/orderDetail?orderId=' + $AES.encode_data(item.orderId + '')">查看物流</router-link>
                </p>
                <p v-if="item.canInvoice && item.invoiceBtnStatus == 1">
                  <!-- <router-link :to="'/invoice?orderId=' + $AES.encode_data(item.orderId+ '')" @click.native="submit">申请发票</router-link> -->
                  <router-link to="" @click.native="submit">申请发票</router-link>
                </p>
                <p v-if="item.canInvoice && item.invoiceBtnStatus == 2">
                  <router-link :to="'/invoice?orderId=' + $AES.encode_data(item.orderId+ '') + '&payMethod=' + item.payMethod">发票明细</router-link>
                </p>
                <p v-if="item.canInvoice && item.invoiceBtnStatus == 3" @click="toDetail(item)">查看发票</p>
                <p v-if="item.status == 1" @click="orderId=item.orderId, isCancelOrderShow = true">取消订单</p>
                <p v-if="item.status != 1 && item.status != 6 && item.orderType == 1" @click="getCustomer(item.orderId)">教务老师</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-spin class="sping" v-show="loading" />
    </div>
    <!-- 页码 -->
    <div style="height:50px"> <!-- 占位 -->
      <div class="pagination">
        <a-pagination v-model="pageNum" show-quick-jumper :total="total" @change="onPageChange" />
      </div>
    </div>

    <!-- 取消订单 弹窗 -->
    <a-modal
      v-model="isCancelOrderShow"
      width="480px"
      :closable="false"
    >
      <div class="modal-content">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <div class="content">
          <p>请选择取消订单的原因</p>
          <a-select class="select" v-model="reason" placeholder="请选择原因"  allowClear>
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="我不想要了">我不想要了</a-select-option>
            <a-select-option value="价格有点贵">价格有点贵</a-select-option>
            <a-select-option value="暂时不需要了">暂时不需要了</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
          <p class="tips-info">
            <img
              src="@/assets/image/personalCenter/2022072501.png"
              alt=""
            />
            取消后将无法恢复，优惠券可退回，有效期内可使用</p>
        </div>
        <div class="btn-foot">
          <p class="cancel" @click="isCancelOrderShow = false">取消</p>
          <p class="all-btn-small" @click="onCancelOrder">确认</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
    <!-- 教务老师客服二维码弹窗 -->
    <a-modal
      v-model="isCustomerShow"
      width="480px"
    >
      <div class="modal-customer">
        <p class="title">
          <img
            src="@/assets/image/personalCenter/customer_icon.png"
            alt=""
          />客服联系方式
        </p>
        <div class="QR-code">
          <img :src="customerImg" alt="">
        </div>
        <p class="tips">
          {{customerTips ? customerTips : '欢迎加入华夏云课堂课程学习，请扫码添加助教老师，邀您加入学习群或提供教学支持。'}}
        </p>
        <div class="btn all-btn-small" @click="isCustomerShow = false">
          我知道了
        </div>
      </div>
      <template #footer></template>
    </a-modal>
    <!-- 确认发货 -->
    <TipsModal
      :isPublicTipsShow="isPublicTipsShow" 
      :tipsText="tipsText"
      @setEvent="getEvent" />
    <!-- 对公转账 -->
    <TipsModal
      :isPublicTipsShow="isPublicPriceShow" 
      :tipsText="PriceTxt"
      ConfirmBtnText="我知道了"
      :isCancelBtnShow="false"
      @setEvent="isPublicPriceShow=false" />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading: false,
      tabMenu: [
        {
          code: '',
          name: '全部订单'
        },
        {
          code: '1',
          name: '待付款'
        },
        {
          code: '2',
          name: '待发货'
        },
        // {
        //   code: '3',
        //   name: '待评价'
        // },
      ],
      tabCode: '',
      keyword: '',
      pageNum: 1,
      pageSize: 10,

      total: 0,
      isCancelOrderShow:false,  // 取消订单弹窗
      isCustomerShow:false,
      customerImg:'', // 客服二维码
      customerTips:'', // 添加客服提示语

      orderList: [],

      orderId: '', // 订单id

      reason:undefined, // 订单取消原因

      tipsText: '',
      confirmCount: '',
      isPublicTipsShow: false, // 确认收货弹窗
      isPublicPriceShow: false, // 对公转账审核弹窗
      PriceTxt: '您已提交对公转账信息，工作人员确认收款后，会在1个工作日内联系您。'
    }
  },
  // 事件处理器
  methods: {
    // 申请发票
    submit() {
      return this.$message.warning('请联系工作人员线下开具发票，联系电话：400-800-9002转3。')
    },
    // tab切换
    tabChange(e) {
      if(e!=this.tabCode){
        this.tabCode = e;
        this.pageNum = 1
        this.getOrderList()
      }
    },
    // 评价按钮是否展示  商品id 1 2 3 4
    getpjStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (
          e[i].productType == 1 ||
          e[i].productType == 2 ||
          e[i].productType == 3 ||
          e[i].productType == 4
        ) {
          return true;
        }
      }
    },

    // 订单是否包含实物
    getStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i].productType == 2 || e[i].productType == 3) {
          return true;
        }
      }
    },

    // 发票详情
    toDetail(e) {
      if (e.invoiceStatus == 1) {
        this.$message.warning('发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。');
      } else {
        this.$router.push(
          '/invoice/InvoiceDetail?invoiceRecordId=' + this.$AES.encode_data(e.invoiceRecordId+ '')
        );
      }
    },

    // 订单分页查询
    onPageChange(e){
      this.pageNum = e
      this.getOrderList()
      document.documentElement.scrollTop = 0;
    },
    // 获取订单列表
    getOrderList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/order/",
        method: "get",
        params: {
          keyword: this.keyword, // 关键字
          type: this.tabCode, //	类型：1.待支付，2.待收货，3.待评价
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
				if(res.code == 200 && res.success){
          this.orderList = res.data.records
          this.total = res.data.total
        }
      });
    },

    // 获取教务老师客服二维码
    getCustomer(orderId){
      this.$ajax({
        url: "/hxclass-pc/order/product/getCustomer",
        method: "get",
        params: {
          flag: 1,
          orderId: orderId,
        },
      }).then((res) => {
				if(res.code == 200 && res.success){
          this.customerImg = res.data.customerCode
					this.customerTips = res.data.customerIntroduce
					this.isCustomerShow = true
        }
      });
    },

    // 跳转商品评价页
    onEvalua(orderId){
      this.$router.push({
        path: "/knowledgeMall/evaluation?orderId=" + this.$AES.encode_data(orderId + ''),
      });
    },

    // 确认收货
    getEvent(){
      this.$ajax({
        url: "/hxclass-pc/order/confirm-receive",
        method: "put",
        params: {
          orderId: this.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('已确认收货');
          this.getOrderList()
          this.isPublicTipsShow = false;
        }
      });
    },

    // 确认收货弹框
    showReceiving(e) {
      this.confirmCount = 0;
      let arr = this.orderList.filter((item) => {
        return item.orderId == e.orderId;
      });
      arr[0].productList.map((item) => {
        if (item.productType != 1 && item.productType != 4) {
          this.confirmCount += item.productCount;
        }
      });
      this.tipsText = '共' + this.confirmCount + '件商品,请确认无误后再确认收货!'
      this.isPublicTipsShow = true;
    },


    // 取消订单
    onCancelOrder(){
      if (!this.reason) {
        return this.$message.warning('请选择取消原因');
      }
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/order/cancel",
        method: "put",
        params: {
          orderId: this.orderId, // 订单id
          closeReason: this.reason, // 用户取消交易关闭原因：我不想要了，价格有点贵，暂时不需要了，其它
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success('取消成功');
          // 将当前取消的订单 状态改为关闭状态
          this.orderList.forEach(element => {
            if(element.orderId == this.orderId){
              element.statusTxt = "交易关闭";
              element.status = 6;
            }
          });
          this.isCancelOrderShow = false
        }
      });
    },

    // 去支付
    goPay(id) {
      this.$router.push({
        path: '/pay/payMethod',
        query: {
          orderId: this.$AES.encode_data(id+'')
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getOrderList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isCancelOrderShow(){
      if(!this.isCancelOrderShow){
        this.reason = undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-empty-image{
  margin-bottom: 15px;
}
.main-wrap{
  height: 100%;
  position: relative;
}
.nav-inner{
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  .title-h4{
    margin-bottom: 0 !important;
  }
  .search-box{
    .input{
      width: 300px;
      height: 41px;
    }
    .btn-span{
      display: inline-block;
      margin-left: 24px;
      color: #ffffff;
      line-height: 41px;
      text-align: center;
      width: 92px;
      height: 41px;
      background: #15B7DD;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
    }
  }
}
.tabs {
  display: inline-block;
  position: relative;
  left: -40px;
  padding: 0 40px;
  margin-bottom: 20px;
  background-color: rgba(21, 183, 221, 0.1);
  .tabItem {
    display: inline-block;
    padding: 0 38px;
    line-height: 38px;
    font-size: 18px;
    color: #666666;
    margin-right: 20px;
    cursor: pointer;
  }
  .tabItem:last-child {
    margin: 0;
  }
  .active {
    color: #ffffff;
    background-color: #15b7dd;
  }
}

.header-inner{
  display: flex;
  align-items: center;
  line-height: 54px;
  background: #F3F3F3;
  width: 100%;
  margin-bottom: 16px;
  >p{
    flex: 1;
    text-align: center;
  }
  span{
    display: inline-block;
    text-align: center;
    width: 130px;
    font-weight: 500;
    color: #333333;
  }
}

.order-form{
  position: relative;
  min-height: 400px;
  .order-form-item{
    border: 1px solid #EBEBEB;
    margin-bottom: 20px;
    .order-num{
      background: #F3F3F3;
      height: 44px;
      line-height: 44px;
      padding: 0 24px;
      font-size: 14px;
      color: #666666;
      >span{
        margin-right: 60px;
      }
    }
    .form-content{
      display: flex;
      .price{
        font-weight: 500;
        color: #333333;
      }
      .form-left{
        width: 615px;
        border-right: 1px solid #EBEBEB;
        .product-item{
          padding: 24px 0 24px 24px;
          border-bottom: 1px solid #EBEBEB;
          display: flex;
          .product-cover{
            width: 100px;
            height: 87px;
            background-repeat: no-repeat;
            object-fit: cover;
          }
          .product-r{
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 18px;
            .info{
              flex: 1;
              display: flex;
              align-content: space-between;
              flex-wrap: wrap;
              padding: 4px 0;
              font-size: 14px;
              min-height: 87px;
              .name{
                font-weight: 400;
                color: #333333;
                line-height: 17px;
                // 标题仅显示两行，溢出内容省略号代替
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient:vertical;
              }
              .navTime{
                line-height: 16px;
                color: @theme;
                width: 100%;
              }
            }
            
            .card-width{
              display: inline-block;
              width: 130px;
              text-align: center;
            }
            .status{
              .bg{
                display: inline-block;
                width: 56px;
                color: #B2B2B2;
                line-height: 20px;
                font-size: 12px;
                border-radius: 15px;
                border: 1px solid #D9D9D9;
              }
              
            }
            .num{
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .product-item:last-child{
          border-bottom: none;
        }
      }
      .form-right{
        flex: 1;
        display: flex;
        .th{
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          flex-wrap: wrap;
          width: 130px;
          border-right: 1px solid #EBEBEB;
        }
        .th:last-child{
          border-right: none;
        }
        .payType{
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          p{
            display: block;
            width: 100%;
            line-height: 20px;
          }
        }
        .operation{
          p{
            font-size: 14px;
            line-height: 21px;
            color: @theme;
            margin-bottom: 8px;
            cursor: pointer;
          }
          .on-btn{
            display: inline-block;
            width: 82px;
            height: 29px;
            line-height: 29px;
            color: #ffffff;
            background: @theme;
            border-radius: 15px;
          }
        }
      }
    }
  }
  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 250px;
    margin-top: -10px;
    margin-left: -10px;
  }
}

// 分页
.pagination{
  position: absolute;
  bottom: 0;
  right: 0;
}

.modal-content {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin: 33px 20px 30px 20px;
    .ant-select{
      margin-top: 16px;
      height: 41px;
      width: 100%;
    }
    /deep/ .ant-select-selection--single{
      height: 41px;
    }
    /deep/ .ant-select-selection__rendered{
      line-height: 41px;
    }
    .tips-info{
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      img{
        position: relative;
        top: -2px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .btn-foot {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    margin-top: 40px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      margin-right: 54px;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      width: 113px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      &::after{
        background: none;
      }
    }
  }
}

.modal-customer{
  .title{
    font-size: 16px;
    img{
      position: relative;
      top: -2px;
      width: 16px;
      margin-right: 4px;
    }
  }
  .QR-code{
    width: 150px;
    margin: 25px auto 20px;
    background: #F7F7F9;
    padding: 10px;
    border-radius: 10px;
    img{
      width: 100%;
    }
  }
  .tips{
    display: block;
    text-align: center;
  }
  .btn{
    margin: 20px auto 0;
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}

/deep/ .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none !important;
}


// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .main-layout-wrap{
    .side-view-right{
      .main-wrap{
        width: 805px;
      }
      .title-h4{
        font-size: 22px;
      }
    }
  }
  .header-inner span{
    width: 109px;
  }
  .order-form .order-form-item .form-content {
    .form-left .product-item .product-r .card-width{
      width: 109px;
    }
    .form-right .th{
      width: 109px;
    }
  }
}
</style>
