<template>
  <div id="Foo" v-if="!loginPage">
     <div class="footer-box">
       <div class="top">
         <div class="first">
           <div class="title">公司概况</div>
           <div class="text" @click="$router.push('/about')">关于我们</div>
         </div>
         <!-- <div class="line"></div> -->
         <div class="first">
           <div class="title">法律条款</div>
           <div class="text" @click="$router.push('/privacyPolicy?sel=1')">服务协议</div>
           <div class="text" @click="$router.push('/privacyPolicy?sel=2')">隐私协议</div>
         </div>
         <!-- <div class="line"></div> -->
         <div class="first">
           <div class="title">友情链接</div>
           <div class="text"><a class="text" href="https://www.cdpf.org.cn/" target="_blank" rel="noopener noreferrer">中国残疾人联合会</a></div>
           <div class="text"><a class="text" href="https://www.hxph.com.cn/" target="_blank" rel="noopener noreferrer">华夏出版社</a></div>
           <!-- <div class="text"><a class="text" href="https://cdpservice.com/" target="_blank" rel="noopener noreferrer">爱心阳光中国残疾人综合服务云平台</a></div> -->
           <!-- <div class="text"><a class="text" href="https://hxyuandao.com/" target="_blank" rel="noopener noreferrer">北京华夏元道文化科技有限公司</a></div> -->
           <div class="text"><a class="text" href="https://www.cncard.org.cn/" target="_blank" rel="noopener noreferrer">中国残疾人康复协会</a></div>
         </div>
         <!-- <div class="line"></div> -->
         <div class="second">
           <div class="title">联系方式</div>
           <div class="contact">
             <img class="contact-phone" src="@/assets/image/nav/icon_phone.png" alt="" />
             <div class="text">400-800-9002</div>
           </div>
           <div class="contact">
             <img class="contact-mail" src="@/assets/image/nav/icon_mail.png" alt="" />
             <div class="text">class@hxph.com.cn</div>
           </div>
           <!-- <div class="contact">
             <img class="contact-wechat" src="@/assets/image/nav/icon_wechat.png" alt="" />
             <div class="text">添加客服，咨询更多</div>
           </div> -->
         </div>
         <!-- <div class="line"></div> -->
         <div class="third">
           <!-- <div class="title">了解更多</div> -->
           <div class="third-content">
             <div class="content">
              <img class="content-img" src="@/assets/image/nav/20220619-211727.png" alt="" />
              <div class="text">华夏云课堂APP</div>
             </div>
             <div class="content">
              <img class="content-img" src="@/assets/image/nav/banner_wechat.png" alt="" />
              <div class="text">微信公众号</div>
             </div>
             <div class="content">
              <img class="content-img" src="@/assets/image/nav/banner_person.png" alt="" />
              <div class="text">个人报名咨询</div>
             </div>
             <div class="content">
              <img class="content-img" src="@/assets/image/nav/banner_consult.png" alt="" />
              <div class="text">团体/机构报名咨询</div>
             </div>
           </div>
         </div>
       </div>

       <div class="bot text">
         <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备05032918号-9</a> | <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004279">京公网安备11010102004279号</a>
       </div>
     </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loginPage: false, //是否为登录页面
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(){
      // 登录页面/找回密码/绑定手机号 隐藏底部
      if(this.$route.path.indexOf('/login') > -1 || this.$route.path.indexOf('/customerOrder') > -1) {
        this.loginPage = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
#Foo{
  background-color: #464443;
  height: 260px;
  z-index: 99;
}
.footer-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 50px 80px 20px;
  width: 1400px;
  height: 100%;

  .title{
    margin-bottom: 16px;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;
    // letter-spacing: 1px;
  }

  .text{
    margin-bottom: 8px;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 15px;
    // letter-spacing: 1px;
    cursor: pointer;
  }

  .line{
    position: relative;
    top: 20px;
    width: 1px;
    height: 25px;
    background: rgba(255, 255, 255, 0.55);
  }

  .top{
    display: flex;
    justify-content: space-between;
    width: 100%;

    .first{}

    .second{
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      // justify-content: space-between;
      height: 120px;
      .contact{
        display: flex;
        // justify-content: center;
        margin-bottom: 8px;
        align-items: center;
        .contact-phone{
          margin-right: 12px;
          // width: 13px;
          // height: 18px;
          width: 10px;
          height: 14px;
        }
        .contact-mail{
          margin-right: 9px;
          // width: 17px;
          // height: 16px;
          width: 14px;
          height: 12px;
        }
        .contact-wechat{
          margin-right: 12px;
          width: 18px;
          height: 14px;
        }
        .text {
          margin-bottom: 0;
        }
      }
    }
    
    .third{
      .third-content{
        display: flex;
        .content{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 24px;
          .content-img{
            margin-bottom: 10px;
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

  .bot{
    margin-top: 36px;
    a{
      color: #fff;
    }
  }
}
</style>
