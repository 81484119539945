<template>
  <div class="main-box" >
    <!-- 面包屑 -->
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/personalCenter/MyOrder"
          >我的订单</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">订单编号：{{orderDetail.code}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="order-detail">
      <a-spin class="sping" v-show="loading" />

      <h4 class="detail-name">订单详情<span>订单编号：{{orderDetail.code}}</span></h4>
      <!-- 付款功能区 -->
      <div class="border-box payment-box">
        <div class="pay-inner-l">
          <span class="h1-text">{{FunorderStatus(orderDetail.status)}}</span>
          <div class="btn-inner">
            <span class="btn all-btn-small btn-bg" v-if="orderDetail.status == 3 || orderDetail.status == 4" @click="showReceiving(orderDetail.productList)">确认收货</span>
            <template v-if="orderDetail.orderCreateType == 1">
              <span class="btn all-btn-small btn-bg" v-if="orderDetail.status == 1 && orderDetail.payMethod != 3" @click="goPay(orderDetail.orderId)">去支付</span>
              <!-- <span class="btn all-btn-small btn-bg" @click="onEvalua" v-if="orderDetail.showBtnComment && orderDetail.status == 5 && getpjStatus(orderDetail.productList)">评价</span> -->
              <span class="btn " v-if="orderDetail.canInvoice && orderDetail.invoiceBtnStatus == 1" @click="onInvioce()">申请发票</span>
              <span class="btn" v-if="orderDetail.address && orderDetail.status == 1" @click="getAddressList()">修改地址</span>
              <span class="btn" v-if="orderDetail.status == 1" @click="isCancelOrderShow = true">取消订单</span>
            </template>
          </div>
          <span class="tips-text" v-if="orderDetail.status == 2">商品正在快速打包</span>
          <span class="tips-text" v-if="orderDetail.status == 6">取消原因：<b>{{orderDetail.closeReason}}</b></span>
        </div>
        <!-- 订单状态 -->
        <div class="speed-inner-r" style="padding: 20px 0;">
          <div class="progress-time">
            <p class="downtime" v-if="orderDetail.status == 1 && orderDetail.payMethod !=3">剩余 <i>{{day}}</i> 天 <i>{{hours}}</i> 小时 <i>{{minutes}}</i> 分自动关闭</p>
            <div class="progress">
              <div class="progress-item" v-for="(item,index) in orderSpeed" :key="index + 'spped'">
                <p class="speed-name">{{item.orderName}}</p>
                <span class="speed-num" :class="{'speed-num-active' : index <= orderIndex}">{{index+1}}</span>
                <p class="speed-time">{{item.orderTime}}</p>
              </div>
              <a-progress class="speed-bar" :percent="orderPercent"  stroke-linecap="square" strokeColor="#15B7DD" :showInfo='false' />
            </div>
          </div>
        </div>
      </div>
      <!-- tab切换 -->
      <div class="tabs" v-if="isLogisticsTabShow">
        <p
          class="tabItem"
          @click="tabChange(item)"
          :class="{ active: item.id == tabCode }"
          v-for="(item) in parceList"
          :key="item.id + 'id'"
        >
          {{ item.name }}
        </p>
      </div>
      <!-- 物流状态 -->
      <div class="border-box payment-box logistics-box" v-if="(orderDetail.status == 3 || orderDetail.status == 4 || (orderDetail.status == 5 && getStatus(orderDetail.productList))) && isLogisticsShow">
        <div class="pay-inner-l">
          <div class="swiper-box">
            <swiper ref="recommendSwiper" :options="productOption">
              <swiper-slide
                v-for="(item,index) in packageList"
                :key="index + 'i'"
              >
                <img class="cover" :src="item.productPicture" alt="">
              </swiper-slide>
            </swiper>

            <!-- 滑动切换按钮 -->
            <div class="swiper-button-next" v-show="packageList.length > 2">
              <div class="bg btn-right">></div>
            </div>
          </div>
          <h3>共<i>{{productCount}}</i>件商品</h3>
          <p><span>快递公司：</span>{{logisticsData.logisticsName}}</p>
          <p><span>运单号：</span>{{logisticsData.logisticsNumber}}</p>
        </div>
        <!-- 订单状态 -->
        <div class="speed-inner-r">
          <Logistics :logisticsData="logisticsData"></Logistics>
        </div>
      </div>


       <!-- 暂未发货 -->
      <div class="border-box payment-box logistics-box" style="display:block" v-if="(orderDetail.status == 3 || orderDetail.status == 4 || (orderDetail.status == 5 && getStatus(orderDetail.productList))) && !isLogisticsShow">
        <h3 class="total">共<i>{{packageList.length}}</i>件商品</h3>
        <div class="no-set-inner">
          <div class="no-set-item" v-for="(item,index) in packageList" :key="index + 'j'">
            <img :src="item.productPicture" alt="">
            <!-- <span>孤独症教具</span> -->
          </div>
        </div>
      </div>


      <!-- 订单收货信息 -->
      <div class="border-box">
        <div class="card" v-if="orderDetail.address">
          <h4>收货人信息</h4>
          <div>
            <div class="p"><span>收件人：</span><p>{{orderDetail.address.name}}</p></div>
            <div class="p"><span>地址：</span><p>{{orderDetail.address.provinceName}}{{orderDetail.address.cityName}}{{orderDetail.address.districtName}}{{orderDetail.address.address}}</p></div>
            <div class="p" v-if="orderDetail.address.phone"><span>手机号码：</span><p>{{orderDetail.address.phone.substr(0,3)}}****{{orderDetail.address.phone.substr(7,11)}}</p></div>
          </div>        
        </div>
        <div class="card" v-if="orderDetail.status != 6">
          <h4>付款信息</h4>
          <div>
            <div class="p"><span>付款方式：</span><p>{{ orderDetail.status != 1 ? orderDetail.payMethodTxt : '-'}}</p></div>
            <div class="p"><span>支付交易号：</span><p>{{ orderDetail.payMethodTxt != '对公转账' && orderDetail.status != 6 && orderDetail.status != 1 ? orderDetail.tradeId : '-'}}</p></div>
          </div>       
        </div>
      </div>

      <!-- 商品信息 -->
      <div class="product-box">
        <div class="public-flex header">
          <span class="head-l">商品信息</span>
          <div class="head-r">
            <span>单价</span>
            <span>数量</span>
            <span>优惠</span>
            <span>小计</span>
            <span>操作</span>
          </div>
        </div>
        <div v-for="item in orderDetail.productList" :key="item.id + 'productid'" class="product-item">
          <div class="public-flex" >
            <div class="head-l">
              <img class="product-cover" v-show="item.productPicture" :src="item.productPicture" alt="">
              <div class="product-r">
                <p class="name">{{item.productName}}</p>
                <div class="label">
                  <span class="navTime" v-if="item.productType == 1"
                    >{{ item.openTime ? item.openTime : (item.validDayType == 1 ? '有效期：' + item.validDays + '天' : item.validDayType == 2 ? '长期有效' :'') }}
                    <p class="offlineAddress" v-if="item.offlineAddress">
                      {{ item.offlineAddress }}
                    </p>
                  </span>
                  <span class="navTime" v-if="item.productType == 4">{{
                    item.examNumberNotes
                  }}</span>
                  <span class="navTime" v-if="item.productType == 5">{{
                    item.otherNotes
                  }}</span>
                  <span
                    class="navTime"
                    v-if="item.productType == 6"
                    >{{ item.courseDeferralDaysNotes }}
                    <br
                      v-if="item.examNumberNotes && item.courseDeferralDaysNotes"
                    />
                    {{ item.examNumberNotes }}</span
                  >
                  <span
                    class="navTime"
                    v-if="item.productType == 7"
                    >{{ item.examNumberNotes }}
                    <br
                      v-if="item.examNumberNotes && item.courseDeferralDaysNotes"
                    />
                    {{ item.courseDeferralDaysNotes }}</span>
                </div>
              </div>
            </div>
            <div class="head-r">
              <span>{{(item.type == 2 || item.payPrice) && item.productPrice ? "¥" + item.productPrice.toFixed(2) : "¥0.00"}}</span>
              <span>x{{ item.productCount }}</span>
              <span>{{(item.productPrice * 100 * item.productCount - item.payPrice*100)/100 ? "¥" + ((item.productPrice * 100 * item.productCount - item.payPrice*100)/100).toFixed(2) : '-'}}</span>
              <span>¥{{ item.payPrice ? item.payPrice.toFixed(2) : '0.00'}}</span>
              <div class="optionBox">
                <span
                  v-if="item.refundStatus"
                  class="refundStatu"
                  >
                  {{item.refundStatus == 2?'部分退款':'全部退款' }}
                </span>
                <span
                  @click="getRefundsHis(item.id)"
                  :class="{
                    spanBtn: item.refundStatus
                }">
                  {{item.refundStatus?'查看退款记录':'-' }}
                </span>
              </div>
              
              <!-- <span>¥{{FunTotalPrice(item)}}</span> -->
            </div>
          </div>
          <!-- 包含商品 -->
          <div class="contain" v-if="false">
            <p class="title">包含商品</p>
            <div class="public-flex" v-for="i in 2" :key="i">
              <div class="head-l">
                <img class="product-cover" src="https://img0.baidu.com/it/u=2199285909,4027684884&fm=253&fmt=auto&app=120&f=JPEG?w=1080&h=721" alt="">
                <div class="product-r">
                  <p class="name">孤独症儿童教育孤独症儿童教育孤独症儿童教育孤独症儿童教育</p>
                  <div class="label">上岗课程丨12课时</div>
                </div>
              </div>
              <div class="head-r">
                <span>¥1000.00</span>
                <span>x1</span>
                <span>-</span>
                <span >-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="total-price">
          <div>
            <p><span>商品总价：</span><span>¥{{ orderDetail.orderPrice ? orderDetail.orderPrice.toFixed(2) : "0.00" }}</span></p>
            <p><span>优惠金额：</span><span>¥{{ couponP?couponP.toFixed(2) : "0.00" }}</span></p>
            <p v-if="orderDetail.address"><span>运费：</span><span>¥{{ orderDetail.freightPrice ? orderDetail.freightPrice.toFixed(2) : "0.00" }}</span></p>
            <p><span>实付款：</span><span class="actualPrice">¥{{ orderDetail.payPrice ? orderDetail.payPrice.toFixed(2) : "0.00" }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改 收货地址 -->
    <a-modal
      v-model="isAddressVisible"
      width="600px"
      :closable="false"
    >
      <div class="modal-content">
        <p class="title">修改收货地址</p>
        <div class="address">
          <a-radio-group v-model="addressOption" >
            <a-radio @click="onSelectAddress(item)" v-for="item in addressList" :key="item.id + 'address'" :style="radioStyle" :value="item.id"><p class="text" :title="FunAddress(item)">{{FunAddress(item)}}</p></a-radio>
          </a-radio-group>
          <span class="new">
            <router-link to="/personalCenter/addressmanage">使用新地址</router-link>
          </span>
        </div>
        <div class="btn-foot">
          <p class="cancel" @click="isAddressVisible = false">取消</p>
          <p class="all-btn-small" @click="onModifyAddress">确认</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>

    <!-- 取消订单 弹窗 -->
    <a-modal
      v-model="isCancelOrderShow"
      width="480px"
      :closable="false"
    >
      <div class="modal-contentcancel">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <div class="content">
          <p>请选择取消订单的原因</p>
          <a-select class="select" v-model="reason" placeholder="请选择原因"  allowClear>
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="我不想要了">我不想要了</a-select-option>
            <a-select-option value="价格有点贵">价格有点贵</a-select-option>
            <a-select-option value="暂时不需要了">暂时不需要了</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
          <p class="tips-info">
            <img
              src="@/assets/image/personalCenter/2022072501.png"
              alt=""
            />
            取消后将无法恢复，优惠券可退回，有效期内可使用</p>
        </div>
        <div class="btn-foot">
          <p class="cancel" @click="isCancelOrderShow = false">取消</p>
          <p class="all-btn-small" @click="onCancelOrder">确认</p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
    <TipsModal
      :isPublicTipsShow="isPublicTipsShow" 
      :tipsText="tipsText"
      @setEvent="getEvent" />

    <!-- 退款记录 -->
    <a-modal
      v-model="isrefundHistoryShow"
      width="350px"
      :footer="false"
    >
      <p class="refundTitle">退款记录</p>

      <div class="refundProductBox" :class="{more: refundHistory.length>1}" v-for="(item, index) in refundHistory" :key="item.refundId">
        <div class="box_left" :class="{ img_before: index != 0, img_after: index != refundHistory.length-1}" v-if="refundHistory.length>1">
          <img src="@/assets/image/order/list_li.png" alt="">
        </div>
        <div class="box_right">
          <div class="option">
            <span>退款金额：</span>
            <span>￥{{ item.refundPrice && item.refundPrice.toFixed(2) }}</span>
          </div>
          <div class="option">
            <span>商品数量：</span>
            <span>{{ item.refundCount }}</span>
          </div>
          <div class="option">
            <span>退款类型：</span>
            <!-- 是否仅退款,1 为仅退款。0 为退款退商品，其中机构端这里表示剔除名额 -->
            <span>
              {{ 
                item.refundsOnly == 1?
                '仅退款':
                item.refundsOnly === 0 || item.refundsOnly === '0'?
                '退款退商品':
                '-' 
              }}
            </span>
          </div>
          <div class="option">
            <span>退款进度：</span>
            <!-- 退款状态1.退款中 2.已处理 -->
            <span :class="{ done: item.refundStatus == 2 }">{{ item.refundStatus == 1?'退款中':item.refundStatus == 2?'已退款':'-' }}</span>
          </div>
          <div class="option">
            <span>退款时间：</span>
            <span>{{ item.refundTime || '-' }}</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Logistics from '@/components/xinyu-logistics/xinyu-logistics.vue'
export default {
  // 可用组件的哈希表
  components: {Logistics},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      orderSpeed: [], // 订单进度
      orderPercent: 0, // 订单进度条
      orderIndex: 0, // 订单进度索引
      orderId: '', //订单id
      addressOption: 0,
      addressID: 0,
      isAddressVisible: false,  // 修改地址弹窗
      radioStyle:{  // 地址弹窗样式
        display: 'flex',
        height: '30px',
        lineHeight: '30px',
      },
      reason: undefined,
      isCancelOrderShow: false,
      productOption: {  // 商品轮播配置
        slidesPerView: 2,
        slidesPerGroup: 2,
        // spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
      },

      isLogisticsTabShow: false,   // 是否显示物流多包裹
      isLogisticsShow: true,
      packageList: [],  //快递包裹
      logisticsData: {
        logisticsInfo:{
          data:[]
        }
      }, // 物流状态
      productCount:0,

      logisticsCode: '',
      logisticsNumber: '',

      day:'0',
      hours:'0',
      minutes:'0',
      newsecond:'',

      parceList: [], 
      tabCode: 0,
      orderDetail: {},

      time: null,  // 支付倒计时

      addressList: [], // 地址
      addressSelectObj: {}, // 修改后的地址


      loading: false, // 加载

      tipsText: '',
      confirmCount: '',
      isPublicTipsShow: false, // 确认收货弹窗

      isLoading:false,

      refundHistory: [], // 退款记录
      isrefundHistoryShow: false,
    }
  },
  // 事件处理器
  methods: {
    // tab切换
    tabChange(e) {
      this.FunPack(e)
    },
    // 跳转商品评价页
    onEvalua(){
      this.$router.push({
        path: "/knowledgeMall/evaluation?orderId=" + this.$AES.encode_data(this.orderId + ''),
      });
    },

    // 申请发票
    onInvioce(){
      return this.$message.warning('请联系工作人员线下开具发票，联系电话：400-800-9002转3。')
      this.$router.push({
        path: "/invoice?orderId=" + this.$AES.encode_data(this.orderId + ''),
      });
    },

    // 评价按钮是否展示  商品id 1 2 3 4
    getpjStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (
          e[i].productType == 1 ||
          e[i].productType == 2 ||
          e[i].productType == 3 ||
          e[i].productType == 4
        ) {
          return true;
        }
      }
    },

    // 返回订单状态
    FunorderStatus(type){
      let orderArry = ['等待付款', '等待发货', '等待收货', '部分发货', '交易成功', '交易关闭', ]
      return orderArry[type-1]
    },

    // 订单是否包含实物
    getStatus(e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i].productType == 2 || e[i].productType == 3) {
          return true;
        }
      }
    },

    // 时间格式化
    FunFormatDate(time) {
      time = String(time).replace(/-/g,'/')
      var date = new Date(time);
      var YY = date.getFullYear() + '-';
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return YY + MM + DD + " " + hh + mm + ss;
    },    

    // 计算价格
    FunTotalPrice(item){
      let count = 1
      if(item.productType == 2 || item.productType == 3){
        count = item.productCount
      }
      return (item.type == 2 || item.payPrice) && item.productPrice ? (item.productPrice * count).toFixed(2) : '0.00'
    },

    onSelectAddress(e){
      this.addressSelectObj = e
    },

    // 确认修改地址
    onModifyAddress(){
      if(this.addressID == this.addressOption){  // 当地址为空时
        this.$message.warning('当前收货地址无需更换！');
      } else {
        if(this.loading) return
        this.loading = true
        this.$ajax({
          url:'/hxclass-pc/order/address',
          method:'put',
          params:{
            orderId:this.orderId,
            receiveId:this.addressSelectObj.id, // 收货地址id
            receiveProvince:this.addressSelectObj.provice, // 收货人省编码
            receiveCity:this.addressSelectObj.city, // 收货人市编码
            receiveDistrict:this.addressSelectObj.area, // 收货人区编码
            receiveAddress:this.addressSelectObj.address, // 收货人详细地址
            receiveName:this.addressSelectObj.consignee, // 收货人姓名
            receivePhone:this.addressSelectObj.phone, // 收货人手机号
          }
        }).then(res=>{
          this.loading = false
          if(res.code == 200 && res.success){
            this.isAddressVisible = false
            this.$message.success('修改成功');
            this.getOrderDetail();
          }else{
            this.$message.error(res.message);
          }
        })
      }
    },

    // 查询地址列表
    getAddressList(){
      // 有地址列表,即不再查询
      if(this.addressList.length) {

        let orderAddress = this.orderDetail.address
        let address1 = orderAddress.name + orderAddress.phone + orderAddress.provinceName + orderAddress.cityName + orderAddress.districtName + orderAddress.address
        this.addressList.forEach(element => {
          let address2 = element.consignee + element.phone + element.proviceName + element.cityName + element.areaName + element.address
          // 检索当前订单已选的地址
          if(address1 == address2){
            this.addressOption = element.id
            this.addressID = element.id
          }
        });

        this.addressSelectObj = {}  // 地址暂存初始
        this.isAddressVisible = true // 地址窗弹起
        return
      }

      this.loading = true
      this.$ajax({
        url:'/hxclass-pc/ship/address/list',
        method:'get',
        params:{
          pageNum: 1,
          pageSize: 20
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.isAddressVisible = true
          this.addressList = res.data.records


          let orderAddress = this.orderDetail.address
          let address1 = orderAddress.name + orderAddress.phone + orderAddress.provinceName + orderAddress.cityName + orderAddress.districtName + orderAddress.address
          this.addressList.forEach(element => {
            let address2 = element.consignee + element.phone + element.proviceName + element.cityName + element.areaName + element.address
            // 检索当前订单已选的地址
            if(address1 == address2){
              this.addressOption = element.id
              this.addressID = element.id
            }
          });
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 收货人信息 地址拼接
    FunAddress(e){
      return e.consignee + '，' + e.phone + '，' + e.proviceName + e.cityName + e.areaName + e.address
    },
    // 查询订单详情
    getOrderDetail(){
      this.loading = true
      this.$ajax({
        url:'/hxclass-pc/order/' + this.orderId,
        method:'get'
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.orderDetail = res.data;

          // *******物流相关******* //
          let params = res.data.status == 3 || res.data.status == 4 || (res.data.status == 5 && this.getStatus(res.data.productList))   // 物流显示必要条件
          if( params && res.data.parcelNumber == 1){  // 单个包裹，且已发货
            this.isLogisticsTabShow = false
            this.packageList = []
            res.data.productList.map((item) => { // 找出实体商品
              if (item.productType != 1 && item.productType != 4) {
                this.packageList.push(item)
              }
            });
            this.getParceDetail()
          } else if(params && res.data.parcelNumber > 1) {  // 多个包裹
            this.getParceList()
          }


          // *******订单关闭倒计时--仅待支付******* //
          if(this.orderDetail.status == 1 && this.orderDetail.payMethod != 3){
            clearTimeout(this.timeout)
            this.orderCloseDown()
          }
          

          // *******生成订单状态******* //
          this.FunOrderType(res.data.status)
        }else{ // 订单异常
          this.$message.error(res.message);
        }
      })
    },

    // 查询单个物流包裹
    getParceDetail(){
      this.$ajax({
        url:'/hxclass-pc/order/parcel/detail',
        method:'get',
        params:{
          orderId: this.orderId, // 订单id
          logisticsCode: this.logisticsCode, // 物流公司编码
          logisticsNumber: this.logisticsNumber, // 物流单号
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.logisticsData = JSON.parse(JSON.stringify(res.data));
          this.logisticsData.address =  JSON.parse(JSON.stringify(res.data.address.provinceName + res.data.address.cityName + res.data.address.districtName + res.data.address.address))
          this.logisticsData.tellPhone = JSON.parse(JSON.stringify(res.data.address.phone))
          this.logisticsData.status = this.orderDetail.status
          let count = 0;
          res.data.productList.map(item=>{
            count += item.productCount;
          })
          this.productCount = count;
          
          this.FunOrderType(this.orderDetail.status)   // 更新发货时间

          if(this.logisticsData.logisticsInfo.status != 200){  // 物流状态异常时
            this.logisticsData.logisticsInfo.data = []
            this.logisticsData.logisticsInfo.data.push(JSON.parse(this.logisticsData.kuaidiInfo))
          } else {

          }
        }
      })
    },


    // 查询多包裹列表
    getParceList(){
      this.$ajax({
        url:'/hxclass-pc/order/parcel',
        method:'get',
        params:{
          orderId: this.orderId
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.isLogisticsTabShow = true
          let arry = res.data;
          let count = 1
          arry.forEach(element => {
            if(element.logisticsNumber){
              element.name = '包裹' + count
              count++
            } else {
              element.name = '暂未发货'
            }
          });

          this.parceList = arry;

          this.FunPack(arry[0])
        }else{
          
        }
      })
    },

    // 处理包裹基本信息
    FunPack(arry){
      this.tabCode = arry.id
      this.logisticsData.logisticsName = arry.logisticsName
      this.logisticsData.logisticsNumber = arry.logisticsNumber

      this.productCount = arry.productCount

      this.logisticsCode = arry.logisticsCode
      this.logisticsNumber = arry.logisticsNumber

      this.packageList = []
      arry.productPicture.split(',').forEach(img => {
        this.packageList.push({
          productPicture: img
        })
      });

      if(arry.logisticsNumber){
        this.isLogisticsShow = true
      } else {
        this.isLogisticsShow = false
      }

      // this.getParceDetail()
    },

    // 处理订单状态
    FunOrderType(status){
      if(status == 1){  // 订单待支付
        if(this.orderDetail.address){  // 有实体商品
          this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: ''}, {orderName:'商家发货', orderTime: ''}, {orderName:'完成收货', orderTime: ''} ]
          this.orderPercent = 18
        } else { // 纯虚拟商品
          this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: ''}, {orderName:'完成交易', orderTime: ''} ]
          this.orderPercent = 25
       }
        this.orderIndex = 0
      } else if(status == 2){  // 订单待发货
        this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: this.FunFormatDate(new Date(this.orderDetail.paymentTime))}, {orderName:'商家发货', orderTime: ''}, {orderName:'完成收货', orderTime: ''} ]
        this.orderIndex = 1
        this.orderPercent = 52
      } else if(status == 3 || status == 4){  // 订单待收货 和 部分发货
        this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: this.FunFormatDate(new Date(this.orderDetail.paymentTime))}, {orderName:'商家发货', orderTime: this.logisticsData.sendTime}, {orderName:'完成收货', orderTime: ''} ]
        this.orderIndex = 2
        this.orderPercent = 85
      } else if(status == 5){  // 交易完成
        if(this.orderDetail.address){  // 有实体商品
          this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: this.FunFormatDate(new Date(this.orderDetail.paymentTime))}, {orderName:'商家发货', orderTime: this.logisticsData.sendTime}, {orderName:'完成收货', orderTime: this.FunFormatDate(new Date(this.orderDetail.completeTime))} ]
          this.orderIndex = 3
        } else { // 纯虚拟商品
          this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'付款成功', orderTime: this.FunFormatDate(new Date(this.orderDetail.paymentTime))}, {orderName:'完成交易', orderTime: this.FunFormatDate(new Date(this.orderDetail.completeTime))} ]
          this.orderIndex = 2
        }
        this.orderPercent = 100
      } else if(status == 6){  // 订单关闭
        this.orderSpeed=[ {orderName:'提交订单', orderTime: this.FunFormatDate(this.orderDetail.createTime)}, {orderName:'交易关闭', orderTime: this.FunFormatDate(new Date(this.orderDetail.modifyTime))} ]
        this.orderPercent = 100
        this.orderIndex = 1
      }
    },

    // 订单关闭倒计时
    orderCloseDown(){
      let str = this.FunFormatDate(this.orderDetail.createTime).split('-').join('/');
      var ms = Math.abs(new Date() - new Date(str).getTime());
      // 订单剩余关闭的时间
      this.time = 60*60*24*7 - (ms / 1000); // 毫秒转为秒

      if(this.time >= 0){
        this.timeout = setInterval(() => {
          var oldsecond = this.time
          this.day = Math.floor(oldsecond / 86400);
          this.hours = Math.floor((oldsecond % 86400) / 3600);
          this.minutes = Math.floor(((oldsecond % 86400) % 3600) / 60);
          this.newsecond = Math.floor(((oldsecond % 86400) % 3600) % 60);
          this.time --
          if(this.time <= 0) { // 剩余时间为0时 弹出时间到的弹窗，并清除定时器
            clearTimeout(this.timeout)
            this.getOrderDetail();
          }
        }, 1000);
      }
    },
    // 取消订单
    onCancelOrder(){
      if (!this.reason) {
        return this.$message.warning('请选择取消原因');
      }

      if(this.isLoading){return}
      this.isLoading = true;

      this.$ajax({
        url: "/hxclass-pc/order/cancel",
        method: "put",
        params: {
          orderId: this.orderId, // 订单id
          closeReason: this.reason, // 用户取消交易关闭原因：我不想要了，价格有点贵，暂时不需要了，其它
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          clearTimeout(this.timeout)
          this.$message.success('取消成功');
          this.isCancelOrderShow = false
          this.getOrderDetail();
        }
        this.isLoading = false;
      });
    },

    // 确认收货
    getEvent(){
      if(this.isLoading){return}
      this.isLoading = true;

      this.$ajax({
        url: "/hxclass-pc/order/confirm-receive",
        method: "put",
        params: {
          orderId: this.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('已确认收货');
          this.getOrderDetail();
          this.isPublicTipsShow = false;
        }
        this.isLoading = false;
      });
    },

    // 确认收货弹框
    showReceiving(arry) {
      this.confirmCount = 0;
      
      arry.map((item) => {
        if (item.productType != 1 && item.productType != 4) {
          this.confirmCount += item.productCount;
        }
      });
      this.tipsText = '共' + this.confirmCount + '件商品,请确认无误后再确认收货!'
      this.isPublicTipsShow = true;
    },

    // 去支付
    goPay(id) {
      this.$router.push({
        path: '/pay/payMethod',
        query: {
          orderId: this.$AES.encode_data(id+'')
        }
      });
    },

    // 获取退款记录
    getRefundsHis(e) {
      this.$ajax({
        url:'/hxclass-pc/order/refund/app/get',
        params: {
          orderProductId: e
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.refundHistory = res.data;
          this.isrefundHistoryShow = true;
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    this.getOrderDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  beforeDestroy(){
    clearTimeout(this.timeout)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    couponP() {
      return (this.orderDetail.orderPrice*100 + this.orderDetail.freightPrice*100 - this.orderDetail.payPrice*100)/100
    }
  },
  // 自定义的侦听器
  watch: { }
}
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
}
.order-detail{
  margin-top: 22px;
  background-color: #ffffff;
  border-radius: 8px;
  // padding: 40px;
  padding: 30px 40px 40px 40px;
  width: 100%;
  position: relative;
  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 400px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .detail-name{
    font-size: 24px;
    font-weight: 500;
    color: #15B7DD;
    line-height: 36px;
    &::before{
      content: '';
      display: inline-block;
      position: relative;
      top: 2px;
      width: 4px;
      height: 19px;
      background: #15B7DD;
      margin-right: 8px;
    }
    span{
      margin-left: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
  }

  .border-box{
    margin-top: 21px;
    border: 1px solid #EBEBEB;
    border-top: 3px solid @theme;
  }
  .payment-box{
    display: flex;
    .pay-inner-l{
      width: 330px;
      text-align: center;
      padding: 35px 0;
      border-right: 1px solid #EBEBEB;
      .h1-text{
        font-size: 28px;
        font-weight: 400;
        color: #666666;
      }
      .btn-inner{
        width: 138px;
        margin: 25px auto 0;
      }
      .btn{
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: @theme;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid @theme;
        cursor: pointer;
      }
      .btn-bg{
        background: @theme;
        color: #fff;
        box-shadow: 0px 4px 4px 0px rgba(49,188,221,0.2500);
      }
      .all-btn-small::after{
        background: none;
      }
      .tips-text{
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        b{
          color: #333333;
          font-weight: 400;
        }
      }
    }
    .speed-inner-r{
      flex: 1;
      display: flex;
      align-items: center;
      .progress-time{
        margin-left: 80px;
        width: 80%;
        .downtime{
          line-height: 24px;
          color: #333333;
          margin-bottom: 33px;
          i{
            font-style: normal;
            font-size: 24px;
            color: #FF0000;
            font-weight: 400;
          }
        }
        .progress{
          display: flex;
          justify-content: space-between;
          position: relative;
          .progress-item{
            position: relative;
            text-align: center;
            z-index: 10;
            width: 90px;
            .speed-name{
              font-size: 18px;
              font-family: PingFang HK-Regular, PingFang HK;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              margin-bottom: 12px;
            }
            .speed-num{
              display: inline-block;
              background: #fff;
              border-radius: 50%;
              text-align: center;
              line-height: 25px;
              color: #C4C4C4;
              font-weight: 500;
              font-size: 14px;
              width: 33px;
              height: 33px;
              border: 4px solid #C4C4C4;
            }
            .speed-num-active{
              border: 4px solid @theme;
              color: @theme;
            }
            .speed-time{
              margin-top: 8px;
              font-size: 14px;
              color: #666666;
              line-height: 21px;
            }
          }
          .speed-bar{
            position: absolute;
            left: 30px;
            width: 90%;
            top: 45px;
          }
          /deep/.ant-progress-inner{
            background-color: #C4C4C4;
          }
          /deep/ .ant-progress-bg{
            height: 5px !important;
          }
        }
      }
    }
  }


  .tabs {
    display: inline-block;
    padding: 0 40px;
    margin-top: 40px;
    background-color: rgba(21, 183, 221, 0.1);
    .tabItem {
      display: inline-block;
      padding: 0 18px;
      line-height: 38px;
      font-size: 18px;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
    .tabItem:last-child {
      margin: 0;
    }
    .active {
      color: #ffffff;
      background-color: #15b7dd;
    }
  }

  // 物流
  .logistics-box{
    min-height: 300px;
    h3{
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      i{
        line-height: 21px;
        color: @theme;
        font-weight: 600;
        font-style: normal;
      }
    }
    .pay-inner-l{
      padding: 50px 0 32px 40px;
      text-align: left;
      .swiper-box{
        width: 100%;
        position: relative;
        .cover{
          width: 100px;
          height: 87px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        /deep/ .swiper-button-prev:after,.swiper-button-next:after{
          content: '';
        }
        /deep/ .swiper-button-next{
          right: -6px;
        }
        /deep/ .swiper-button-prev{
          left: -6px;
        }
        .bg{
          width: 15px;
          height: 88px;
          background: rgba(0, 0, 0, 0.35);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 18px;
        }
      }
      
      p{
        margin-top: 16px;
        line-height: 21px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        span{
          display: inline-block;
          width: 80px;
          color: #666666;
        }
      }
    }



    // 暂未发货样式
    .total{
      margin-left: 26px;
    }
    .no-set-inner{
      margin-top: 26px;
      margin-left: 40px;
      margin-bottom: 62px;
      display: flex;
      .no-set-item{
        margin-right: 40px;
        text-align: center;
        width: 140px;
        img{
          width: 140px;
          height: 122px;
          border-radius: 4px;
          margin-bottom: 16px;
        }
        span{
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }

  .border-box{
    display: flex;
    .card{
      padding: 30px 70px;
      width: 50%;
      h4{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        margin-bottom: 24px;
      }
      .p{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-top: 16px;
        display: flex;
        span{
          display: inline-block;
          width: 90px;
          color: #666666;
        }
        p{
          flex: 1;
          display: inline-block;
        }
      }
    }
    .card:nth-child(2){
      border-left: 1px solid #EBEBEB;
    }
  }

  .product-box{
    margin-top: 20px;
    border: 1px solid #EBEBEB;
    .public-flex{
      display: flex;
      align-items: center;
      .head-l{
        width: 520px;
      }
      .head-r{
        flex: 1;
        display: flex;
        span{
          display: inline-block;
          text-align: center;
          // width: calc(100% / 4);
          flex: 1;
        }
        .spanBtn{
          color: #15B7DD;
          cursor: pointer;
        }
        .optionBox{
          flex: 1;
          position: relative;
          text-align: center;
          .refundStatu{
            background: linear-gradient( 84deg, #4DDDFF 0%, #A5C7F9 100%);
            border-radius: 8px 0px 8px 0px;
            padding: 0 6px;
            font-size: 10px;
            color: #FFFFFF;
            line-height: 15px;
            position: absolute;
            top: -17px;
          }
        }
      }
    }
    .header{
      background: #F3F3F3;
      height: 54px;
      span{
        display: inline-block;
        text-align: center;
        line-height: 54px;
        font-weight: 500;
        color: #333333;
      }
    }

    .product-item{
      align-items: center;
      // margin: 0 37px;
      padding: 28px 0;
      border-bottom: 1px solid #EBEBEB;
      .head-l{
        display: flex;
        padding-left: 37px;
        .product-cover{
          width: 100px;
          height: 87px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        .product-r{
          margin-left: 18px;
          font-size: 14px;
          font-weight: 400;
          .name{
            width: 300px;
            color: #333333;
            line-height: 21px;
          }
          .label{
            margin-top: 16px;
            color: @theme;
          }
        }
      }
      .head-r{
        span:nth-child(1),span:nth-child(4){
          font-weight: 500;
        }
        span:nth-child(2),span:nth-child(3){
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .contain{
      margin-top: 28px;
      .title{
        color: #333;
        font-weight: 500;
        margin-left: 37px;
      }
      .public-flex{
        margin-top: 28px;
      }
    }
    .total-price{
      color: #333;
      text-align: right;
      margin-top: 24px;
      margin-bottom: 32px;
      >div{
        display: inline-block;
        text-align: left;
        width: 200px;
        margin-right: 75px;
        p{
          line-height: 24px;
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .actualPrice{
            color: #FF0000;
            line-height: 36px;
            font-weight: 500;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.modal-content{
  .title{
    margin-top: 16px;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    text-align: center;
  }
  .address{
    margin-top: 30px;
    /deep/.ant-radio-wrapper{
      align-items: center;
      margin-bottom: 10px;
    }
    .text{
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .new{
      margin-left: 23px;
      font-size: 14px;
      font-weight: 500;
      color: #15B7DD;
      line-height: 21px;
      cursor: pointer;
    }
  }
  .btn-foot {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      align-items: center;
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      margin-right: 54px;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      width: 113px;
      height: 36px;
      line-height: 36px;
      &::after{
        background: none;
      }
    }
  }
}

.modal-contentcancel {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin: 33px 20px 30px 20px;
    .ant-select{
      margin-top: 16px;
      height: 41px;
      width: 100%;
    }
    /deep/ .ant-select-selection--single{
      height: 41px;
    }
    /deep/ .ant-select-selection__rendered{
      line-height: 41px;
    }
    .tips-info{
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      img{
        position: relative;
        top: -2px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .btn-foot {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    margin-top: 40px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      margin-right: 54px;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      width: 113px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      &::after{
        background: none;
      }
    }
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}
.refundTitle{
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 27px;
  text-align: center;
  margin-top: -8px;
  margin-bottom: 23px;
}
.refundProductBox{
  display: flex;

  .box_left{
    padding-top: 2px;
    padding-right: 4px;
    position: relative;
    img{
      width: 22px;
      height: 16px;
      position: relative;
      z-index: 2;
    }
  }
  .img_before::before{
    content: '';
    width: 0;
    height: 18px;
    border-left: 1px dashed #999999;
    position: absolute;
    left: 8px;
    top: 0;
  }
  .img_after::after{
    content: '';
    width: 0;
    height: calc(100% - 18px);
    border-left: 1px dashed #999999;
    position: absolute;
    left: 8px;
    bottom: 0;
  }
  .box_right{
    flex: 1;
    padding: 12px 13px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 14px;
    .option{
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        color: #666666;
        line-height: 21px;
      }
      .done{
        color: #34C632;
      }
    }
  }
}
.more{
  .box_right{
    border-radius: 6px;
    background-color: #F8F8F8;
  }
}
</style>
