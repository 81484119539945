import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import { message,notification  } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import 'assets/css/reset.css'
import 'assets/font/font.css'
import 'assets/css/style.less'
import upload from '@/unit/upload.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 商城购买  领取优惠券 弹框
import Shopwarning from '@/components/model/shopWarning.vue'

// 全局提示 弹框
import TipsModal from '@/components/model/TipsModal.vue'
// 引入swiper的css样式
import 'swiper/css/swiper.css'
// import Global from 'assets/js/Global.js'
// 正则校验
import regular from './assets/js/regular.js'
// ajax请求
import ajax from './unit/ajax'
// AES加密
import AESCipher from './unit/AESCipher.js'
// 随页面滚动加载样式
import animate from '@/unit/animate.js' 
// 全局图片错误替换
import Global from '@/components/Global.vue'
// 无数据样式组件
import Nomessage from './components/noMessage.vue'
// 图片懒加载组件
import VueLazyload from 'vue-lazyload'

// 图片放大预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


// 跳转页面时回到顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

Vue.use(message)
Vue.use(notification)

Vue.use(Viewer)

Vue.component('Shopwarning',Shopwarning); // 全局注册 商城弹框
Vue.component('TipsModal',TipsModal); // 全局注册 提示框
Vue.config.productionTip = false
Vue.prototype.$ajax = ajax
Vue.prototype.GLOBAL = Global

Vue.prototype.$upload = upload

Vue.prototype.$regular = regular
Vue.prototype.$Message = message // 全局提示
Vue.prototype.$Notification = notification // 全局提示
Vue.prototype.$AES = new AESCipher('hxclass')

Vue.use(Antd)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.component('no-message', Nomessage) // 无数据样式组件

// 挂载样式加载
Vue.directive('animate', animate)

Vue.use(VueLazyload)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
