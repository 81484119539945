<template>
  <!-- 支付方式 -->
  <div class="payMethod">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">" v-if="!$route.query.source">
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">收银台</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 支付方式 -->
    <div class="box_con">
      <div class="con_info">
        <div class="info_txt">
          您的订单已提交成功，请尽快完成付款！订单编号：{{ orderDetail.code }}
        </div>
        <div class="info_txt">
          <span>请在</span>
          <a-statistic-countdown :value="payCountDown" format="D天H时m分s秒" />
          <!-- <span class="light">6天23小时59分59秒</span> -->
          <span>内完成支付，否则订单将会被自动取消。</span>
        </div>
      </div>
      <div class="con_main">
        <div class="main_title">请选择支付方式：</div>
        <div class="main_type">
          <div
            class="type_item"
            :class="{ pick: index == payType }"
            v-for="(item, index) in payTypeArr"
            :key="index"
            @click="onCheckProduct(index)"
          >
            <img class="item_img" :src="item.src" :alt="item.title" />
          </div>
        </div>
        <div class="main_hint" v-if="payType == 2">
          <div class="hint_title">请向以下账户转账：<span @click="copyMsg">复制</span></div>
          <div class="hint_con">
            <span class="con_left">收款户名：</span>
            <span class="con_right">华夏出版社有限公司</span>
          </div>
          <div class="hint_con">
            <span class="con_left">收款银行：</span>
            <span class="con_right"
              >中国工商银行北京东四支行</span
            >
          </div>
          <div class="hint_con">
            <span class="con_left">银行账号：</span>
            <span class="con_right">0200 0041 0900 4621 597</span>
          </div>
        </div>
        <div class="main_button">
          <!-- <div class="all-btn-blue" @click="$router.go(-1)">取消</div> -->
          <div
            class="all-btn-small"
            v-if="payType == 2"
            @click="goOfflinePay()"
          >
            填写支付信息
          </div>
        </div>
      </div>
    </div>
    <!-- 加载 -->
    <div class="load_box" v-if="loading">
      <a-spin class="load" size="large" />
    </div>
  </div>
</template>

<script>
// import { obj } from '@/unit/config.js';
import config from '@/unit/config.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false, //加载中
      orderId: null, //订单ID
      orderDetail: {}, //订单详情
      payType: -1, //支付类型
      payTypeArr: [
        {
          title: "支付宝",
          src: require("@/assets/image/pay/pay_icon1.png"),
        },
        {
          title: "微信",
          src: require("@/assets/image/pay/pay_icon2.png"),
        },
        {
          title: "对公转账",
          src: require("@/assets/image/pay/pay_icon3.png"),
        },
      ],
      payCountDown: null, //支付倒计时
      payReturnUrl: '', //支付宝返回地址域名
      closeLoadingTimer: null, //关闭loading定时器
      productType:'',// 订单类型  3.补考
      signupUserId: '', //考生 id
    };
  },
  // 事件处理器
  methods: {
    // 复制收款人信息
    copyMsg() {
        let msg = '收款户名：华夏出版社有限公司 收款银行：中国工商银行北京东四支行 银行账号：0200004109004621597'
        let inputDom = document.createElement('textarea') // js创建一个文本框
        document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
        inputDom.value = msg //将需要复制的内容赋值到创建的文本框中
        inputDom.select() //选中文本框中的内容
        inputDom.focus()
        document.execCommand('copy') //执行复制操作
        document.body.removeChild(inputDom) //最后移出
        this.$message.success('复制成功');
      },
    // 订单详情
    getOrderDetail() {
      this.$ajax({
        url: "/hxclass-pc/order/" + this.orderId,
        method: "get",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.orderDetail = res.data;
          this.orderDetail.createTime = new Date((res.data.createTime).replace(/-/g,'/'));
          // 当前时间戳
          let nowDate = Date.now();
          // 创建订单时间戳
          let creatTime = new Date(this.orderDetail.createTime);
          // 到期时间
          let expireTime = creatTime.setDate(creatTime.getDate() + 7);
          // 时间差
          let countDown = expireTime - nowDate;
          this.payCountDown = Date.now() + countDown;
          // console.log(this.payCountDown);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 检查订单商品是否含有不能购买的商品
    onCheckProduct(index){ 
      this.loading = true; //加载
      this.$ajax({ 
        url: '/hxclass-pc/order/order/purchase',
        params: {
          orderId: this.orderId,
        }
      }).then((res) => {
        if (res.data) {
          this.changePayType(index)
        } else {
          this.$message.error('该订单含有已下架商品或订单超时已关闭，请重新选择！');
        }
        this.loading = false; //加载
      });
    },

    // 支付方式
    changePayType(index) {
      this.loading = true; //加载
      this.payType = index;
      let payMethod = null;
      if (this.payType == 0) {
        // 支付宝
        payMethod = 2;
      } else if (this.payType == 1) {
        // 微信
        // payMethod = 1
        this.$router.push({
          path: "/pay/wxPay",
          query: {
            orderId: this.$AES.encode_data(this.orderId),
            productType: this.$route.query.productType,
            signupUserId: this.$route.query.signupUserId,
            auditTime: (this.$route.query.auditTime ? this.$AES.encode_data(this.$route.query.auditTime) : undefined),
            source: this.$route.query.source
          },
        });
        this.loading = false;
        return;
      } else if (this.payType == 2) {
        this.loading = false;
        return;
      }

      this.$ajax({
        url: "/hxclass-pc/order/native/pay",
        method: "post",
        params: {
          orderId: this.orderId, //订单id
          payMethod: payMethod, //1微信 2支付宝
          returnUrl:
            this.payReturnUrl+"pay/payOk?type=1&orderId=" +
            this.$AES.encode_data(this.orderId) + 
            '&productType=' + this.$route.query.productType + 
            '&auditTime=' +  (this.$route.query.auditTime ? this.$AES.encode_data(this.$route.query.auditTime) : undefined) + 
            '&source=' + this.$route.query.source,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 微信
          // if (payMethod == 1) {
          //   this.$router.push('/pay/wxPay');
          // }
          // 支付宝
          if (payMethod == 2) {
            const formElement = document.createElement("div");
            formElement.style.display = "display:none;";
            formElement.innerHTML = res.data.body; //去到from
            if (formElement.firstChild) {
              // if(this.productType == 3){
              //   formElement.firstChild.target = "_blank";
              // }
              document.body.appendChild(formElement);
              formElement.firstChild.submit(); //进行跳转
              document.body.removeChild(formElement);
              this.closeLoadingTimer = setTimeout(() => {
                this.loading = false;
              },2000)
            }

            if(this.productType == 3){
              clearInterval(this.isPayTimer); //清除支付定时器
              this.isPayTimer = setInterval(() => {
                this.isPay()
              }, 3000);
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 是否支付成功
    isPay() {
      this.$ajax({
        url: '/hxclass-pc/exam/signUp/selMakUpOrderIsPay',
        params: {
          orderId: this.orderId,
          signUpUserId: this.signupUserId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$router.replace({
            path: '/study/study',
          })
          // 支付成功，清除计时器
          clearInterval(this.isPayTimer); //清除支付定时器
        }
      })
    },

    // 对公转账
    goOfflinePay() {
      this.$router.push({
        path: "/pay/offlinePay",
        query: {
          orderId: this.$AES.encode_data(this.orderId),
          source: this.$route.query.source
        },
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    this.productType = this.$route.query.productType ? this.$AES.decode_data(this.$route.query.productType) : 0; //
    this.signupUserId = this.$route.query.signupUserId ? this.$AES.decode_data(this.$route.query.signupUserId) : 0;
    this.payReturnUrl = config.payReturnUrl;
    // this.orderId = '34713';
    // 订单详情
    this.getOrderDetail();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  beforeDestroy(){
    clearInterval(this.isPayTimer)
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    // 加载
    this.loading = false;
    // 销毁loading定时器
    clearTimeout(this.closeLoadingTimer)
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    // 支付倒计时
    payCountDown: {
      handler(newName, oldName) {
        // console.log(newName);
      },
      immediate: true,
      deep: true, //监听对象或数组的时候，要用到深度监听
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.payMethod {
  width: 1400px;
  margin: 0 auto;
  padding-top: 92px;
  .box_con {
    margin-top: 22px;
    margin-bottom: 40px;
    padding: 40px 40px 60px 40px;
    background: #fff;
    border-radius: 5px;
    .con_info {
      .info_txt {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        &:nth-last-child(1) {
          margin-top: 28px;
        }
        .light {
          color: #EC6C01;
        }
        /deep/.ant-statistic {
          display: inline-block;
          .ant-statistic-content {
            font-size: 16px;
            color: #EC6C01;
          }
        }
      }
    }
    .con_main {
      margin-top: 50px;
      .main_title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        color: #15b7dd;
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 19px;
          margin-right: 8px;
          background: #15b7dd;
        }
      }
      .main_type {
        display: flex;
        margin-top: 24px;
        padding: 0 12px;
        .type_item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 165px;
          height: 70px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          margin-right: 36px;
          cursor: pointer;
          .item_img {
            height: 28px;
            -webkit-user-drag: none;
          }
          &.pick {
            border: 1px solid #15b7dd;
            background-color: rgba(21, 183, 221, 0.04);
            background-image: url("~@/assets/image/pay/right.png");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: right bottom;
          }
        }
      }
      .main_hint {
        transition: all 0.5s;
        position: relative;
        width: 567px;
        background: #f3f3f3;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 12px;
        padding: 24px;
        &::before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 0;
          height: 0;
          top: -24px;
          right: 76px;
          border-top: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #f3f3f3;
          border-left: 12px solid transparent;
        }
        .hint_title {
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span{
            color: #15b7dd;
            cursor: pointer;
          }
        }
        .hint_con {
          margin-top: 18px;
          font-size: 18px;
          .con_left {
            color: #666;
            font-family: PingFang SC-Regular, PingFang SC;
          }
          .con_right {
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }
      }
      .main_button {
        display: flex;
        margin-top: 50px;
        padding: 0 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        .all-btn-blue,
        .all-btn-small {
          width: 220px;
          height: 50px;
          font-size: 20px;
          box-shadow: 0px 4px 4px 0px rgba(49, 188, 221, 0.1);
          line-height: 50px;
          margin-right: 32px;
        }
      }
    }
  }
  .load_box {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .payMethod {
    width: 1200px;
    .box_con .con_main {
      margin-top: 40px;
      .main_title {
        font-size: 22px;
      }
      .main_button {
        margin-top: 40px;
        .all-btn-blue,
        .all-btn-small {
          width: 138px;
          height: 46px;
          font-size: 16px;
          line-height: 46px;
        }
      }
    }
  }
}
</style>
